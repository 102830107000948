
<kendo-dialog
  *ngIf="show"
  class="custom-dialog custom-dialog-darkOverlay"
  width="{{popupWidth}}"
  height="auto"
  title=" "
  (close)="closeModal()">

  <!-- Pre-Check Succeed Section -->
  <div>
    <div class="wrapper-section-row">
      <h2 class="modal-text">{{'landingpage-pass-p1'|langchange}}</h2>
    </div>
    <div *ngIf="companyLink" class="wrapper-section-row margin-t-20 margin-b-10">
      <h2 class="modal-text">{{nextStepMessage}}</h2>
    </div>
    <a href="//{{companyLink}}" class="h4">{{companyLink}}</a>
    <div class="wrapper-section-row margin-t-20">
      <h2 class="modal-text">{{'landingpage-pass-p3'|langchange}}</h2>
    </div>
  </div>

</kendo-dialog>

 <span *ngIf="canshow" class="pointer">
    <!--<a (click)="openMyNotification()" class="fa fa-envelope-o fa-2x">-->
    <a (click)="goToNotificationsmessages()" class="fa fa-envelope-o fa-2x">
      <!--<ul *ngIf="this.portalUserService.unreadCnt">
        <li>{{this.portalUserService.unreadCnt}}</li>
      </ul>-->
      <ul>
        <li *ngIf="notReadNotifications > 0" >{{notReadNotifications}}</li>
      </ul>
    </a>
   </span>

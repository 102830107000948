import { CurrentUser } from "./../currentuser";
import { StorageConstants } from "./../storageconstants";
import {
  AppConstants,
  ShiftEntryStatus,
  GridType,
  nonCalcTypes,
} from "./../appconstants";
import { Injectable } from "@angular/core";
import { Router, Data } from "@angular/router";
import { ShiftDetail } from "src/app/external-user/models/time-shift-response";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { ErrorExpressionConstants } from "src/app/core/error-expression-constants";
import { DomSanitizer } from "@angular/platform-browser";
import { IWeek } from "src/app/external-user/models/Iweek";
import {
  CaregiverParticipantRelQuestionnaireDetailsDto,
  IShiftDetailDTO,
} from "src/app/external-user/services/v4ApiServices/v4ApiServices.services";
import { locales } from "../../core/locales.values";
import { LanguageChangeService } from "./language-change.service";
import { Subject } from "rxjs";
import { FeatureStateService } from "./featureStateService";

export interface Week {
  week8: Date;
  week9: Date;
  week10: Date;
  week11: Date;
  week12: Date;
  week13: Date;
  week14: Date;
}
@Injectable({
  providedIn: "root",
})
export class CommonFunctionsService {
  constructor(
    private toasterService: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private languageChangeService: LanguageChangeService,
    private featureStateService: FeatureStateService
  ) {}

  public roundTo = 15;
  public attestationRes = [];
  languageText = locales[1].text;
  currentLanguage = new Subject<string>();

  public GetUserType(): string {
    const userType = sessionStorage.getItem(StorageConstants.userType);
    if (userType === null || userType === undefined) {
      return "";
    } else {
      if (userType === StorageConstants.externalUserValue) {
        return StorageConstants.externalUserValue;
      } else {
        return StorageConstants.internalUserValue;
      }
    }
  }

  public getFormattedDate(dateString: string) {
    let date = "";
    if (dateString != null && dateString !== "") {
      const currentTime = new Date(dateString);
      const month = currentTime.getMonth() + 1;
      const day = currentTime.getDate();
      const year = currentTime.getFullYear();
      const monthStr = month < 10 ? "0" + month.toString() : month.toString();
      const dayStr = day < 10 ? "0" + day.toString() : day.toString();
      const yearStr = year.toString();
      date = monthStr + "/" + dayStr + "/" + yearStr;
    }
    return date;
  }

  public getDateTimeIgnoringBrowserTimeZone(dateTime: string | Data): Date {
    const str = dateTime.split("T");
    const date = str[0].split("-");
    const time: string[] = str[1].split(":");
    if (time.length > 1) {
      if (time[2].indexOf("Z") > -1) {
        time[2] = time[2].substring(0, time[2].length - 1);
      }
    }
    time[2] = time[2].split("-")[0];
    const myDate = new Date(
      Number(date[0]),
      Number(date[1]) - 1,
      Number(date[2]),
      Number(time[0]),
      Number(time[1]),
      Number(time[2]),
      0
    );
    return myDate;
  }

  public getTodayDate(): Date {
    const date = new Date();
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );
  }

  public convertToUTCDateTime(value: Date): Date | null {
    if (value && value !== null) {
      return new Date(
        Date.UTC(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          value.getHours(),
          value.getMinutes(),
          value.getSeconds(),
          0
        )
      );
    }
  }

  public downloadPdfFile(data: any, filename: string) {
    const fileHeader = {
      "content-type": "application/pdf",
      "x-filename": filename,
    };
    this.downloadFile(data, fileHeader);
  }

  public downloadFile(data: any, headers: any): void {
    const responseHeaders = headers;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const blob = new Blob([data], {
      type: isSafari
        ? responseHeaders["content-type"]
        : "application/octet-stream",
    });

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(
        blob,
        responseHeaders["x-filename"]
      );
    } else {
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a") as any;
      document.body.appendChild(link);
      link.style = "display: none";
      link.href = url;
      link.download = responseHeaders["x-filename"];
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 1000);
    }
  }

  public dateCompareWithoutTime(dateOne: Date, dateTwo: Date): number {
    if (!dateOne && !dateTwo) {
      return 0;
    }
    if (dateOne && !dateTwo) {
      return 1;
    }
    if (!dateTwo && dateOne) {
      return -1;
    }
    if (dateOne.getFullYear() > dateTwo.getFullYear()) {
      return 1;
    }

    if (dateOne.getFullYear() < dateTwo.getFullYear()) {
      return -1;
    }

    if (dateOne.getMonth() > dateTwo.getMonth()) {
      return 1;
    }

    if (dateOne.getMonth() < dateTwo.getMonth()) {
      return -1;
    }

    if (dateOne.getDate() > dateTwo.getDate()) {
      return 1;
    }

    if (dateOne.getDate() < dateTwo.getDate()) {
      return -1;
    }

    return 0;
  }

  public isMidnight(date: Date): boolean {
    const isMidnight: boolean =
      date.getHours() === 0 &&
      date.getMinutes() === 0 &&
      date.getSeconds() === 0;
    const isSecBefore: boolean =
      date.getHours() === 23 &&
      date.getMinutes() === 59 &&
      date.getSeconds() === 59;

    return isMidnight || isSecBefore;
  }

  public isTimeMidnight(time: any): boolean {
    const isMidnight: boolean =
      time.hour === 0 && time.minute === 0 && time.second === 0;
    const isSecBefore: boolean =
      time.hour === 23 && time.minute === 59 && time.second === 59;
    return isMidnight || isSecBefore;
  }

  public isRounded(inTime: Date, outTime: Date): boolean {
    const outMidnight: boolean =
      outTime.getHours() === 23 &&
      outTime.getMinutes() === 59 &&
      outTime.getSeconds() === 59;
    const outRounded: boolean =
      outTime.getMinutes() % this.roundTo === 0 || outMidnight;
    const inRounded: boolean = inTime.getMinutes() % this.roundTo === 0;

    return outRounded && inRounded;
  }

  public isTimeRounded(inTime: any, outTime: any): boolean {
    const outMidnight: boolean =
      outTime.hour === 23 && outTime.minute === 59 && outTime.second === 59;
    const outRounded: boolean =
      outTime.minute % this.roundTo === 0 || outMidnight;
    const inRounded: boolean = inTime.minute % this.roundTo === 0;

    return outRounded && inRounded;
  }

  public isDateInRange(
    currentDate: Date,
    minDate: Date,
    maxDate: Date
  ): boolean {
    const isBeforeMinDate = this.isBeforeCurrentDate(currentDate, minDate);
    const isAfterMaxDate = this.isAfterCurrentDate(currentDate, maxDate);

    if (isBeforeMinDate || isAfterMaxDate) {
      return false;
    } else {
      return true;
    }
  }

  public isBeforeCurrentDate(currentDate: Date, minDate: Date): boolean {
    return this.dateCompareWithoutTime(currentDate, minDate) === -1;
  }

  public isAfterCurrentDate(currentDate: Date, maxDate: Date): boolean {
    return this.dateCompareWithoutTime(currentDate, maxDate) === 1;
  }

  public isValidSession(): boolean {
    if (CurrentUser.details) {
      return CurrentUser.details.email && CurrentUser.details.email.length > 0;
    } else {
      return false;
    }
  }

  public checkDateTimeForDayLightSaving(date: Date): Date {
    const currentDate = new Date();
    const jan = new Date(currentDate.getFullYear(), 0, 1);
    const daylightSaving = jan.getTimezoneOffset() - date.getTimezoneOffset();
    if (daylightSaving > 0) {
      date.setMinutes(date.getMinutes() + daylightSaving);
    }
    return date;
  }

  public getDaylightSavingValue(): number {
    const jan = new Date(new Date().getFullYear(), 0, 1);
    const daylightSaving =
      jan.getTimezoneOffset() - new Date().getTimezoneOffset();
    return daylightSaving;
  }

  public setStartDaysOfTheWeek(startOfTheWeek: string): number {
    let startDayOfTheWeek: number;
    switch (startOfTheWeek) {
      case "Sunday": {
        startDayOfTheWeek = 0;
        break;
      }
      case "Monday": {
        startDayOfTheWeek = 1;
        break;
      }
      case "Tuesday": {
        startDayOfTheWeek = 2;
        break;
      }
      case "Wednesday": {
        startDayOfTheWeek = 3;
        break;
      }
      case "Thursday": {
        startDayOfTheWeek = 4;
        break;
      }
      case "Friday": {
        startDayOfTheWeek = 5;
        break;
      }
      case "Saturday": {
        startDayOfTheWeek = 6;
        break;
      }
      default: {
        startDayOfTheWeek = 0;
        break;
      }
    }
    return startDayOfTheWeek;
  }

  public hideMalFormed(roleName: string) {
    return roleName.toLowerCase() !== "caregiver";
  }

  public disableByRole(roleName: string, gridName: string): boolean {
    if (roleName != null && roleName !== "") {
      roleName = roleName.toLowerCase();
    }
    const v4RoleScreensObjectString = sessionStorage.getItem(
      AppConstants.roleScreensConfigStorageKey
    );
    let v4RoleScreensConfig;
    let gridDict;
    let disabledRolesArray;
    try {
      v4RoleScreensConfig = JSON.parse(atob(v4RoleScreensObjectString));
      gridDict = v4RoleScreensConfig[gridName];
      disabledRolesArray = gridDict.DisableByRole;
      return disabledRolesArray == null
        ? false
        : disabledRolesArray.includes(roleName);
    } catch (error) {
      return false;
    }
  }

  public hideMileageTextByRole(
    roleName: string,
    componentName: string
  ): boolean {
    if (roleName != null && roleName !== "") {
      roleName = roleName.toLowerCase();
    }
    const v4RoleScreensObjectString = sessionStorage.getItem(
      AppConstants.roleScreensConfigStorageKey
    );
    let v4RoleScreensConfig;
    let gridDict;
    let disabledRolesArray;
    try {
      v4RoleScreensConfig = JSON.parse(atob(v4RoleScreensObjectString));
      gridDict = v4RoleScreensConfig[componentName];
      disabledRolesArray = gridDict.HideMileageTextByRole;
      return disabledRolesArray == null
        ? false
        : disabledRolesArray.includes(roleName);
    } catch (error) {
      return false;
    }
  }

  public show2WeeksTimeEntry(): boolean {
    const stateCode =
      CurrentUser.details.impersonateUserId > 0
        ? CurrentUser.details.impersonateUserStateCode
        : CurrentUser.details.stateCode;
    const stateCodesFor2Weeks: string = environment.twoWeeksTimeEntryStates;
    if (stateCode && stateCode !== "" && stateCode !== null) {
      return stateCodesFor2Weeks.indexOf(stateCode) > -1;
    }
    return false;
  }
  // calculateTotal for Time shift
  public calculateTotal(shiftdetail, gridType): string {
    const weekday1 = this.GetWeekDayEntryValue(shiftdetail.weekdayOne);
    const weekday2 = this.GetWeekDayEntryValue(shiftdetail.weekdayTwo);
    const weekday3 = this.GetWeekDayEntryValue(shiftdetail.weekdayThree);
    const weekday4 = this.GetWeekDayEntryValue(shiftdetail.weekdayFour);
    const weekday5 = this.GetWeekDayEntryValue(shiftdetail.weekdayFive);
    const weekday6 = this.GetWeekDayEntryValue(shiftdetail.weekdaySix);
    const weekday7 = this.GetWeekDayEntryValue(shiftdetail.weekdaySeven);
    const totalHours =
      weekday1 +
      weekday2 +
      weekday3 +
      weekday4 +
      weekday5 +
      weekday6 +
      weekday7;
    return gridType === GridType[GridType.Shift]
      ? totalHours.toFixed(2)
      : totalHours.toString();
  }

  public calculateDayTotal(
    shiftdetail: IShiftDetailDTO[],
    day,
    weekRange,
    companyConfig
  ): string {
    let total = 0.0;
    if (shiftdetail) {
      shiftdetail.forEach((x) => {
        if (!this.doesRecordBelongsToTheWeek(x, weekRange)) {
          if (
            x.serviceCode !== companyConfig.testServiceCode &&
            x[day].shiftEntryValue != null &&
            nonCalcTypes.indexOf(x[day].statusTitle) <= -1 &&
            this.shiftCalculate(x[day])
          ) {
            total += Number(x[day].shiftEntryValue);
          }
        }
      });
    }
    return total.toFixed(2);
  }

  public calculateServiceCodeDayTotal(shiftdetail, day): string {
    let total = 0.0;
    if (shiftdetail) {
      shiftdetail.forEach((x) => {
        if (
          x[day].shiftEntryValue != null &&
          nonCalcTypes.indexOf(x[day].statusTitle) <= -1 &&
          this.shiftCalculate(x[day])
        ) {
          total += Number(x[day].shiftEntryValue);
        }
      });
    }
    return total.toFixed(2);
  }

  public weekData(): Array<IWeek> {
    return [
      { id: 1, name: "1 Week" },
      { id: 2, name: "2 Weeks" },
    ];
  }
  public selectedWeek(displayTwoWeeksData: boolean = false): IWeek {
    let selectedWeekValue: IWeek;
    if (displayTwoWeeksData) {
      selectedWeekValue = this.weekData().filter((x) => x.id === 2)[0];
    } else {
      selectedWeekValue = this.weekData().filter((x) => x.id === 1)[0];
    }
    return selectedWeekValue;
  }

  private GetWeekDayEntryValue(dayObj): number {
    return dayObj.shiftEntryValue != null &&
      dayObj.statusTitle != AppConstants.denied &&
      nonCalcTypes.indexOf(dayObj.statusTitle) <= -1 &&
      this.shiftCalculate(dayObj)
      ? Number(dayObj.shiftEntryValue)
      : Number(0);
  }

  public getStatusText(statusVal: number): string {
    switch (statusVal) {
      case 0:
        return AppConstants.preferredLanguageData["legend_label_none"].value;
      case 1:
        return AppConstants.preferredLanguageData["legend_label_saved"].value;
      case 2:
        return AppConstants.preferredLanguageData["legend_label_submitted"]
          .value;
      case 3:
        return AppConstants.preferredLanguageData["legend_label_approved"]
          .value;
      case 4:
        return AppConstants.preferredLanguageData["legend_label_rejected"]
          .value;
      case 5:
        return AppConstants.preferredLanguageData["legend_label_inprogress"]
          .value;
      case 6:
        return AppConstants.preferredLanguageData["legend_label_readytobepaid"]
          .value;
      case 7:
        return AppConstants.preferredLanguageData["legend_label_paid"].value;
      case 8:
        return AppConstants.preferredLanguageData["legend_label_deleted"].value;
      case 9:
        return AppConstants.preferredLanguageData["legend_label_denied"].value;
      case 10:
        return AppConstants.preferredLanguageData["legend_label_suspended"]
          .value;
      case 11:
        return AppConstants.preferredLanguageData[
          "legend_label_removalinprogress"
        ].value;
      case 14:
        return AppConstants.preferredLanguageData["legend_label_nevertobepaid"]
          .value;
    }
  }

  public generateOneWeeksData(result, gridType) {
    if (result) {
      const weekData = JSON.parse(JSON.stringify(result));
      const serviceCodes = [];
      const week2ShiftData: Array<ShiftDetail> = [];
      if (
        weekData?.shiftDetails !== null &&
        weekData?.shiftDetails !== undefined
      ) {
        weekData.shiftDetails.forEach((shift) => {
          const serveCodeExists = !(
            serviceCodes.indexOf(shift.serviceCode + shift.mappingUserId) >
              -1 &&
            shift.weekdayOne.shiftEntryValue === null &&
            shift.weekdayTwo.shiftEntryValue === null &&
            shift.weekdayThree.shiftEntryValue === null &&
            shift.weekdayFour.shiftEntryValue === null &&
            shift.weekdayFive.shiftEntryValue === null &&
            shift.weekdaySix.shiftEntryValue === null &&
            shift.weekdaySeven.shiftEntryValue === null
          );
          if (serveCodeExists) {
            serviceCodes.push(shift.serviceCode + shift.mappingUserId);
            week2ShiftData.push(shift);
          }
        });
      }

      weekData.shiftDetails = week2ShiftData;
      return weekData;
    }
    return result;
  }

  public generateTwoWeeksData(result, gridType) {
    if (result) {
      const week2Data = JSON.parse(JSON.stringify(result));
      week2Data.weekdayOne = result.weekdayEight;
      week2Data.weekdayTwo = result.weekdayNine;
      week2Data.weekdayThree = result.weekdayTen;
      week2Data.weekdayFour = result.weekdayEleven;
      week2Data.weekdayFive = result.weekdayTwelve;
      week2Data.weekdaySix = result.weekdayThirteen;
      week2Data.weekdaySeven = result.weekdayFourteen;
      const serviceCodes = [];
      const week2ShiftData: Array<ShiftDetail> = [];
      let rowId = 2000;
      if (
        week2Data?.shiftDetails !== null &&
        week2Data?.shiftDetails !== undefined
      ) {
        week2Data.shiftDetails.forEach((shift) => {
          const serveCodeExists = !(
            serviceCodes.indexOf(shift.serviceCode + shift.mappingUserId) >
              -1 &&
            shift.weekdayEight.shiftEntryValue === null &&
            shift.weekdayNine.shiftEntryValue === null &&
            shift.weekdayTen.shiftEntryValue === null &&
            shift.weekdayEleven.shiftEntryValue === null &&
            shift.weekdayTwelve.shiftEntryValue === null &&
            shift.weekdayThirteen.shiftEntryValue === null &&
            shift.weekdayFourteen.shiftEntryValue === null
          );
          if (serveCodeExists) {
            shift.rowId = ++rowId;
            shift.weekdayOne = shift.weekdayEight;
            shift.weekdayTwo = shift.weekdayNine;
            shift.weekdayThree = shift.weekdayTen;
            shift.weekdayFour = shift.weekdayEleven;
            shift.weekdayFive = shift.weekdayTwelve;
            shift.weekdaySix = shift.weekdayThirteen;
            shift.weekdaySeven = shift.weekdayFourteen;
            serviceCodes.push(shift.serviceCode + shift.mappingUserId);
            week2ShiftData.push(shift);
          }
        });
      }
      week2Data.shiftDetails = week2ShiftData;
      return week2Data;
    }
    return result;
  }

  public generateTotalRecordsData(totalWeeksData, weekRange) {
    const totalWeeksShiftData: Array<ShiftDetail> = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    totalWeeksData.shiftDetails = this.getShiftDetails(
      totalWeeksData,
      weekRange
    );

    const serviceCodes: Array<string> = totalWeeksData.shiftDetails
      .map((item) => item.serviceCode)
      .filter((value, index, self) => self.indexOf(value) === index);

    for (const ele of serviceCodes) {
      let currentShift;

      const sData = totalWeeksData.shiftDetails.filter(
        (x) => x.serviceCode === ele
      );
      for (const element of sData) {
        const svcStDate = new Date(element.shiftStartDate);
        const weeksObjData = this.getWeeksData(totalWeeksData);
        if (!currentShift) {
          currentShift = element as ShiftDetail;
          this.setShiftEntryValuetoWeekData(
            currentShift,
            element,
            svcStDate,
            totalWeeksData,
            weeksObjData
          );
          // reset the statusTitle of the currentShift, since the status check is performed based on value of sData[j].statusTitle
          this.resetStatusTitleToNone(currentShift);
        } else {
          this.setCurrentShiftWeekData(
            currentShift,
            element,
            totalWeeksData,
            svcStDate,
            weeksObjData
          );
        }
      }

      this.setShiftEntryValue(currentShift);
      totalWeeksShiftData.push(currentShift);
    }
    return totalWeeksShiftData;
  }

  public resetStatusTitleToNone(currentShift: any) {
    currentShift.weekdayOne.statusTitle =
      ShiftEntryStatus[ShiftEntryStatus.None];
    currentShift.weekdayTwo.statusTitle =
      ShiftEntryStatus[ShiftEntryStatus.None];
    currentShift.weekdayThree.statusTitle =
      ShiftEntryStatus[ShiftEntryStatus.None];
    currentShift.weekdayFour.statusTitle =
      ShiftEntryStatus[ShiftEntryStatus.None];
    currentShift.weekdayFive.statusTitle =
      ShiftEntryStatus[ShiftEntryStatus.None];
    currentShift.weekdaySix.statusTitle =
      ShiftEntryStatus[ShiftEntryStatus.None];
    currentShift.weekdaySeven.statusTitle =
      ShiftEntryStatus[ShiftEntryStatus.None];
  }

  public setShiftEntryValue(currentShift: any) {
    currentShift.weekdayOne.shiftEntryValue =
      currentShift.weekdayOne.shiftEntryValue.toFixed(2);
    currentShift.weekdayTwo.shiftEntryValue =
      currentShift.weekdayTwo.shiftEntryValue.toFixed(2);
    currentShift.weekdayThree.shiftEntryValue =
      currentShift.weekdayThree.shiftEntryValue.toFixed(2);
    currentShift.weekdayFour.shiftEntryValue =
      currentShift.weekdayFour.shiftEntryValue.toFixed(2);
    currentShift.weekdayFive.shiftEntryValue =
      currentShift.weekdayFive.shiftEntryValue.toFixed(2);
    currentShift.weekdaySix.shiftEntryValue =
      currentShift.weekdaySix.shiftEntryValue.toFixed(2);
    currentShift.weekdaySeven.shiftEntryValue =
      currentShift.weekdaySeven.shiftEntryValue.toFixed(2);
  }

  public setShiftEntryValuetoWeekData(
    currentShift: any,
    element: any,
    svcStDate: Date,
    totalWeeksData: any,
    weeksObjData: any
  ) {
    currentShift.weekdayOne.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(element.weekdayEight, weeksObjData.week8, svcStDate),
      this.getShiftValue(
        element.weekdayOne,
        new Date(totalWeeksData.weekdayOne.weekday),
        svcStDate
      )
    );
    currentShift.weekdayTwo.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(element.weekdayNine, weeksObjData.week9, svcStDate),
      this.getShiftValue(
        element.weekdayTwo,
        new Date(totalWeeksData.weekdayTwo.weekday),
        svcStDate
      )
    );
    currentShift.weekdayThree.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(element.weekdayTen, weeksObjData.week10, svcStDate),
      this.getShiftValue(
        element.weekdayThree,
        new Date(totalWeeksData.weekdayThree.weekday),
        svcStDate
      )
    );
    currentShift.weekdayFour.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(element.weekdayEleven, weeksObjData.week11, svcStDate),
      this.getShiftValue(
        element.weekdayFour,
        new Date(totalWeeksData.weekdayFour.weekday),
        svcStDate
      )
    );
    currentShift.weekdayFive.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(element.weekdayTwelve, weeksObjData.week12, svcStDate),
      this.getShiftValue(
        element.weekdayFive,
        new Date(totalWeeksData.weekdayFive.weekday),
        svcStDate
      )
    );
    currentShift.weekdaySix.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(
        element.weekdayThirteen,
        weeksObjData.week13,
        svcStDate
      ),
      this.getShiftValue(
        element.weekdaySix,
        new Date(totalWeeksData.weekdaySix.weekday),
        svcStDate
      )
    );
    currentShift.weekdaySeven.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(
        element.weekdayFourteen,
        weeksObjData.week14,
        svcStDate
      ),
      this.getShiftValue(
        element.weekdaySeven,
        new Date(totalWeeksData.weekdaySeven.weekday),
        svcStDate
      )
    );
  }

  public setCurrentShiftWeekData(
    currentShift: any,
    element: any,
    totalWeeksData: any,
    svcStDate: Date,
    weeksObjData: any
  ) {
    currentShift.weekdayOne.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(currentShift.weekdayOne, new Date(), new Date()),
      this.sumOfTotalHours(
        this.getShiftValue(
          element.weekdayOne,
          new Date(totalWeeksData.weekdayOne.weekday),
          svcStDate
        ),
        this.getShiftValue(element.weekdayEight, weeksObjData.week8, svcStDate)
      )
    );
    currentShift.weekdayTwo.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(currentShift.weekdayTwo, new Date(), new Date()),
      this.sumOfTotalHours(
        this.getShiftValue(element.weekdayNine, weeksObjData.week9, svcStDate),
        this.getShiftValue(
          element.weekdayTwo,
          new Date(totalWeeksData.weekdayTwo.weekday),
          svcStDate
        )
      )
    );
    currentShift.weekdayThree.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(currentShift.weekdayThree, new Date(), new Date()),
      this.sumOfTotalHours(
        this.getShiftValue(element.weekdayTen, weeksObjData.week10, svcStDate),
        this.getShiftValue(
          element.weekdayThree,
          new Date(totalWeeksData.weekdayThree.weekday),
          svcStDate
        )
      )
    );
    currentShift.weekdayFour.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(currentShift.weekdayFour, new Date(), new Date()),
      this.sumOfTotalHours(
        this.getShiftValue(
          element.weekdayEleven,
          weeksObjData.week11,
          svcStDate
        ),
        this.getShiftValue(
          element.weekdayFour,
          new Date(totalWeeksData.weekdayFour.weekday),
          svcStDate
        )
      )
    );
    currentShift.weekdayFive.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(currentShift.weekdayFive, new Date(), new Date()),
      this.sumOfTotalHours(
        this.getShiftValue(
          element.weekdayTwelve,
          weeksObjData.week12,
          svcStDate
        ),
        this.getShiftValue(
          element.weekdayFive,
          new Date(totalWeeksData.weekdayFive.weekday),
          svcStDate
        )
      )
    );
    currentShift.weekdaySix.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(currentShift.weekdaySix, new Date(), new Date()),
      this.sumOfTotalHours(
        this.getShiftValue(
          element.weekdayThirteen,
          weeksObjData.week13,
          svcStDate
        ),
        this.getShiftValue(
          element.weekdaySix,
          new Date(totalWeeksData.weekdaySix.weekday),
          svcStDate
        )
      )
    );
    currentShift.weekdaySeven.shiftEntryValue = this.sumOfTotalHours(
      this.getShiftValue(currentShift.weekdaySeven, new Date(), new Date()),
      this.sumOfTotalHours(
        this.getShiftValue(
          element.weekdayFourteen,
          weeksObjData.week14,
          svcStDate
        ),
        this.getShiftValue(
          element.weekdaySeven,
          new Date(totalWeeksData.weekdaySeven.weekday),
          svcStDate
        )
      )
    );
  }

  public getShiftDetails(totalWeeksData: any, weekRange: any): any {
    const shiftDetails = [];
    if (
      totalWeeksData?.shiftDetails !== null &&
      totalWeeksData?.shiftDetails !== undefined
    ) {
      totalWeeksData.shiftDetails.forEach((element) => {
        if (!this.doesRecordBelongsToTheWeek(element, weekRange)) {
          shiftDetails.push(element);
        }
      });
    }
    return shiftDetails;
  }

  public getWeeksData(totalWeeksData: any) {
    const weekObj: Week = {
      week8: totalWeeksData?.weekdayEight?.weekday
        ? new Date(totalWeeksData.weekdayEight.weekday)
        : new Date(),
      week9: totalWeeksData?.weekdayNine?.weekday
        ? new Date(totalWeeksData.weekdayNine.weekday)
        : new Date(),
      week10: totalWeeksData?.weekdayTen?.weekday
        ? new Date(totalWeeksData.weekdayTen.weekday)
        : new Date(),
      week11: totalWeeksData?.weekdayEleven?.weekday
        ? new Date(totalWeeksData.weekdayEleven.weekday)
        : new Date(),
      week12: totalWeeksData?.weekdayTwelve?.weekday
        ? new Date(totalWeeksData.weekdayTwelve.weekday)
        : new Date(),
      week13: totalWeeksData?.weekdayThirteen?.weekday
        ? new Date(totalWeeksData.weekdayThirteen.weekday)
        : new Date(),
      week14: totalWeeksData?.weekdayFourteen?.weekday
        ? new Date(totalWeeksData.weekdayFourteen.weekday)
        : new Date(),
    };
    return Object.assign(weekObj);
  }

  private sumOfTotalHours(value1, value2) {
    return Number(value1) + Number(value2);
  }

  public getShiftValue(ob, shiftDate, svcDate): number {
    return ob.shiftEntryValue != null &&
      nonCalcTypes.indexOf(ob.statusTitle) <= -1 &&
      shiftDate >= svcDate
      ? Number(ob.shiftEntryValue)
      : Number(0);
  }

  public calculateServiceCodeTotal(details) {
    if (details) {
      return this.innerCalServiceCode(details);
    }
  }

  public innerCalServiceCode(details) {
    return (
      (nonCalcTypes.indexOf(details.weekdayOne.statusTitle) <= -1 &&
      this.shiftCalculate(details.weekdayOne)
        ? Number(details.weekdayOne.shiftEntryValue)
        : Number(0)) +
      (nonCalcTypes.indexOf(details.weekdayTwo.statusTitle) <= -1 &&
      this.shiftCalculate(details.weekdayTwo)
        ? Number(details.weekdayTwo.shiftEntryValue)
        : Number(0)) +
      (nonCalcTypes.indexOf(details.weekdayThree.statusTitle) <= -1 &&
      this.shiftCalculate(details.weekdayThree)
        ? Number(details.weekdayThree.shiftEntryValue)
        : Number(0)) +
      (nonCalcTypes.indexOf(details.weekdayFour.statusTitle) <= -1 &&
      this.shiftCalculate(details.weekdayFour)
        ? Number(details.weekdayFour.shiftEntryValue)
        : Number(0)) +
      (nonCalcTypes.indexOf(details.weekdayFive.statusTitle) <= -1 &&
      this.shiftCalculate(details.weekdayFive)
        ? Number(details.weekdayFive.shiftEntryValue)
        : Number(0)) +
      (nonCalcTypes.indexOf(details.weekdaySix.statusTitle) <= -1 &&
      this.shiftCalculate(details.weekdaySix)
        ? Number(details.weekdaySix.shiftEntryValue)
        : Number(0)) +
      (nonCalcTypes.indexOf(details.weekdaySeven.statusTitle) <= -1 &&
      this.shiftCalculate(details.weekdaySeven)
        ? Number(details.weekdaySeven.shiftEntryValue)
        : Number(0))
    );
  }

  public getCompanyCode(): string {
    let companyCode = "";
    if (CurrentUser.details) {
      companyCode =
        CurrentUser.details.impersonateUserId === 0 ||
        CurrentUser.details.impersonateUserId == null
          ? CurrentUser.details.companyCode
          : CurrentUser.details.impersonateUserCompanyCode;
    }

    return companyCode;
  }

  public getStateCode(): string {
    let stateCode = "";
    const sessionInfo = CurrentUser.details;
    if (sessionInfo) {
      stateCode =
        sessionInfo.impersonateUserId === 0 ||
        sessionInfo.impersonateUserId == null
          ? sessionInfo.stateCode
          : sessionInfo.impersonateUserStateCode;
    }

    return stateCode;
  }

  public getSolomonId(): string {
    let solomonId = "";
    const sessionInfo = CurrentUser.details;
    if (sessionInfo) {
      solomonId =
        sessionInfo.impersonateUserId === 0 ||
        sessionInfo.impersonateUserId == null
          ? sessionInfo.solomonId
          : sessionInfo.impersonateUserSolomonId;
    }

    return solomonId;
  }

  public getRoleName(): string {
    return CurrentUser.details.impersonateUserId === 0 ||
      CurrentUser.details.impersonateUserId === null
      ? CurrentUser.details.roleName
      : CurrentUser.details.impersonateUserRole;
  }

  public findIndexWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  public getFriendlySvcCode(svcCode: string, svcDesc: string): string {
    if (!svcCode && !svcDesc) {
      return "";
    } else if (svcCode && !svcDesc) {
      return svcCode;
    } else if (!svcCode && svcDesc) {
      return svcDesc;
    } else {
      return svcDesc.trim() + " (" + svcCode.trim() + ")";
    }
  }

  public generateDateFormatForDatePicker(dt: Date) {
    if (dt) {
      return {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate(),
      };
    }
  }
  public removeSlider() {
    document.querySelector("body").classList.remove("show_sidebar");
  }

  public showSlider() {
    document.querySelector("body").classList.add("show_sidebar");
  }

  public getI18NValue(key: string) {
    if (key) {
      return AppConstants.preferredLanguageData[key].value;
    } else {
      return "";
    }
  }

  public getStartOfTheWeek(): string {
    return this.getCompanyCode().toLowerCase() ===
      environment.companyCodeOfVirginia.toLowerCase()
      ? "Thursday"
      : "Sunday";
  }

  public getDateIgnoringBrowserTimeZone(dateTime: string): Date {
    const str = dateTime.split("T");
    const date = str[0].split("-");
    const time = str[1].split(":");
    time[2] = time[2].split("-")[0];
    const myDate = new Date(
      Number(date[0]),
      Number(date[1]) - 1,
      Number(date[2]),
      0,
      0,
      0
    );
    return myDate;
  }

  public checkUserAccess(hasAccess: boolean) {
    if (!hasAccess) {
      this.toasterService.error(
        ErrorExpressionConstants.unAuthorize401ErrorMessage
      );
      AppConstants.showLoading = false;
      return false;
    } else {
      return true;
    }
  }

  public setUserType(userType, tk) {
    if (userType === StorageConstants.externalUserValue) {
      CurrentUser.isExternalUser = true;
      CurrentUser.isInternalUser = false;
      CurrentUser.email = tk.emails[0];
      CurrentUser.familyName = tk.family_name;
      CurrentUser.givenName = tk.given_name;
      CurrentUser.state = tk.state;
      sessionStorage.setItem(
        StorageConstants.userType,
        StorageConstants.externalUserValue
      );
    } else if (userType === StorageConstants.internalUserValue) {
      CurrentUser.isExternalUser = false;
      CurrentUser.isInternalUser = true;
      CurrentUser.familyName = tk.family_name;
      CurrentUser.givenName = tk.given_name;
      CurrentUser.email = tk.unique_name;
      sessionStorage.setItem(
        StorageConstants.userType,
        StorageConstants.internalUserValue
      );
    }
    this.setUserDisplayName(tk);
  }

  public registrationstatus() {
    this.router.navigate(["user/registrationstatus"]);
  }

  private setUserDisplayName(deToken) {
    if (CurrentUser.isExternalUser) {
      if (CurrentUser?.details?.fullName !== undefined && CurrentUser?.details?.fullName !== "") {
        AppConstants.userDisplayName = CurrentUser.details.fullName;
      } else {
        AppConstants.userDisplayName =
          deToken.given_name + " " + deToken.family_name;
      }
    } else {
      AppConstants.userDisplayName = deToken.name;
    }
  }

  public doesRecordBelongsToTheWeek(shiftdetail, weekRange): boolean {
    let belongsToWeek = false;
    if (weekRange != null && weekRange !== "") {
      const servCodeStartDate = new Date(shiftdetail.shiftStartDate);
      const servCodeEndDate = new Date(shiftdetail.shiftEndDate);
      const weekRngArr = weekRange.split("#|#");
      const weekStartDate = new Date(weekRngArr[0].toString());
      const weekEndDate = new Date(weekRngArr[1].toString());
      belongsToWeek = !(
        (servCodeStartDate <= weekStartDate ||
          servCodeStartDate <= weekEndDate) &&
        (servCodeEndDate >= weekStartDate || servCodeEndDate >= weekEndDate)
      );
    }
    return belongsToWeek;
  }

  public selectedCellFromRow(selectedRows, txId) {
    let selectedDay: any = {};
    for (const selectedRow of selectedRows) {
      if (selectedRow.weekdayOne.id === txId) {
        selectedDay = selectedRow.weekdayOne;
        break;
      }

      if (selectedRow.weekdayTwo.id === txId) {
        selectedDay = selectedRow.weekdayTwo;
        break;
      }

      if (selectedRow.weekdayThree.id === txId) {
        selectedDay = selectedRow.weekdayThree;
        break;
      }

      if (selectedRow.weekdayFour.id === txId) {
        selectedDay = selectedRow.weekdayFour;
        break;
      }

      if (selectedRow.weekdayFive.id === txId) {
        selectedDay = selectedRow.weekdayFive;
        break;
      }

      if (selectedRow.weekdaySix.id === txId) {
        selectedDay = selectedRow.weekdaySix;
        break;
      }

      if (selectedRow.weekdaySeven.id === txId) {
        selectedDay = selectedRow.weekdaySeven;
        break;
      }
    }

    return selectedDay;
  }

  public isActiveUser(): boolean {
    let isActive = true;
    const user = CurrentUser?.details;
    if (user) {
      isActive = user?.isActive;
    }
    return isActive;
  }

  public setGridLoadingIcon(): boolean {
    return this.isActiveUser() ? true : false;
  }

  public convertToUTCDate(value: Date): Date | null {
    if (value) {
      return new Date(
        Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())
      );
    }
  }

  // This will need to updated if the adjustments enum is updated.
  public shiftCalculate(shiftData) {
    if (shiftData.isVoid || shiftData.isVoidOnly) {
      return false;
    }
    const adjustmentReasons = [];
    /* eslint-disable no-bitwise */
    if ((1 << 0) & shiftData.adjType) {
      adjustmentReasons.push("ACNChange");
    }
    if ((1 << 1) & shiftData.adjType) {
      adjustmentReasons.push("BillRateChange");
    }
    if ((1 << 2) & shiftData.adjType) {
      adjustmentReasons.push("PayRateChange");
    }
    if ((1 << 3) & shiftData.adjType) {
      adjustmentReasons.push("TimeChange");
    }
    if ((1 << 4) & shiftData.adjType) {
      adjustmentReasons.push("RemoveTime");
    }
    if ((1 << 5) & shiftData.adjType) {
      adjustmentReasons.push("ServCodeChange");
    }
    if ((1 << 6) & shiftData.adjType) {
      adjustmentReasons.push("HoldChange");
    }
    if ((1 << 7) & shiftData.adjType) {
      adjustmentReasons.push("UnHoldChange");
    }
    if ((1 << 8) & shiftData.adjType) {
      adjustmentReasons.push("ServiceDateChange");
    }
    /* eslint-enable no-bitwise */
    for (let i; i < adjustmentReasons.length; i++) {
      if (nonCalcTypes.indexOf(adjustmentReasons[i]) > -1) {
        return false;
      }
    }
    return true;
  }

  getStartAndEndDays(datesArr: any[]) {
    const maxDate = new Date(Math.max.apply(null, datesArr));
    const minDate = new Date(Math.min.apply(null, datesArr));
    const sDate = new Date(minDate.setDate(minDate.getDate() + 7));
    const eDate = new Date(maxDate.setDate(maxDate.getDate() + 7));
    const startDate = new Date(
      sDate.getTime() - sDate.getTimezoneOffset() * 60000
    );
    const endDate = new Date(
      eDate.getTime() - eDate.getTimezoneOffset() * 60000
    );
    return { startdate: startDate, enddate: endDate };
  }

  public buildRequestForDocFica() {
    this.attestationRes = [];
    const allQuestions = Array.from(
      document.getElementsByClassName("att-question")
    );
    allQuestions.forEach((ques) => {
      let componentId = 0;
      let componentDetailId = 0;
      let optionId = 0;
      let optionDetailId = 0;
      Array.from(ques.attributes).forEach((el) => {
        if (el.name === "data-attr-qid") {
          componentDetailId = Number(el.value);
        }
      });
      const allAnswers = Array.from(ques.getElementsByClassName("att-answer"));
      const allMultiSelectAnswers = Array.from(
        ques.getElementsByClassName("att-multiselect-answer")
      );
      if (allAnswers.length > 0) {
        allAnswers.forEach((el) => {
          const answerObj = this.buildAllAnswersForDOCFICA(
            componentId,
            optionId,
            optionDetailId,
            el
          );
          componentId = answerObj.componentId;
          optionId = answerObj.optionId;
          optionDetailId = answerObj.optionDetailId;
        });
      } else if (allMultiSelectAnswers.length > 0) {
        allMultiSelectAnswers.forEach((el) => {
          this.buildAllMultiSelectAnswers(
            el,
            componentId,
            optionId,
            optionDetailId,
            componentDetailId
          );
        });
        return;
      } else {
        const uniqueQuestion = Array.from(
          ques.getElementsByClassName("k-single-select")
        );
        uniqueQuestion.forEach((el) => {
          const uniqueQuestionObj = this.buildUniqueQuestions(
            el,
            componentId,
            optionDetailId,
            optionId,
            uniqueQuestion
          );
          componentId = uniqueQuestionObj.componentId;
          optionId = uniqueQuestionObj.optionId;
          optionDetailId = uniqueQuestionObj.optionDetailId;
        });
      }
      const qalist = new CaregiverParticipantRelQuestionnaireDetailsDto();
      qalist.attestationResponseDetailId = 0;
      qalist.componentId = componentId;
      qalist.componentDetailId = componentDetailId;
      qalist.optionId = optionId;
      qalist.optionDetailId = optionDetailId;
      this.attestationRes.push(qalist);
    });
    return this.attestationRes;
  }

  public buildAllAnswersForDOCFICA(componentId, optionId, optionDetailId, el) {
    const uniqueQuestion = Array.from(
      el.getElementsByClassName("att-answer-opt")
    );
    uniqueQuestion.forEach((chileEl) => {
      const ele = chileEl as HTMLInputElement;
      if (ele.checked) {
        componentId = Number(ele.getAttribute("data-attr-cid"));
        optionId = Number(ele.getAttribute("data-attr-oid"));
        optionDetailId = Number(ele.getAttribute("data-attr-odid"));
      }
    });

    return { componentId, optionId, optionDetailId };
  }

  public buildAllMultiSelectAnswers(
    el: Element,
    componentId: number,
    optionId: number,
    optionDetailId: number,
    componentDetailId: number
  ) {
    const selectedAnswers = Array.from(
      el.getElementsByClassName("att-multiselect-answer-opt")
    );
    selectedAnswers.forEach((chileEl) => {
      const ele = chileEl as HTMLInputElement;
      if (ele.checked) {
        componentId = Number(ele.getAttribute("data-attr-cid"));
        optionId = Number(ele.getAttribute("data-attr-oid"));
        optionDetailId = Number(ele.getAttribute("data-attr-odid"));
        const qamultiselectlist =
          new CaregiverParticipantRelQuestionnaireDetailsDto();
        qamultiselectlist.attestationResponseDetailId = 0;
        qamultiselectlist.componentId = componentId;
        qamultiselectlist.componentDetailId = componentDetailId;
        qamultiselectlist.optionId = optionId;
        qamultiselectlist.optionDetailId = optionDetailId;
        this.attestationRes.push(qamultiselectlist);
      }
    });
  }

  public buildUniqueQuestions(
    el,
    componentId,
    optionDetailId,
    optionId,
    uniqueQuestion
  ) {
    if (el.getAttribute("data-attr-visible") === "true") {
      const ele = el as HTMLInputElement;
      if (ele.checked) {
        componentId = Number(ele.getAttribute("data-attr-cid"));
        optionId = Number(ele.getAttribute("data-attr-oid"));
        optionDetailId = Number(ele.getAttribute("data-attr-odid"));
      } else {
        uniqueQuestion.forEach((hel) => {
          if (hel.getAttribute("data-attr-visible") === "false") {
            componentId = Number(ele.getAttribute("data-attr-cid"));
            optionId = Number(ele.getAttribute("data-attr-oid"));
            optionDetailId = Number(ele.getAttribute("data-attr-odid"));
          }
        });
      }
    }

    return { componentId, optionId, optionDetailId };
  }

  public languageChange() {
    let preferredLang = sessionStorage.getItem(
      StorageConstants.selectedlanguage
    );
    if (!preferredLang) {
      preferredLang = AppConstants.defaultLanguage;
    }
    switch (preferredLang) {
      case locales[0].code:
        const languageModelEn = locales[1];
        this.languageChangeService.use(languageModelEn.code);
        sessionStorage.setItem(
          StorageConstants.selectedlanguage,
          languageModelEn.code
        );
        this.languageText = locales[0].text;
        this.currentLanguage.next(locales[1].code);
        AppConstants.isEsPreferredLang = true;
        break;
      case locales[1].code:
        const languageModelEs = locales[0];
        this.languageChangeService.use(languageModelEs.code);
        sessionStorage.setItem(
          StorageConstants.selectedlanguage,
          languageModelEs.code
        );
        this.languageText = locales[1].text;
        this.currentLanguage.next(locales[0].code);
        AppConstants.isEsPreferredLang = false;
        break;
      default:
        break;
    }
    return this.languageText;
  }

  public fetchU2LanguageCode(selectedLanguage) {
    let u2LangCode;
    switch (selectedLanguage) {
      case locales[0].code:
        u2LangCode = locales[0].u2LangCode;
        break;
      case locales[1].code:
        u2LangCode = locales[1].u2LangCode;
        break;
      default:
        break;
    }
    return u2LangCode;
  }

  public getHourMinsSecondsFromDateTimeString(dateTime: string) {
    const str = dateTime.split("T");
    const time = str[1].split(":");
    time[2] = time[2].split(".")[0];
    return time;
  }

  public transformTimeFromDateTimeString(dateTime: string) {
    const str = dateTime.split("T");
    const time = str[1].split(":");
    const meridiem = (parseFloat(time[0]) ?? 0) >= 12 ? "PM" : "AM";
    time[0] =
      (parseFloat(time[0]) ?? 0) > 12
        ? String(parseFloat(time[0]) - 12).padStart(2, "0")
        : time[0];
    return time[0] + ":" + time[1] + " " + meridiem;
  }

  formatSelectedDateForCalenderControl(newselecteddate) {
    return {
      year: newselecteddate.getFullYear(),
      month: newselecteddate.getMonth() + 1,
      day: newselecteddate.getDate(),
    };
  }

  public convertToUTCDateTimeMidnight(value: Date): Date | null {
    if (value && value !== null) {
      return new Date(
        Date.UTC(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          23,
          59,
          59
        )
      );
    }
  }

  public projectUTCDateTimeAsLocalDateTime(value: Date | string): Date | null {
    if (!value) {
      return null;
    }

    if (typeof value === "string") {
      return new Date(value.replace("Z", ""));
    }

    if (value.getTimezoneOffset() >= 0) {
      return this.parseDate(
        new Date(Date.parse(value.toISOString().replace("Z", "")))
      );
    }

    return value;
  }

  public parseDate(strDate: Date): Date | null {
    if (strDate != null) {
      strDate = new Date(
        strDate.getFullYear(),
        strDate.getMonth(),
        strDate.getDate(),
        0,
        0,
        0
      );
    }
    return strDate;
  }

  public convertUTCDateToLocalDate(date) {
    const newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  public convertToUTCDateTimeZeroHour(value: Date): Date | null {
    if (value && value !== null) {
      return new Date(
        Date.UTC(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          0,
          0,
          0
        )
      );
    }
  }

  public deepCopy(obj) {
    let copy;
    if (null == obj || "object" != typeof obj) {
      return obj;
    }
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }
  }

  public getLoadingStatus(loading: any) {
    if (loading) {
      return Object.values(loading).find((val) => val === true) !== undefined;
    }
    return false;
  }

  public handleErrorResponse(toastrService: ToastrService, error) {
    for (const key in error.errors) {
      if (error.errors.hasOwnProperty(key)) {
        toastrService.error(error.errors[key][0]);
      }
    }
  }
}

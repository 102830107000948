import { CurrentUserDetails } from './models/currentuserdetails';

export class CurrentUser {
    public static userAuthenticated = false;
    public static isInternalUser = false;
    public static isExternalUser = false;
    public static role: string;
    public static familyName: string;
    public static givenName: string;
    public static state: string;
    public static email: string;
    public static details: CurrentUserDetails;
    public static whitelistUrls: string[] = [];
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppInsightsService } from '../services/app-insights.service';
import { MsalCustomService } from '../services/msal.service';
import { AppConstants, EnrollmentStatus } from './../appconstants';
import { CurrentUser } from './../currentuser';
import { PortalUserService } from './../services/portalUser.service';
import { FeatureStateService } from '../services/featureStateService';

@Injectable()
export class MsalChildGuard {

    constructor(
        private router: Router,
        private portalUserService: PortalUserService,
        private msalcustSer: MsalCustomService,
        public appInsightsService: AppInsightsService,
        private featureStateService: FeatureStateService) {
      }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isAuthenticated = await this.msalcustSer.authenticated;
        if (!isAuthenticated) {
            this.router.navigate(['login']);
            return false;
        } else {
            if (!CurrentUser.details) {
                try {
                    AppConstants.showLoading = true;
                    await this.portalUserService.InitiateUserSession();                   
                    AppConstants.showLoading = false;
                }
                catch(err) {
                    this.appInsightsService.logError(new Error("Error from CanActivateChild "+err));
                }
                finally {
                    AppConstants.showLoading = false;
                }
            }
            if (CurrentUser.details.enrollmentStatus !== EnrollmentStatus.RegistrationComplete) {
               return this.redirectToRegistration();
            }
            const isSSNValidation = this.featureStateService.isSSNValidation();
            if (isSSNValidation && CurrentUser.details && CurrentUser.details.isSsnValidated === false) {
                const companyConfig =  this.validateCompanyConfig();
                const landingPageRoles = companyConfig?.landingPageRoles.find(x => x.split(" ").join("").toLowerCase() === CurrentUser.details.roleName.toLowerCase());
                return this.redirectToLandingPage(companyConfig, landingPageRoles);
            }
        }
        return true;
    }

    validateCompanyConfig(){
        const companyConfigValue = sessionStorage.getItem(AppConstants.companyConfigStorageKey);
        const companyConfig = companyConfigValue ? (JSON.parse(atob(companyConfigValue))) : undefined;
        return companyConfig;
    }

    redirectToRegistration(){
        this.router.navigate(['user/registrationstatus']);
        return false;
    }

    redirectToLandingPage(companyConfig: any, landingPageRoles: any) {
        if (companyConfig && landingPageRoles) {
            this.router.navigate(['user/landingpage']);
            return false;
        }
    }

}


<main class="landing-bg page-scroll-full" *ngIf="showLoginForm">
  <section class="container-fluid landing-container">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="head-container-logo">
            <a>
              <img alt="CDMS Logo" src="../../assets/images/logo.svg" class="cdms-logo-img"/>
            </a>
          </div>
          <div class="cdms-banner-bg pull-right">
            <img class="pull-right" alt="CDMS Banner" class="cdms-banner-img" src="../../assets/images/CDMS-banner-img-va.jpg" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 care-services">
            <div>
              <h1>{{'label_we_specialize' | langchange}}</h1>
              <p>{{'label_we_provide' | langchange}}</p>
            </div>
            <ul class="circle-row">
              <li>{{'label_medicaid' | langchange}}<br />&nbsp;</li>
              <li>{{'label_medicare' | langchange}}<br />&nbsp;</li>
              <li class="priv-link"><p class="margin-0">{{'label_private_insurance' | langchange}}</p></li>
              <li>{{'label_supporting' | langchange}}<br />&nbsp;</li>
            </ul>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
            <div class="row login-section">
              <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                <div class="v-line"></div>
              </div>
              <div class="col-lg-11 col-md-11 col-sm-11 col-xs-11">
                <div>
                  <a class="btn btn-primary btn-lg" (click)="signInExternalUser()">
                    {{'label_sign_in' | langchange}}
                  </a>
                  <p>{{'label_or' | langchange}}</p>

                  <a class="btn btn-default btn-lg" (click)="register()">
                    {{'label_register' | langchange}}
                  </a>

                  <p>{{'label_language_change' | langchange}}</p>
                  <select title="language selector" id="langselect" class="w-310">
                    <option value="ui_locales=en">Language Selection</option>
                    <option value="ui_locales=en">English</option>
                    <option value="ui_locales=es">Spanish</option>
                  </select>
                  <div>
                    <p class="pull-left">{{'label_admin_staff' | langchange}}<a href="javascript:void(0)" (click)="openInterUserLogin()">{{'label_here' | langchange}}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<div class="model-overlay" *ngIf="openInternalUserPopup"> </div>
<kendo-window class="intenal-user-login-dialog" title="" *ngIf="openInternalUserPopup" (close)="closeInterUserPopup()">
  <p> {{'label_v4_admin_login_msg' | langchange}}<a href="{{u2LoginRedirect}}" > {{'label_here' | langchange}}</a></p>
  <p> {{'label_v3_admin_login_msg' | langchange}}<a href="{{v3LoginRedirect}}">{{'label_here' | langchange}}</a></p>
</kendo-window>

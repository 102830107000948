import { Component, OnDestroy, OnInit} from '@angular/core';
import { Subject, SubscriptionLike } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConstants } from 'src/app/core/appconstants';
import { SuccessLandingPageService } from './success-popup.service';
import { PortalUserService } from 'src/app/core/services/portalUser.service';
import { CurrentUser } from 'src/app/core/currentuser';
import { LanguageChangeService } from 'src/app/core/services/language-change.service';
import { StorageConstants } from 'src/app/core/storageconstants';
import { locales } from '../../core/locales.values';

@Component({
  selector: 'app-landingpage-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss',
  ]
})
export class LandingPageSuccessPopupComponent implements OnDestroy, OnInit {

  public popupWidth = 800;
  public show = false;
  public companyLink = '';
  public nextStepMessage = '';
  private companyConfig;

  private readonly _destroying$ = new Subject<void>();
  langChangeSubscription: SubscriptionLike;

  constructor(
    private successLandingPageService: SuccessLandingPageService,
    private portalUserService: PortalUserService,
    private languageChangeService: LanguageChangeService
  ) {
    this.successLandingPageService.getShow().pipe(takeUntil(this._destroying$))
    .subscribe((data: boolean) => {
      if (data !== undefined) {
        this.show = data;
      }
    });
  }

  ngOnInit(): void {
    const preferredLang = sessionStorage.getItem(StorageConstants.selectedlanguage ?? locales[0].code);
    this.getCompanyConfig(preferredLang);
    this.langChangeSubscription = this.languageChangeService.currentLanguage.subscribe((lang: string) => {
      this.getCompanyConfig(lang);
    })
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  closeModal(){
    this.successLandingPageService.setShow(false);
  }

  public async getCompanyConfig(lang: string) {
    await this.portalUserService.getCompanyInfo(CurrentUser.details?.impersonateUserId > 0 ?
      CurrentUser.details.impersonateUserCompanyCode : CurrentUser.details.companyCode);
    const companyConfigValue = sessionStorage.getItem(AppConstants.companyConfigStorageKey);
    if(companyConfigValue){
      this.companyConfig = (JSON.parse(atob(companyConfigValue)));
      if (this.companyConfig.ssnValidationSuccess) {
        this.nextStepMessage = lang === locales[1].code ?
          this.companyConfig.ssnValidationSuccess.EsMessage :
          this.companyConfig.ssnValidationSuccess.EnMessage;
        this.companyLink = this.companyConfig.ssnValidationSuccess.URL;
      }
    }
  }

}

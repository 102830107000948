import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/appconstants';
import { FeatureStateService } from 'src/app/core/services/featureStateService';
import { StorageConstants } from 'src/app/core/storageconstants';
import { AttestationStatusEnum } from 'src/app/core/userrole';
import { AttestationServiceProxy,
  CaregiverParticipantRelAttestationResponse } from 'src/app/external-user/services/v4ApiServices/v4ApiServices.services';
import { xIcon } from '@progress/kendo-svg-icons'

@Component({
  selector: 'app-caregiver-participant-rel',
  templateUrl: './caregiver-participant-rel.component.html',
  styles: [
  ]
})
export class CaregiverParticipantRelComponent implements OnInit {

  @Input() participantData;
  participantId;
  CaregiverParticipantDetails: CaregiverParticipantRelAttestationResponse;
  caregiverParticipantData = JSON.parse(sessionStorage.getItem(StorageConstants.CaregiverParticipantRel));
  @Input() solomonId;
  clientRel;
  isAuthRep;
  companyConfig;
  relationshipAttestationNo;
  popupWidth = 500;
  mainpopupWidth = 500;
  showConfirmation = false;
  isConfirm = undefined;
  relationshipTypesData;
  relationshipToClientId = 0;
  isAuthRepresentative = 0;
  showRelAuth = false;
  relTypeError = false;
  authRepError = false;
  showConfirmModal = false;
  relRejected = true;
  isCaregiverRelEnabled = false;
  @Output() emitDocAttestation = new EventEmitter();
  @Output() emitnextParticipant = new EventEmitter();
  @Input() showCloseBtn;
  @Output() cgRelModalClose = new EventEmitter();
  @Input() languageText;
  @Output() langChange = new EventEmitter();
  @Input() isImpersonateUser;

  // Icons
  public xIcon = xIcon;


  constructor(public activatedRoute: ActivatedRoute, private toaster: ToastrService,
              private AttestationServiceApi: AttestationServiceProxy,
              private featureStateService: FeatureStateService) {
      const companyConfigValue = sessionStorage.getItem(AppConstants.companyConfigStorageKey);
      this.companyConfig = (JSON.parse(atob(companyConfigValue)));
      this.isCaregiverRelEnabled = this.featureStateService.isCaregiverRelValid();

  }

  ngOnInit(): void {
    this.participantId = this.participantData.participantId;
    if (this.isCaregiverRelEnabled) {
      this.getCaregiverParticipantDetails();
    }
    this.relationshipAttestationNo = this.companyConfig.relationshipAttestationPhoneNumber;
    this.getRelationTypes();
  }

  changeLanguage() {
    this.langChange.emit();
  }

  closeCgRelModal() {
    this.cgRelModalClose.emit();
  }

  getCaregiverParticipantDetails() {
    this.CaregiverParticipantDetails = this.participantData;
    if (this.CaregiverParticipantDetails.relationshipConfirmation === AttestationStatusEnum.CaregiverRejected ||
      this.CaregiverParticipantDetails.docConfirmation === AttestationStatusEnum.InternalRejected ||
      this.CaregiverParticipantDetails.ficaConfirmation === AttestationStatusEnum.InternalRejected ||
      this.CaregiverParticipantDetails.leConfirmation === AttestationStatusEnum.InternalRejected) {
        this.relRejected = true;
      } else {
        this.relRejected = false;
      }
    if (this.relRejected) {
      this.isConfirm = false;
    }
  }

  getRelationTypes() {
    this.AttestationServiceApi.getCaregiverRelationshipTypes().subscribe(res => {
      this.relationshipTypesData = res.items;
    }, error => {
      this.toaster.error(error);
    });
  }

  validateRelType() {
    this.relTypeError = Number(this.relationshipToClientId) === 0 ? true : false;
  }
  validateAuthRep() {
    this.authRepError = Number(this.isAuthRepresentative) === 0 ? true : false;
  }

  cgPtRelChange(option) {
    if (option) {
      this.showConfirmation = false;
      this.showRelAuth = true;
    } else {
      this.showRelAuth = false;
      this.showConfirmation = true;
    }
  }

  closeModal(option) {
      this.showConfirmModal = false;
  }

  confirmContinue() {
    this.relTypeError = Number(this.relationshipToClientId) === 0 ? true : false;
    this.authRepError = Number(this.isAuthRepresentative) === 0 ? true : false;
    if (!this.relTypeError && !this.authRepError) {
      this.showConfirmModal = true;
    }
  }

  rejectService(option) {
    if (!option) {
      this.isConfirm = undefined;
    }
    this.showConfirmation = false;
  }
}

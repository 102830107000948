<kendo-dialog class="custom-dialog custom-dialog-darkOverlay" width="{{popupWidth}}" height="auto">
    <div class="attestation-header">
        <h1 data-i18n="label_user_profile" *ngIf="!employeepopup">
            {{'label_welcome_01' | langchange}}
        </h1>
        <h1 data-i18n="label_user_profile" *ngIf="employeepopup">
            {{'label_employeeinfo_01' | langchange}}
        </h1>
            <a href="javascript:void(0)" id="language-selector"
                (click)="changeLanguage()">{{languageText}}</a>
    </div>
    <div class="h-line margin-b-30"></div>
    <div class="modal-cont">
    <div class="row" *ngIf="!employeepopup">
        <div class="col-lg-12">
            <div class="modal-body h-auto bg-color-blue">
                <div>
                    <div class="margin-b-3">{{'label_welcome_02' | langchange}}</div>
                    <ol  class="attestation-list margin-b-3">
                        <li>{{'label_welcome_03' | langchange}}</li>
                        <li>{{'label_welcome_04' | langchange}}</li>
                        <li>{{'label_welcome_05' | langchange}}</li>
                        <li>{{'label_welcome_06' | langchange}}</li>
                        <li>{{'label_welcome_07' | langchange}} <a href="mailto:{{'label_welcome_08' | langchange}}">{{'label_welcome_08' | langchange}}</a> {{'label_welcome_09' | langchange}}</li>
                    </ol>
                    <div>{{'label_welcome_10' | langchange}}</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-lg" (click)="continueToEmpInfo()"
                [disabled]="isImpersonateUser" i18n="button_Continue">{{'button_Continue' | langchange}}</button>
            </div>
        </div>
    </div>
</div>
<div class="modal-cont">
    <div class="row" *ngIf="employeepopup">
        <div class="col-lg-12 col-12">
            <div class="modal-body h-auto bg-color-blue">
                <div>
                    <div>
                        <ul class="attestation-list margin-b-5">
                            <li>{{'label_employeeinfo_02' | langchange}}</li>
                            <li>{{'label_employeeinfo_03' | langchange}} <a href="mailto:{{'label_employeeinfo_04' | langchange}}">{{'label_employeeinfo_04' | langchange}}.</a></li>
                        </ul>

                    </div>
                    <div  class="margin-b-3">
                        <div class="row">
                            <div class="col-md-2 col-2"></div>
                            <div class="col-md-10 col-10">
                                <div class="row margin-b-2">
                                    <div class="col-md-4 col-4"><b>{{'label_item_name' | langchange}}</b></div>
                                    <div class="col-md-8 col-8"><b>{{'label_item_value' | langchange}}</b></div>
                                </div>
                                <div class="row margin-b-2">
                                    <div class="col-md-4 col-4">{{'label_providerOneiD' | langchange}}</div>
                                    <div class="col-md-8 col-8">{{demographicDetails.providerOneId}}</div>
                                </div>
                                <div class="row margin-b-2">
                                    <div class="col-md-4 col-4">{{'label_FirstName' | langchange}}</div>
                                    <div class="col-md-8 col-8">{{demographicDetails.firstName}}</div>
                                </div>
                                <div class="row margin-b-2">
                                    <div class="col-md-4 col-4">{{'label_MiddleName' | langchange}}</div>
                                    <div class="col-md-8 col-8">{{demographicDetails.middleName}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 col-4">{{'label_LastName' | langchange}}</div>
                                    <div class="col-md-8 col-8">{{demographicDetails.lastName}}</div>
                                </div>
                            </div>
                            <div class="col-md-2 col-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" *ngIf="!isdemograpRejected">
                <button type="button" class="btn btn-secondary btn-lg" (click)="stopEmpInfo()"
                [disabled]="isImpersonateUser" i18n="button_Ok">{{'button_stop' | langchange}}</button>
                    <button type="button" class="btn btn-primary btn-lg" (click)="continueFurther()"
                    [disabled]="isImpersonateUser" i18n="button_Ok">{{'button_Continue' | langchange}}</button>
            </div>
            <div class="modal-footer" *ngIf="isdemograpRejected">
                <button type="button" class="btn btn-secondary btn-lg" (click)="closeModal()"
                i18n="button_Close">{{'button_Close' | langchange}}</button>
            </div>
        </div>
    </div>
</div>
</kendo-dialog>
<main class="page-wrap landing-bg">
  <section class="container-fluid landing-container">
      <div class="row cdms-enroll-status">
          <div class="col-lg-12 enroll-box">
              <div class="row">
                  <div class="head-container-logo">
                      <a>
                          <img alt="CDMS Logo" src="./../../assets/images/logo.svg" width="181" />
                      </a>
                  </div>
                  <div class="head-container-logo">
                    <img alt="Direct My Care Logo" src="./../../assets/images/DirectMyCare_Blue_Logo.png" width="110"/>
                  </div>
                  </div>
                  <h1 class="text-danger">Sign Out</h1>
                  <div>
                      <h2 class="text-danger">You have been successfully signed out. Please click <a href="/">here to sign in </a></h2>
                  </div>
              </div>
      </div>
  </section>
</main>
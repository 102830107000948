export interface ILocale {
    id: number;
    code: string;
    text: string;
    u2LangCode: string;
}
export const locales: ILocale[] = [
    {
        id: 1,
        code: 'en',
        text: 'English',
        u2LangCode: 'Eng'
    },
    {
        id: 2,
        code: 'es',
        text: 'Español',
        u2LangCode: 'Esp'
    }
];

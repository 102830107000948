import { ApplicationInsightsConstants } from 'src/app/core/application-insights-constants';
import { CurrentUser } from './../currentuser';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  appInsights: ApplicationInsights;


  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({ config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableCorsCorrelation: true,
      enableAutoRouteTracking: true
    } });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  public logPageView(name: string, url?: string, properties?: any, measurements?: any, duration?: number): void {
    if (environment.production) {
      this.appInsights.trackPageView({name:name, uri:url, properties:this.addUserData(properties),measurements: measurements});
    }
  }

  public logEvent(name: string, properties?: any, measurements?: any): void {
    if (environment.production) {
      this.appInsights.trackEvent({name:name,measurements: measurements} , this.addUserData(properties));
    }
  }

  public logError(error: Error, properties?: { [key: string]: string }): void {
    if (environment.production) {
      this.appInsights.trackException({ exception: error}, this.addUserData(properties));
    }
  }

  //(default severity level - Verbose(0))
  //(traces are disabled from environment.ts for all env)
  // also lower loggingLevelTelemetry to 0 in environment.ts file if traces are needed.
  public trackTrace(message: string, properties?: { [name: string]: string }, severityLevel?: SeverityLevel): void {
    if (environment.logging.enableTrace) {
      this.appInsights.trackTrace({ message: message, severityLevel:severityLevel}, this.addUserData(properties));
    }
  }

  public trackTraceEnv(environments: string[], message: string, properties?: { [name: string]: string }, severityLevel?: SeverityLevel): void {
    if (environments.includes(environment.envName)) {
      this.appInsights.trackTrace({ message: message, severityLevel:severityLevel}, this.addUserData(properties));
    }
  }

  public trackMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    if (environment.production) {
      this.appInsights.trackMetric( { name: name, average: average }, this.addUserData(properties));
    }
  }


  public setAuthenticatedUserId(): void {
    this.appInsights.setAuthenticatedUserContext(
        CurrentUser.details.solomonId ?? CurrentUser.details.id?.toString(),
        CurrentUser.details.roleName ? CurrentUser.details.roleName.replace(/[\s,;=|]/g, '') : '');
  }

  private addUserData(properties: any) {
    if (CurrentUser.details) {
      this.setAuthenticatedUserId();
      if (properties) {
        properties.SolomonId = CurrentUser.details.solomonId;
        properties.UserId = CurrentUser.details.solomonId ?? CurrentUser.details.id?.toString();
        properties.ImpersonatedRole = CurrentUser.details.impersonateUserRole || undefined;
        properties.ImpersonatedUserId = CurrentUser.details.impersonateUserId || undefined;
        properties.Source = ApplicationInsightsConstants.source;
        properties.SourceName = ApplicationInsightsConstants.sourceName;
      } else {
        properties = {
          Solomonid: CurrentUser.details.solomonId,
          UserId: CurrentUser.details.solomonId ?? CurrentUser.details.id?.toString(),
          ImpersonatedRole : CurrentUser.details.impersonateUserRole || undefined,
          ImpersonatedUserId : CurrentUser.details.impersonateUserId || undefined,
          Source: ApplicationInsightsConstants.source,
          SourceName: ApplicationInsightsConstants.sourceName,
        };
      }
      return properties;
    }
  }
}

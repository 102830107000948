<kendo-dialog class="custom-dialog custom-dialog-darkOverlay" width="{{popupWidth}}" height="auto">
    <div class="attestation-header">
        <h1 data-i18n="label_user_profile">
            {{'label_thankyou_01' | langchange}}
        </h1>
            <a href="javascript:void(0)" id="language-selector"
                (click)="changeLanguage()">{{languageText}}</a>
    </div>
    <div class="h-line margin-b-30"></div>
    <div class="row">
        <div class="col-lg-12">
            <div class="modal-body h-auto bg-color-blue">
                <div>
                    <div class="margin-b-3">{{'label_thankyou_02' | langchange}}</div>
                    <div>
                        {{'label_thankyou_03' | langchange}}
                        <span class="link-color">{{'label_thankyou_04' | langchange}}</span>
                        {{'label_thankyou_05' | langchange}}
                    </div>
                    <button (click)="toWorkdayURL()" [disabled]="isImpersonateUser"
                        class="padding-left5 btn pr-0 margin-r-1-em attestation-workday">
                        <img alt="Workday" src="assets/images/workday-logo.png">
                    </button>
                </div>
            </div>
        </div>
    </div>
</kendo-dialog>
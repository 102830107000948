import { Injectable, Injector, ErrorHandler } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  public handleError(error: any): void {

    this.logError(error);
  }
  logError(error: any) {
    super.handleError(error);
  }
}

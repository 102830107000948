import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  constructor() { }

  public notificationsCount = new BehaviorSubject(true);
  currentCount = this.notificationsCount.asObservable();

  public attestationCompleted = new BehaviorSubject(false);
  currentAttestation = this.attestationCompleted.asObservable();

  // Notifications Count
  getUnreadCount(getCount: boolean) {
    this.notificationsCount.next(getCount);
  }

  // Attestation Completed
  isAttestationCompleted(value: boolean) {
    this.attestationCompleted.next(value);
  }
}

import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../core/currentuser';
import { AppConstants } from '../core/appconstants';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  ngOnInit() {
    CurrentUser.details = undefined;
    sessionStorage.clear();
    AppConstants.headerLoading = false;
    AppConstants.showLoading = false;
  }

}

export class AccessRightsKeys {
  public static readonly GetRoles = 'GetRoles';

  public static readonly GetAllStates = 'GetAllStates';

  public static readonly GetUsers = 'GetUsers';

  public static readonly GetUserEmail = 'GetUserEmail';

  public static readonly GetPortalAdminUserRole = 'GetPortalAdminUserRole';

  public static readonly UpdateUserRole = 'UpdateUserRole';

  public static readonly DeleteUserRole = 'DeleteUserRole';

  public static readonly SaveAnnouncement = 'SaveAnnouncement';

  public static readonly DeleteAnnouncement = 'DeleteAnnouncement';

  public static readonly UpdateAnnouncement = 'UpdateAnnouncement';

  public static readonly ImpersonateUser = 'ImpersonateUser';

  public static readonly GetAnnouncements = 'GetAnnouncements';

  public static readonly GetAllCaseLoad = 'GetAllCaseLoad';

  public static readonly GetCaseLoadDetail = 'GetCaseLoadDetail';

  public static readonly DeleteCaseLoad = 'DeleteCaseLoad';

  public static readonly AddCaseLoad = 'AddCaseLoad';

  public static readonly EditCaseLoad = 'EditCaseLoad';

  public static readonly GetParticipantsDashbaord = 'GetParticipantsDashbaord';

  public static readonly GetSFDashbaord = 'GetSFDashbaord';

  public static readonly ManageParticipant = 'ManageParticipant';

  public static readonly ManageSF = 'ManageSF';

  public static readonly GetParticipantDetail = 'GetParticipantDetail';

  public static readonly GetSFDetail = 'GetSFDetail';

  public static readonly GetEmployerDetail = 'GetEmployerDetail';

  public static readonly GetCaregiverDetail = 'GetCaregiverDetail';

  public static readonly GetShiftSummaryDashboard = 'GetShiftSummaryDashboard';

  public static readonly GetMCOAuthorizations = 'GetMCOAuthorizations';

  public static readonly GetEORAuthorizations = 'GetEORAuthorizations';

  public static readonly GetMCOParticipants = 'GetMCOParticipants';

  public static readonly GetCustEmpRelations = "GetCustEmpRelations";

  public static readonly GetAllAuthorizations = 'GetAllAuthorizations';

  public static readonly GetAuthorizationDetails = 'GetAuthorizationDetails';

  public static readonly GetServiceDetails = 'GetServiceDetails';

  public static readonly GetRefreshAmount = 'GetRefreshAmount';

  public static readonly GetServiceHistory = 'GetServiceHistory';

  public static readonly GetOutstandingTimeEntries = 'GetOutstandingTimeEntries';

  public static readonly GetMissingDataEntries = 'GetMissingDataEntries';

  public static readonly GetOutstandingMilageEntries = 'GetOutstandingMilageEntries';

  public static readonly GetAssociatedUserDetails = 'GetAssociatedUserDetails';

  public static readonly GetTimeAndMilageEntry = 'GetTimeAndMilageEntry';

  public static readonly GetPayStubReports = 'GetPayStubReports';

  public static readonly GetParticipantAuthorizations = 'GetParticipantAuthorizations';

  public static readonly GetAssociatedParticipants = 'GetAssociatedParticipants';

  public static readonly GetAssociatedCaregivers = 'GetAssociatedCaregivers';

  public static readonly GetExternalUsers = 'GetExternalUsers';

  public static readonly GetSummaryReports = 'GetSummaryReports';

  public static readonly GetDetailedReports = 'GetDetailedReports';

  public static readonly GetUserDetails = 'GetUserDetails';

  public static readonly ViewDocuments = 'ViewDocuments';

  public static readonly GetShiftDetails = 'GetShiftDetails';

  public static readonly GetMileageDetails = 'GetMileageDetails';

  public static readonly SaveShift = 'SaveShift';

  public static readonly UpdateShift = 'UpdateShift';

  public static readonly SubmitShift = 'SubmitShift';

  public static readonly DeleteShift = 'DeleteShift';

  public static readonly SaveMilage = 'SaveMilage';

  public static readonly UpdateMileage = 'UpdateMileage';

  public static readonly SubmitMileage = 'SubmitMileage';

  public static readonly DeleteMileage = 'DeleteMileage';

  public static readonly ApproveShift = 'ApproveShift';

  public static readonly RejectShift = 'RejectShift';

  public static readonly ApproveMileage = 'ApproveMileage';

  public static readonly RejectMileage = 'RejectMileage';

  public static readonly ReviewAdditionalDetails = 'ReviewAdditionalDetails';

  public static readonly GetProfileDetails = 'GetProfileDetails';

  public static readonly UpdateProfileDetails = 'UpdateProfileDetails';

  public static readonly GetAmountDetails = 'GetAmountDetails';

  public static readonly GetAmountRange = 'GetAmountRange';

  public static readonly GetUnitRange = 'GetUnitRange';

  public static readonly GetCustEmpRate = 'GetCustEmpRate';

  public static readonly GetAllAgencies = 'GetAllAgencies';

  public static readonly EditAgencyAssociation = 'EditAgencyAssociation';

  public static readonly UpdateUserEmail = 'UpdateUserEmail';

  public static readonly DeleteUser = 'DeleteUser';

  public static readonly GetAllTimeEntries = 'GetAllTimeEntries';

  public static readonly GetAllMileageEntries = 'GetAllMileageEntries';

  public static readonly GetShiftServiceCode = 'GetShiftServiceCode';

  public static readonly GetMileageServiceCode = 'GetMileageServiceCode';

  public static readonly GetAttestationTemplate = 'GetAttestationTemplate';

  public static readonly CopyForward = 'CopyForward';

  public static readonly RegisterUser = 'RegisterUser';

  public static readonly GetUserDetailsByMailId = 'GetUserDetailsByMailId';

  public static readonly GetUserDetailsByRoleId = 'GetUserDetailsByRoleId';

  public static readonly GetCompaniesByState = 'GetCompaniesByState';

  public static readonly GetProgramsByCompany = 'GetProgramsByCompany';

  public static readonly GetAllStatus = 'GetAllStatus';

  public static readonly ValidateProgramCoordinator = 'ValidateProgramCoordinator';

  public static readonly GetRoleDetails = 'GetRoleDetails';

  public static readonly GetAllCanBeImpersonatedUser = 'GetAllCanBeImpersonatedUser';

  public static readonly GetCanImpersonate = 'GetCanImpersonate';

  public static readonly GetAllServiceCodes = 'GetAllServiceCodes';

  public static readonly GetPatientPayData = 'GetPatientPayData';
}

import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { StorageConstants } from '../storageconstants';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';import { switchMap, catchError } from "rxjs/operators";
import { MsalCustomService } from '../services/msal.service';
import { getAuthorityUrlForExternalUser, getAuthorityUrlForInternalUser } from '../services/msal.config';
import { AppInsightsService } from '../services/app-insights.service';
import { Router } from '@angular/router';
import { ApiURLConstants } from '../apiurlconstants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private msalService: MsalService, private msalCustomService: MsalCustomService,
        public appInsightsService: AppInsightsService, private router: Router) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userType = sessionStorage.getItem(StorageConstants.userType);
        const sessionHeader = sessionStorage.getItem(StorageConstants.sessionId);
        const scopes = [environment.graphScopes[0]];
        const isInternalUser = userType == StorageConstants.internalUserValue;
        let currentLoggedInAccount : AccountInfo;
        const allAccounts = this.msalService.instance.getAllAccounts();
        const isSecureUrl = !this.isOpenUrl(request);
        if (!isInternalUser)
        {
            scopes.push(environment.apiScope);
            currentLoggedInAccount =  allAccounts.filter(x => x.idTokenClaims.aud === environment.clientIdB2C)[0];
        } else {
            scopes.push(environment.apiScope);
            currentLoggedInAccount =  allAccounts.filter(x => x.idTokenClaims.aud === environment.ClientIdAD)[0];
        }
        if(currentLoggedInAccount !== undefined && currentLoggedInAccount !== null){
            return this.msalService.acquireTokenSilent({scopes, account : currentLoggedInAccount})
            .pipe(
                catchError((e) => {
                    this.appInsightsService.logError(new Error("error from acquire token silent..."+e));
                    const redirectStartPage = window.location.href;
                    const authority = isInternalUser ? getAuthorityUrlForInternalUser() : getAuthorityUrlForExternalUser();
                    const langselect = this.msalCustomService.getLanguage();
                    this.msalService.acquireTokenRedirect({scopes, redirectStartPage, authority: authority, 
                        extraQueryParameters: { ui_locales: langselect }});
                    return EMPTY;
                }),
                switchMap((result: AuthenticationResult) => {
                    const token = result.accessToken;
                    if (!token && isSecureUrl) {
                        this.navigateToSignOut();
                        return EMPTY;
                    }

                    this.traceRequest('Condition 1', token, userType, sessionHeader, result, request);
                    
                    const requestClone = request.clone({
                      setHeaders: {
                        Authorization: `Bearer ${token}`,
                        usertype: `${userType}`,
                        sessionId: `${sessionHeader || ''}`,
                      },
                    });
                    return next.handle(requestClone);
                })
            );
        } else {
            this.appInsightsService.trackTrace('Initiating impersonation of external user from U2 portal');
            
            if (isSecureUrl) {
                this.navigateToSignOut();
                return EMPTY;
            }

            this.traceRequest('Condition 2', null, userType, sessionHeader, null, request);

            const requestClone = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${null}`,
                    usertype: `${userType}`,
                    sessionId: `${sessionHeader || ''}`
                },
            });
            return next.handle(requestClone);
        }
    }

    public navigateToSignOut() {
        this.msalService.logoutRedirect()
            .pipe(catchError(() => this.router.navigateByUrl('signout')))
            .subscribe();
    }
    
    public isOpenUrl(request: HttpRequest<any>) {
        const openUrls = [ApiURLConstants.SetImpersonateSessionDetailsUrl];
        return openUrls.some(path => request.url.includes(path));
    }

    private traceRequest(
        message: string,
        token: string | null,
        userType: string,
        sessionHeader: string,
        result?: AuthenticationResult,
        request?: HttpRequest<any>
    ) {
        if (request) {
            const tokenText = token && typeof token == "string" && token?.substring(0, 5);
            const oid = result?.idTokenClaims && result?.idTokenClaims['oid'];
            const logMessage = `V4 Interceptor [${message}] - text: ${tokenText}, userType: ${userType}, ` +
            `url: ${request.url}, method: ${request.method}, sessionHeader: ${sessionHeader}, oid: ${oid}`;
            const environments = ['dev', 'qa', 'uat', 'pre-prod', 'production'];
            this.appInsightsService.trackTraceEnv(environments, logMessage);
        }
    }
}

<header [class]="['head-container', 'env-' + environment]"
(click)="debugChangeEnvironmentStyle($event)">
    <section class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 padding-0">
                <div class="head-container-logo d-inline-block">
                    <a
                        href='javascript:void(0)'
                        (click)="gotoHomePage()"
                    >
                        <img
                            alt="CDMS Logo"
                            src="./../../assets/images/logo.svg"
                            width="181"
                        />
                    </a>
                </div>
                <div class="d-inline-block margin-l-5">
                    <img
                        alt="Direct Care Logo"
                        src="./../../assets/images/DirectMyCare_Blue_White.png"
                        width="130"
                        />
                </div>
            </div>
            <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                <div class="pull-right head-usersetting">
                    <ul>
                        <li
                            kendoTooltip
                            #tooltip="kendoTooltip"
                            *ngIf="environment !== 'production'"
                            position="left"
                            tooltipClass="dark"
                        >
                            <span
                                class="env-badge"
                                title="This is not a production environment"
                                (click)="tooltip.show(tooltip)"
                            >{{environment}}</span>
                        </li>
                        <li>
                            <notification-count
                                [canshow]="isNotificationEnabled"
                                *ngIf="showNotifictaionIcon"
                            ></notification-count>
                        </li>
                        <li>
                            <div
                                *ngIf="(loggedInUser.userAuthenticated && !loggedInUser.isInternalUser) || (loggedInUser.details && loggedInUser.details.impersonateUserId === 0 && !(loggedInUser.details.canImpersonate))">
                                <div>
                                    <a
                                        class="text18"
                                        href="javascript:void(0)"
                                        (click)="redirectToProfile()"
                                    >{{ appConstants.userDisplayName }}</a>
                                </div>
                                <span
                                    *ngIf="loggedInUser.details && loggedInUser.isInternalUser"
                                    class="head-user"
                                >{{loggedInUser.details.customRoleDisplayText + ' - '+ (loggedInUser.isInternalUser ?
                                    'Internal' : loggedInUser.details.stateCode)}}</span>
                                <div
                                    class="dropdown dropdown-width"
                                    *ngIf="loggedInUser.details.roleText && !loggedInUser.isInternalUser"
                                >
                                    <div>
                                        <a
                                            href=""
                                            class="dropdown-toggle"
                                            id="dropdownImpersonate"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <div ng-bind-html="userName"></div><span
                                                class="caret caret-dropdown fl-r"
                                            ></span>
                                            <span class="head-user">{{loggedInUser.details.customRoleDisplayText + ' -
                                                '+ (loggedInUser.isInternalUser ? 'Internal' :
                                                loggedInUser.details.stateCode)}}</span>
                                        </a>
                                        <ul
                                            class="dropdown-menu"
                                            aria-labelledby="dropdownImpersonate"
                                        >
                                            <li
                                                [ngClass]="loggedInUser.details.roleId === item.roleId ? 'color-grey' : 'dropdown-item'"
                                                *ngFor="let item of roles"
                                                (click)="changeRole(item.roleId, item.roleName)"
                                            >{{item.roleName}}</li>
                                            <li
                                                class="color-blue"
                                                *ngIf="loggedInUser.details.allRoles.length>1"
                                                (click)="openSetPrimaryPopup()"
                                            >Set Primary Role</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div
                                class="dropdown dropdown-width"
                                *ngIf="loggedInUser.details && loggedInUser.details.impersonateUserId > 0"
                            >
                                <div>
                                    <a
                                        class="text18"
                                        href="javascript:void(0)"
                                        (click)="redirectToProfile()"
                                    >{{ loggedInUser.details.impersonateFirstName + ' ' +
                                        loggedInUser.details.impersonateLastName }}</a>
                                </div>
                                <a
                                    href=""
                                    class="dropdown-toggle"
                                    id="dropdownImpersonate"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <div ng-bind-html="userName"></div><span
                                        class="caret caret-dropdown fl-r"
                                    ></span>
                                    <span class="head-user">{{loggedInUser.details.customRoleDisplayText + ' - '+
                                        loggedInUser.details.impersonateUserStateCode}}</span>
                                </a>
                                <ul
                                    class="dropdown-menu p-0"
                                    aria-labelledby="dropdownImpersonate"
                                >
                                    <li
                                        [ngClass]="loggedInUser.details.ImpersonateUserRoleId === item.roleId || loggedInUser.details.impersonateUserId > 0 ? 'color-grey' : 'dropdown-item'"
                                        *ngFor="let item of roles"
                                        (click)="changeRole(item.roleId, item.roleName)"
                                    >{{item.roleName}}</li>
                                </ul>
                            </div>

                            <div
                                class="dropdown dropdown-width"
                                *ngIf="(loggedInUser.userAuthenticated && loggedInUser.isInternalUser && !loggedInUser.details) || (loggedInUser.details && loggedInUser.details.impersonateUserId === 0 && loggedInUser.details.canImpersonate)"
                            >
                                <div>
                                    <div *ngIf="loggedInUser.userAuthenticated">
                                        <a class="text18">{{ appConstants.userDisplayName }}</a>
                                    </div>
                                    <a
                                        *ngIf="loggedInUser.details && loggedInUser.details.impersonateUserId === 0 && loggedInUser.details.canImpersonate"
                                        href=""
                                        class="dropdown-toggle"
                                        id="dropdownImpersonate"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <div ng-bind-html="userName"></div><span
                                            class="caret caret-dropdown fl-r"
                                        ></span>
                                        <span class="head-user">{{loggedInUser.details.customRoleDisplayText + ' - '+
                                            (loggedInUser.isInternalUser ? 'Internal' :
                                            loggedInUser.details.stateCode)}}</span>
                                    </a>
                                    <ul
                                        class="dropdown-menu p-0"
                                        aria-labelledby="dropdownImpersonate"
                                    >
                                        <li><a
                                                href="javascript:void(0)"
                                                data-i18n="menu_Impersonate"
                                                (click)="toggleImpersonateUsersPopup(true);"
                                            >Impersonate</a></li>
                                    </ul>

                                </div>
                            </div>
                        </li>
                        <li>
                            <a
                                href="javascript:void(0)"
                                id="signout"
                                data-i18n="menu_Logout"
                                (click)="signOutFromApp()"
                            >{{'menu_Logout'| langchange}}</a>
                        </li>
                        <li>
                            <a
                                href="javascript:void(0)"
                                id="language-selector"
                                *ngIf="!loggedInUser.isInternalUser || (loggedInUser.details && loggedInUser.details.impersonateUserId>0)"
                                (click)="languageChange()"
                            >{{languageText}}</a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </section>
</header>

<div
    class="container-fluid grey-box"
    *ngIf="loggedInUser.details && loggedInUser.details.impersonateUserId>0">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>
                <em>You are currently impersonated as <strong>{{loggedInUser.details.impersonateFirstName}}
                        {{loggedInUser.details.impersonateLastName}}</strong>. It is on a read only mode.</em>&nbsp;
                <button
                    type="button"
                    class="btn btn-primary btn-lg"
                    (click)="endImpersonation()"
                >{{'label_end_impersonation' | langchange}}</button>
            </h4>
        </div>
    </div>
</div>

<nav class="top-nav">
    <ul>
        <li><a
                id="home"
                href="javascript:void(0)"
                [ngClass]="{'selected': selectedMenuIndex === 1}"
                (click)="setSelectedMenu(1)"
            >{{'menu_Home' | langchange}}</a></li>
        <li><a
                id="faq"
                href="javascript:void(0)"
                [ngClass]="{'selected': selectedMenuIndex === 2}"
                (click)="setSelectedMenu(2)"
            >{{'menu_FAQ' | langchange}}</a></li>
        <li><a
                id="contactus"
                href="javascript:void(0)"
                [ngClass]="{'selected': selectedMenuIndex === 3}"
                (click)="setSelectedMenu(3)"
            >{{'menu_ContactUs' | langchange}}</a></li>
    </ul>
</nav>

<kendo-dialog
    *ngIf="appConstants.sessionExpires"
    class="custom-dialog session-timeout"
    title="{{'notification_information' | langchange}}"
    [width]="400"
>
    <p class="session-timeout-txt">{{'notification_msg_timeout' | langchange }}</p>
    <kendo-dialog-actions>
        <button
            kendoButton
            class="btn-green"
            (click)="signOutFromApp()"
            primary="true"
        >{{'button_Ok' | langchange}}</button>
    </kendo-dialog-actions>
</kendo-dialog>


<kendo-dialog
    title="Impersonate User"
    (close)="toggleImpersonateUsersPopup(false)"
    class="custom-dialog"
    *ngIf="showImpersonateUserPopup"
    width="900"
>
    <div class="container-fluid">
        <div class="form-horizontal">
            <div class="form-group">
                <label class="col-sm-1 control-label">User</label>
                <div class="col-sm-7">
                    <p
                        class="form-control-static"
                        *ngIf="!(impersonateUserSelectedItem.ImpersonateLastName || impersonateUserSelectedItem.ImpersonateFirstName)"
                    ></p>
                    <p
                        class="form-control-static"
                        *ngIf="(impersonateUserSelectedItem.ImpersonateLastName || impersonateUserSelectedItem.ImpersonateFirstName)"
                    >
                        {{impersonateUserSelectedItem.ImpersonateFirstName}}
                        {{impersonateUserSelectedItem.ImpersonateLastName}}
                        ({{impersonateUserSelectedItem.ImpersonateRoleDisplayText}})
                    </p>
                </div>
                <div class="col-sm-4">
                    <kendo-dialog-actions class="m-t-0">
                        <button
                            kendoButton
                            (click)="impersonateSelectedUser();"
                            class="btn btn-green btn-lg"
                            [disabled]="(impersonateUserSelectedItem.ImpersonateLastName || impersonateUserSelectedItem.ImpersonateFirstName) ? false : true"
                        >Impersonate</button>
                    </kendo-dialog-actions>
                </div>
            </div>
        </div>
        <kendo-grid
            id="ImpersonateGridAll"
            [loading]="loading"
            [data]="ImpersonateGridAll"
            [pageSize]="5"
            [skip]="skipImpersonateGridAll"
            [filter]="filterImepersonateGridAll"
            [sort]="sortImpersonateGridAll"
            [loading]="loadingImpersonateGridAll"
            [scrollable]="'none'"
            [pageable]="true"
            [selectable]="ImpersonateGridAllSelectableSettings"
            class="cdms-custom-grid action-grid cust-kendo-grid"
            filterable="menu"
            [sortable]="true"
            (filterChange)="filterChange($event)"
            (sortChange)="sortChange($event)"
            (pageChange)="pageChange($event)"
            (selectionChange)="rowChange($event)"
            [(selectedKeys)]="selectedrow"
            [kendoGridSelectBy]="'userRoleId'"
        >
            <kendo-dialog-titlebar>
                <div class="dialog-titlebar">
                    <span class="k-icon k-i-print"></span> Print this page
                </div>
            </kendo-dialog-titlebar>
            <kendo-grid-column
                field="solomonId"
                title="Solomon Id"
                [width]="150"
            >
                <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter
                    let-column="column"
                    let-filterService="filterService"
                >
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false"
                    >
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
                field="firstName"
                title="First Name"
                [width]="150"
            >
                <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter
                    let-column="column"
                    let-filterService="filterService"
                >
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false"
                    >
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
                field="lastName"
                title="Last Name"
                [width]="100"
            >
                <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter
                    let-column="column"
                    let-filterService="filterService"
                >
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false"
                    >
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
                field="roleText"
                title="Role Name"
                [width]="100"
            >
                <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter
                    let-column="column"
                    let-filterService="filterService"
                >
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false"
                    >
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
                field="companyCode"
                title="Company Code"
                [width]="100"
            >
                <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter
                    let-column="column"
                    let-filterService="filterService"
                >
                    <kendo-grid-string-filter-menu
                        [column]="column"
                        [filter]="filter"
                        [filterService]="filterService"
                        [extra]="false"
                    >
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>
                {{noRecords}}
            </ng-template>
        </kendo-grid>
    </div>
</kendo-dialog>
<kendo-dialog
    title="{{'label_SelectPrimaryRole'|langchange}}"
    (close)="togglePrimaryRolePopup()"
    class="custom-dialog"
    [width]="500"
    *ngIf="setPrimaryRole"
>
    <div *ngFor="let item of roles">
        <input
            id={{item.roleId}}
            type="radio"
            name="role"
            [value]="item.roleId"
            class="roleRadio"
            (change)="changePrimary(item.roleId)"
            [ngModel]="primaryRole"
            kendoRadioButton
            [disabled]="isImpersonate"
        />
        <label
            class="margin-l-5"
            for={{item.roleId}}
        >{{item.roleName}}</label>
    </div>
    <span
        *ngIf="primaryRoleRequired"
        class="help-block"
    >{{'label_SelectPrimaryRole'|langchange}}</span>
    <kendo-dialog-actions>
        <button
            kendoButton
            (click)="updatePrimaryRole()"
            class="btn-green"
        >{{'button_Ok'|langchange}}</button>
        <button
            kendoButton
            *ngIf="loggedInUser.details.roleText"
            (click)="togglePrimaryRolePopup()"
            class="btn-grey"
        >{{'button_Cancel'|langchange}}</button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
    title="{{'label_MsgFromCDCN'|langchange}}"
    (close)="enablePrimaryRolePopup()"
    class="custom-dialog"
    [width]="500"
    *ngIf="multiRoleMsg"
>
    <div class="col-md-2 col-md-offset-10">
        <a
            href="javascript:void(0)"
            id="language-selector"
            class="pull-right"
            *ngIf="!loggedInUser.details.roleText && (!loggedInUser.isInternalUser || (loggedInUser.details && loggedInUser.details.impersonateUserId>0))"
            (click)="languageChange()"
        >{{languageText}}</a>
    </div>

    <p>
        {{'label_multiRole' | langchange}}
    </p>
    <kendo-dialog-actions>
        <button
            kendoButton
            (click)="enablePrimaryRolePopup()"
            class="btn-green"
        >{{'button_Ok'|langchange}}</button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
    title="{{'label_MsgFromCDCN'|langchange}}"
    (close)="initiateUserSession()"
    class="custom-dialog"
    [width]="500"
    *ngIf="primaryRoleSet"
>
    <p>
        {{'label_primaryRoleSet' | langchange}}
    </p>
    <kendo-dialog-actions>
        <button
            kendoButton
            (click)="initiateUserSession()"
            class="btn-green"
        >{{'button_Ok'|langchange}}</button>
    </kendo-dialog-actions>
</kendo-dialog>


<app-demographic-attestation *ngIf="showCaregiverModel" [isEmployeeInfo]="showEmpInfo" [isImpersonateUser]="isImpersonate"
    [demograpRejected]="isdemograpRejected" [solomonId]="caregiverId" (changeConfirmModal)="confirmModalchange()"
    (closeConfirmModal)="signOutFromApp()" (confirmContinue)="demoGraphicscontinue()" [languageText]="languageText"
    (langChange)="languageChange()"></app-demographic-attestation>
<app-confirm-popup *ngIf="showConfirmModal" (closeConfirmModal)="confirmModalClick($event)"></app-confirm-popup>

<app-qualifications *ngIf="showQualificationCheck" [solomonId]="caregiverId" [isImpersonateUser]="isImpersonate"
[languageText]="languageText" (langChange)="languageChange()" [showBackGround]="showQualificationGrid" [showTraining]="showTrainingnGrid" [showTrainingText] = "showTrainingText" [showAttestationList]="showAttestationList"
(fromQualifications)="goToCaregiverRel()"></app-qualifications>

<app-caregiver-participant-rel *ngIf="showCgRelCheck" (emitDocAttestation)="goToDocAttestation()" [isImpersonateUser]="isImpersonate"
(emitnextParticipant)="goToNextParticipant()" [languageText]="languageText" (langChange)="languageChange()"
[solomonId]="caregiverId" [participantData]="currentParticipantData"></app-caregiver-participant-rel>

<app-doc-attestation *ngIf="showCgDocCheck" (emitFicaAttestation)="goToFicaAttestation()"
[languageText]="languageText" (langChange)="languageChange()" [isImpersonateUser]="isImpersonate"
[solomonId]="caregiverId" [participantData]="currentParticipantData"></app-doc-attestation>

<app-fica-attestation *ngIf="showCgFicaCheck" (emitCompleteAttestation)="goToCompleteAttestation()"
[languageText]="languageText" (langChange)="languageChange()" [isImpersonateUser]="isImpersonate"
[solomonId]="caregiverId" [participantData]="currentParticipantData"></app-fica-attestation>

<app-workday-popup *ngIf="showWorkDayCheck" [languageText]="languageText" (langChange)="languageChange()"
[isImpersonateUser]="isImpersonate"></app-workday-popup>

<app-landingpage-success-popup>
</app-landingpage-success-popup>

<kendo-dialog title="Loading" *ngIf="pageLoading" class="loading-dialog" (close)="close('cancel')">
    <div class="modal-body">
      <section class="container-fluid">
        <div>
          <div class="margin-b-30">
            <div class="sk-fading-circle">
              <div class="sk-circle1 sk-circle"></div>
              <div class="sk-circle2 sk-circle"></div>
              <div class="sk-circle3 sk-circle"></div>
              <div class="sk-circle4 sk-circle"></div>
              <div class="sk-circle5 sk-circle"></div>
              <div class="sk-circle6 sk-circle"></div>
              <div class="sk-circle7 sk-circle"></div>
              <div class="sk-circle8 sk-circle"></div>
              <div class="sk-circle9 sk-circle"></div>
              <div class="sk-circle10 sk-circle"></div>
              <div class="sk-circle11 sk-circle"></div>
              <div class="sk-circle12 sk-circle"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </kendo-dialog>

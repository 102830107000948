import { Observable } from 'rxjs';

export class AppConstants {
  public static readonly loginPath = '/login';
  public static readonly emptyPath = '/';
  public static showLoading = false;
  public static sessionTimeOut = 1200; // 20 minutes
  public static sessionLogOut = 1; // 1 sec
  public static keepAliveIntervel = 300; // 5 minutes
  public static days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
  public static fulldays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public static monthshort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public static readonly weekCountName = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen'];
  public static defaultTimeFormat = 'hh:mm a';
  public static notificationFormDataKey = 'notification';
  public static notificationFileDataKey = 'attachment_';
  public static defaultLanguage = 'en';
  public static preferredLanguageData = {};
  public static defaultDateFormat = 'MM/dd/yyyy';
  public static dateFormatWithDay = 'EEE, dd MMM';
  public static startDateOfWeek = 'startDateOfWeek';
  public static selectedParticipantId = 'selectedParticipantId';
  public static selectedParticipantIdForDR = 'selectedParticipantIdForDR';
  public static selectedParticipantIdForEA = 'selectedParticipantIdForEA';
  public static selectedCaregiverId = 'selectedCaregiverId';
  public static directlyToAuth = 'goDirectlyToAuth';
  public static selectedAgencyId = 'selectedAgencyId';
  public static get noRecordText(): string { return AppConstants.preferredLanguageData['label_no_data_to_display'].value; }
  public static get httpErrorMessage(): string { return AppConstants.preferredLanguageData['notification_Http_InternalError'].value; }
  public static selectUserToImpersonate = 'Please select a user to impersonate';
  public static selectOnlyOneColumnToFilter = 'Please select only one column at a time to filter';
  public static regexValidateHtml: RegExp = /^[^!@#$%^&*()<>{}]*$/;
  public static regexValidateAddress: RegExp = /(^$)|(^[A-Za-z0-9_.:\?,' \-#]+$)/;
  public static regexValidateZipcode: RegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;
  public static regexValidateNumber: RegExp = /^[0-9]*$/;
  public static regexValidateTextArea: RegExp = /^[a-zA-Z0-9\s.,?!'";:()@#&+\-"*%_]*$/;
  public static participantListForDR = 'participantListForDR';
  public static participantListForEA = 'participantListForEA';
  public static agencyId = 'agencyId';
  public static agencyName = 'agencyName';
  public static participantInternalId = 'participantInternalId';
  public static participantName = 'participantName';
  public static caregiverName = 'caregiverName';
  public static caregiver = 'Caregiver';
  public static caregiverId = 'caregiverId';
  public static cssPaid = 'paid';
  public static cssEdited = 'fa fa-pencil';
  public static cssReject = 'fa fa-times rejected';
  public static cssSave = 'fa fa-floppy-o grey-text';
  public static cssSubmit = 'fa fa-upload submitted green-text';
  public static cssApproved = 'fa fa-check approved';
  public static cssInProcess = 'fa fa-angle-double-right green-text';
  public static cssReadyToBePaid = 'fa fa-thumbs-up';
  public static cssDeleted = 'fa fa-trash';
  public static cssDenied = 'fa fa-minus-circle denied';
  public static cssSuspended = 'fa fa-ban suspended';
  public static cssRemovalInProgress = 'fa fa-angle-double-left fs-20 removalInProgress';
  public static cssTest = 'test-shift';
  public static cssTestApproved = 'test-shift-approved';
  public static cssNeverToBePaid = 'fa fa-thumbs-down';
  public static cssTestSubmittedIcon = 'fa fa-upload submitted orange-text';
  public static cssTestApprovedIcon = 'fa fa-check green-text';
  public static cssTestSavedIcon = 'fa fa-floppy-o grey-text';
  public static cssTestSaved = 'test-shift-saved';
  public static dateYearFormat = 'yyyy-MM-dd';
  public static deleteShiftTypes = ['Saved', 'Submitted', 'Rejected'];
  public static paid = 'Paid';
  public static reject = 'Rejected';
  public static save = 'Saved';
  public static submit = 'Submitted';
  public static approved = 'Approved';
  public static suspended = 'Suspended';
  public static denied = 'Denied';
  public static edited = 'edited';
  public static maxOccurenceValue = 2147483647;
  public static selectedUser = 'selectedUser';
  public static dateFormat24yyyy = 'yyyy-MM-dd HH:mm:ss';
  public static summaryReportExtn = '_summaryreport.pdf';
  public static detailReportExtn = '_detailreport.pdf';
  public static headerContent: { 'Content-type': 'application/json' };
  public static headerResponseType: 'arraybuffer';
  public static sessionExpires = false;
  public static employeronrecord = 'employeronrecord';
  public static employerofrecord = 'employerofrecord';
  public static confirmationBoxState = 'confirmationBoxState';
  public static userDisplayName = '';
  public static ignoredShiftEntryStatuses = ['None', 'Saved', 'Submitted', 'Rejected', 'Deleted', 'Denied', 'Suspended', 'Test', 'RemovalInProgress', 'TestSubmitted', 'TestApproved','TestSaved'];
  public static impersonatedUserId = 'impersonatedUserId';
  public static impersonatedUserRouteUrl = 'impersonatedUserRouteUrl';
  public static showErrorPage = false;
  public static errorMessage = '';
  public static currentLanguage: Observable<string>;
  public static refreshTokenUrl = null;
  public static loadRefreshIframe = false;
  public static isEsPreferredLang = false;
  public static maxMileValue = 4;
  public static headerLoading = false;
  public static msBeforeAutoSignout = 4000;
  public static unableToLoadFeatures = 'Unable to load features';
  public static companyFeaturesStorageKey = 'CompanyFeatures';
  public static companyConfigStorageKey = 'CompanyConfig';
  public static roleScreensConfigStorageKey = 'V4RoleScreensConfig';
  public static shiftDocTypeName = 'shift';
  public static shiftDocTypeSubTypeName = 'shf';
  public static gHSShiftDocTypeName = 'ghs';
  public static gHAShiftDocTypeName = 'gha';
  public static mileageDocTypeName = 'mileage';
  public static mileageUnitName = 'Mile';
  public static shiftUnitName = 'Hour';
  public static mileageDocTypeSubTypeName = 'mlg';
  public static contactYourEorLabel = 'Please contact your Employer.';
  public static shiftNotCountedTotalHours = 'Not counted in total hours.'
  public static eorNotExistMessage = 'EOR Not Exist';
  public static CaregiverClientRelRejected  = 'Caregiver Client Relationship Rejected Successfully!';
  public static CaregiverClientRelVerified  = 'Caregiver Client Relationship Verified Successfully!';
  public static newCaregiverClientRelCreated  = 'New Caregiver Client Relationship Created Successfully!';
  public static DocVerified  = 'Verified Successfully!';
  public static FicaVerified  = 'Verified Successfully!';
  public static cdwa = 'CDWA';
  public static EvvV4 = 'EVV-V4';
  public static duplicateFound = 'Duplicate Found';
  public static maxLimitReached = 'Max Limit Reached';
  // Aut Allocation Error Names
  public static notOkToDrive = 'notOkToDrive';
  public static notOkToWork = 'notOkToWork';
  public static invalidhours = 'invalidhours';
  // Attestation
  public static attestationShiftParticipantTypeCode = "SHIFT-PARTICIPANT"
  public static attestationShiftEORTypeCode = "SHIFT-EOR"
  public static attestationShiftCaregiverTypeCode = "SHIFT-CAREGIVER"
  // Rolling 12 months historical time entries
  public static viewHistoricalTimeEntryPeriod = 365;
  public static datePickerMissingMessage = 'Please select a Start Date and an End Date';
  public static datePickerStartDateMessage = 'Please select a Start Date';
  public static datePickerEndDateMessage = 'Please select an End Date';
  public static datePickerErrorMessage = 'Start Date must be before End Date';
  public static earnings = 'Earnings';
  public static defaultPayStubFileName = 'PayOutReport.pdf';
  public static defaultPayStubDetailFileName = 'PayOutServiceDetailReport.pdf';
  public static readytobePaidStatus = 'ReadyToBePaid';
  public static removalInProgress = 'RemovalInProgress';
  public static SickTimeHoursAvailableLimit = 40;
}

export enum CabinetType {
  ClientCabinet = 0,
  EmployeeCabinet = 1
}

export enum ShiftEntryStatus {
  None = 0,
  Saved = 1,
  Submitted = 2,
  Approved = 3,
  Rejected = 4,
  InProcess = 5,
  ReadyToBePaid = 6,
  Paid = 7,
  Deleted = 8,
  Denied = 9,
  Suspended = 10,
  Edited = 11,
  Test = 12,
  RemovalInProgress = 13,
  NeverToBePaid = 14,
  TestApproved = 15,
  TestSubmitted = 16,
  TestSaved = 17,
}

export enum ModelDialogLoadingType {
  None = 0,
  AlertOk = 1,
  AlertOkCancel = 2,
  LoadingPayStubReport = 3,
  LoadingSummaryReport = 4,
  LoadingDetailReport = 5,
  Loading = 6,
}

export enum ModalDialogAction {
  None,
  SubmitShiftFromPanel,
  SubmitMultipleShifts,
  AdditionalAction,
  DeleteTimeEntry,
  DeleteTimeEntryFromPanel,
  CopyForward,
  Approve,
  Reject,
  SubmitMileageFromPanel,
  DeleteMileageEntryFromPanel,
  SubmitMultipleMileage,
  DeleteMultipleMileage,
  ApproveMileageEntry,
  RejectMileageEntry,
  DeleteUserByEmail,
  ChangeUserRole,
  ChangeUserEmail
}

export enum GridType {
  Shift = 0,
  Mileage = 1,
  Total = 2,
}

export enum TimesheetActions {
  ActionRequired,
  TimeEntry,
  Approve,
  Reject,
  None,
  MileageEntry,
  RejectMileage,
  Close,
  Save,
  Update,
  Cancel,
  Submit,
  Add,
  Delete,
  Remove
}

export enum EnrollmentStatus {
  Blocked = 1,
  RegistrationInitiated = 2,
  PacketCreated = 3,
  PacketReceived = 4,
  RegistrationComplete = 5,
}

export enum HttpStatusCode {
  Conflict = 409,
  BadRequest = 400,
  Forbidden = 403,
  NotFound = 404,
  PreconditionFailed = 412,
  Unauthorized = 401,
  InternalError = 500,
  NotAcceptable = 406
}

export enum Dashboard {
  CareGiver,
  Participant,
  ServiceBroker,
  DesignatedRepresentative,
  ProgramCoordinator,
  LocalOfficeAdmin
}

export enum AdjustmentReasonsToNotShow {
  ACNChange,
  BillRateChange,
  PayRateChange
}

export enum AdjustmentReasonsToNotCalc {
  ACNChange,
  BillRateChange,
  PayRateChange
}

export const nonCalcTypes = [
  ShiftEntryStatus[ShiftEntryStatus.Denied],
  ShiftEntryStatus[ShiftEntryStatus.Rejected],
  ShiftEntryStatus[ShiftEntryStatus.Deleted],
  ShiftEntryStatus[ShiftEntryStatus.Test],
  ShiftEntryStatus[ShiftEntryStatus.TestSubmitted],
  ShiftEntryStatus[ShiftEntryStatus.TestApproved],
  AdjustmentReasonsToNotCalc[AdjustmentReasonsToNotCalc.ACNChange],
  AdjustmentReasonsToNotCalc[AdjustmentReasonsToNotCalc.BillRateChange],
  AdjustmentReasonsToNotCalc[AdjustmentReasonsToNotCalc.PayRateChange]
];

export enum AttestationCode {
  DOC = 'Doc',
  FICA = 'Fica',
  LE = 'Le'
}

export enum Actions  {
  V4_Stop_Time_Entry = 'V4 Stop Time Entry',
  V4_Hide_Relatioship = 'V4 Hide Relationship'
}

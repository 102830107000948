<section class="wrapper">

  <!-- Pre-Check Section -->
  <div *ngIf="checkResult === undefined || checkResult === null" class="wrapper-section">

    <!--  Welcome Statement -->
    <div class="wrapper-section-row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h1 class="">
          {{'landingpage-welcome-full'|langchange}}
        </h1>
      </div>
    </div>

    <!-- Verification -->
    <form [formGroup]="validationForm" (ngSubmit)="onSubmit()">
      <div class="margin-t-30 m-width-750">
        <div class="wrapper-section-row">
          <h3 class="">{{'landingpage-verification'|langchange}}</h3>
        </div>
        <div class="wrapper-section-row margin-t-30">
          <div class="col-lg-3 col-md-4 col-sm-4 margin-r-20">
            <div class="wrapper-section-row">
              <input
                kendoTextBox
                title="SSN"
                class="k-textbox"
                formControlName="ssn"
                [placeholder]="'####'"
                [maxlength]="4"
                [type]="inputType"/>
              <button
                kendoButton
                class="btn-visible"
                type="button"
                (click)="toggleVisibility()">
                {{inputType === 'password' ? ('button_Show'|langchange) : ('button_Hide'|langchange)}}
              </button>
            </div>
            <span
              class="fs-12 margin-t-5">
              {{'landingpage-enter-digits'|langchange}}
            </span>
            <div *ngIf="validationForm && validationForm?.controls['ssn'].errors !== null" class="fs-12 margin-t-5">
              <span class="help-block">
                  <ng-container *ngIf="validationForm?.controls['ssn'].errors?.pattern">
                    {{'landingpage-only-numbers'|langchange}}
                  </ng-container>
              </span>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-4">
            <button
              type="submit"
              kendoButton
              class="btn btn-primary btn-lg btn-continue"
              [disabled]="validationForm?.invalid === true || isImpersonate"
            >
            {{'button_Continue'|langchange}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Post-Check Fail Section -->
  <div *ngIf="checkResult === false" class="wrapper-section">
    <div class="">
      <h3 class="">
        {{'landingpage-fail-p1'|langchange}}
      </h3>
      <div class="wrapper-section-row-btn margin-t-20">
        <button
          type="button"
          kendoButton
          class="btn btn-primary btn-lg btn-dashboard"
          (click)="reload()"
        >
        {{'button_TryAgain'|langchange}}
        </button>
      </div>
      <h3 class="wrapper-section-row-alignment margin-t-20">
        <h3>{{'landingpage-fail-p5-1'|langchange}}</h3>
        <a href="{{companyConfig.accountNotificationEmail}}">{{companyConfig.accountNotificationEmail}}</a>
        <h3 *ngIf="companyConfig.ssnValidationFailureContactNumber">{{'landingpage-fail-p5-2'|langchange}}{{companyConfig.ssnValidationFailureContactNumber}}</h3>
      </h3>
    </div>
  </div>
</section>

import { Component, OnInit, Input, OnChanges, SimpleChanges, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModelDialogLoadingType } from 'src/app/core/appconstants';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit, OnChanges {

  @Input()
  header: string;
  @Input()
  content: string;
  @Input()
  dialogType: ModelDialogLoadingType;
  public showPopup = false;
  public sanitizedContent = '';

  constructor(private sanitizer: DomSanitizer) {}
  ngOnInit() {
    this.showDialog(this.dialogType);
    this.sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, this.content);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showDialog(this.dialogType);
  }




  public modalCancelClick() {
    this.showDialog(ModelDialogLoadingType.None);
  }

  public showDialog(state: ModelDialogLoadingType) {
    if (state === ModelDialogLoadingType.None) {
      this.showPopup = false;
    } else {
      this.showPopup = true;
    }
    this.dialogType = state;
  }

  public modalOkClick() {
    this.modalCancelClick();
  }
}

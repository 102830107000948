import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/appconstants';
import { AttestationServiceProxy } from '../../services/v4ApiServices/v4ApiServices.services';

@Component({
  selector: 'app-demographic-attestation',
  templateUrl: './demographic-attestation.component.html',
  styles: [
  ]
})
export class DemographicAttestationComponent implements OnInit {

  popUpTitle = 'Welcome to CDWA!';
  popupWidth = 800;
  @Output() langChange = new EventEmitter();
  @Output() changeConfirmModal = new EventEmitter();
  @Output() confirmContinue = new EventEmitter();
  @Output() closeConfirmModal = new EventEmitter();
  @Input() languageText;
  @Input() isImpersonateUser;
  @Input() isEmployeeInfo;
  @Input() demograpRejected;
  @Input() solomonId;
  employeepopup = false;
  isdemograpRejected = false;
  demographicDetails;
  companyConfig;
  cdwaNo;
  constructor(private AttestationServiceApi: AttestationServiceProxy, private toaster: ToastrService) { }

  ngOnInit(): void {
    if (this.isEmployeeInfo) {
      this.continueToEmpInfo();
    }
    this.isdemograpRejected = this.demograpRejected;
    const companyConfigValue = sessionStorage.getItem(AppConstants.companyConfigStorageKey);
    this.companyConfig = (JSON.parse(atob(companyConfigValue)));
    this.cdwaNo = this.companyConfig.cdwaPhoneNumber;
  }

  changeLanguage() {
    this.langChange.emit();
  }
  continueToEmpInfo() {
    AppConstants.showLoading = true;
    this.AttestationServiceApi.getCaregiverDemographicDetails(this.solomonId).subscribe(res => {
      this.demographicDetails = res;
      this.employeepopup = true;
      AppConstants.showLoading = false;
    }, error => {
      this.toaster.error(error);
    });
  }

  stopEmpInfo() {
    this.changeConfirmModal.emit();
  }

  closeModal() {
    this.closeConfirmModal.emit();
  }

  continueFurther() {
    this.confirmContinue.emit();
  }

}

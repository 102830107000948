import { Injectable } from '@angular/core';
import { AppConstants } from '../appconstants';
import { UserInfo } from 'src/app/internal-user/models/UserInfo';
import { CaregiverInfo } from 'src/app/external-user/models/caregiver-info';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public startDateOfWeek: Date;
  public selectedParticipantIdForDR: string;
  public selectedParticipantIdForEA: string;
  public selectedUser: UserInfo;
  public impersonatedUserId: number;
  public impersonatedUserRouteUrl: string;
  public participantListForDR: Array<CaregiverInfo>;
  public participantListForEA: Array<CaregiverInfo>;
  public confirmationBoxState: boolean;

  constructor(private datePipe: DatePipe) {
    this.getImpersonatedUserUrl();
    this.getParticipantListForEA();
    this.getParticipantListForDR();
    this.getStartDateOfWeek();
    this.getConfirmationBoxState();
    this.getSelectedUser();
  }

  public setConfirmationBoxState(isOpened: boolean) {
    sessionStorage.setItem(AppConstants.confirmationBoxState, JSON.stringify(isOpened));
    this.confirmationBoxState = JSON.parse(sessionStorage.getItem(AppConstants.confirmationBoxState));
  }

  public getConfirmationBoxState() {
    this.confirmationBoxState =  JSON.parse(sessionStorage.getItem(AppConstants.confirmationBoxState));
  }

  public setStartDateOfWeek(value: Date) {
    const dateValue = value;
    sessionStorage.setItem(AppConstants.startDateOfWeek, this.datePipe.transform(dateValue, AppConstants.defaultDateFormat));
    this.startDateOfWeek = new Date(sessionStorage.getItem(AppConstants.startDateOfWeek));
  }

  public getStartDateOfWeek() {
    this.startDateOfWeek = JSON.parse(sessionStorage.getItem(AppConstants.startDateOfWeek));
  }

  public setSelectedParticipantIdForDR(key: string, solomonId: string) {
    sessionStorage.setItem(key, solomonId);
    this.selectedParticipantIdForDR = sessionStorage.getItem(AppConstants.selectedParticipantIdForDR);
  }

  public setSelectedParticipantIdForEA(key: string, solomonId: string) {
    sessionStorage.setItem(key, solomonId);
    this.selectedParticipantIdForEA = sessionStorage.getItem(AppConstants.selectedParticipantIdForEA);
  }

  public setSelectedUser(key: string, userInfo: UserInfo) {
    sessionStorage.setItem(key, JSON.stringify(userInfo));
    this.selectedUser = JSON.parse(sessionStorage.getItem(AppConstants.selectedUser));
}

public getSelectedUser() {
  this.selectedUser = JSON.parse(sessionStorage.getItem(AppConstants.selectedUser));
}

public setImpersonatedUserId(key: string, userId: any) {
  sessionStorage.setItem(key, userId);
  this.impersonatedUserId = Number(sessionStorage.getItem(AppConstants.impersonatedUserId));
}

public setImpersonatedUserUrl(key: string, routeUrl: string) {
  sessionStorage.setItem(key, routeUrl);
  this.impersonatedUserRouteUrl = (sessionStorage.getItem(AppConstants.impersonatedUserRouteUrl));
}

public getImpersonatedUserUrl() {
  this.impersonatedUserRouteUrl = (sessionStorage.getItem(AppConstants.impersonatedUserRouteUrl));
}
public setParticipantListForDR(key: string, participantList: Array<CaregiverInfo>) {
  sessionStorage.setItem(key, JSON.stringify(participantList));
  this.participantListForDR = JSON.parse(sessionStorage.getItem(AppConstants.participantListForDR));
}

public setParticipantListForEA(key: string, participantList: Array<CaregiverInfo>) {
  sessionStorage.setItem(key, JSON.stringify(participantList));
  this.participantListForEA = JSON.parse(sessionStorage.getItem(AppConstants.participantListForEA));
}

public getParticipantListForEA() {
  this.participantListForEA = JSON.parse(sessionStorage.getItem(AppConstants.participantListForEA));
}

public getParticipantListForDR() {
  this.participantListForDR = JSON.parse(sessionStorage.getItem(AppConstants.participantListForDR));
}

}

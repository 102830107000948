import { AccessRightsKeys } from 'src/app/core/accessrightskeys';
import { PortalUserService } from 'src/app/core/services/portalUser.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants, ModelDialogLoadingType } from 'src/app/core/appconstants';
import { CurrentUser } from 'src/app/core/currentuser';
import { StorageConstants } from 'src/app/core/storageconstants';
import { EnrollmentStatus, UserRole } from 'src/app/core/userrole';
import { UserDashBoardService } from 'src/app/external-user/services/user-dash-board.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CommonFunctionsService } from 'src/app/core/services/common-functions.service';
import moment from 'moment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public selectedOptionTest = [{ id: null, fullName: null }];
  public dialogType: ModelDialogLoadingType;
  public showSignout = false;
  public userData: any = {};
  public solomonIdModel: any = {};
  public isDataLoaded = false;
  public redirectUrl: string;
  public disabledInternal = true;
  userId;
  dialogContent;
  public userDataEditable: any = {};
  public solomonId;
  public isParticipant: boolean;
  public participant = [];
  public isEditable = false;
  public isDR: boolean;
  public isAgencyAdmin: boolean;
  public isSF: boolean;
  public isMCO: boolean;
  public isHelpdeskWorker: boolean;
  public impersonateUserId: number;
  public stateOptions: any = { availableOptions: null, selectedOption: { id: null } };
  public careGiverDisable: boolean;
  public otherDisable: boolean;
  public loggedUserDisable: boolean;
  public solomonDisable: boolean;
  public pcDisable = true;
  public stateOfResidenceOptions: any = { availableOptions: null, selectedOption: { id: null } };
  public userStatusOptions: any = { availableOptions: null, selectedOption: { id: null } };
  public canDisableCompany: boolean;
  public canDisableSolomonId: boolean;
  public disableB2CEdit = true;
  public languageOptions: any = { availableOptions: null, selectedOption: { id: null } };
  public companiesOptions: any = { availableOption: null, selectedOptn: { id: null } };
  public canGetSummaryReports = false;
  public canGetDetailedReports = false;
  public showReportsPopup = false;
  public attestationTemplateOptions: any = { availableOptions: null, selectedOption: { id: null } };
  public drUsersOptions: any = { availableOptions: null, selectedOption: { id: null } };
  public programsOptions: any = { availableOptions: null, selectedOption: { id: null } };
  public mcoUsersOptions: any = { availableOptions: null, selectedOption: { id: null } };
  public caseManagerOptions: any = { availableOptions: null, selectedOption: null };
  public companyMissing = false;
  private avaiopt: any;
  private selectopt: any;
  public canViewOwnDocuments: boolean;
  @ViewChild('multiselect') public multiselect: any;
  dialogHeader;
  canViewOthersDocument: boolean;
  firstName;
  middleName;
  lastName;
  suffix;
  dob;
  public userEmail: string;
  slId: any;
  public isUserOnboarding = false;
  public canUpdateUserProfile = false;
  public registrationCompleted = false;
  public registrationCompleteDate: string;
  public created: string;
  public dateFormat = 'MM/DD/YYYY HH:mm:ss';
  phoneAddress: any = {};

  constructor(public service: UserDashBoardService, private router: Router,
              private routeParams: ActivatedRoute, private toaster: ToastrService,
              private portalUserService: PortalUserService, private common: CommonFunctionsService) {
    this.userEmail = this.routeParams.snapshot.params.email;
    this.stateOfResidenceOptions.selectedOption.id = 0;
    this.stateOfResidenceOptions.selectedOption.id = 0;
    this.stateOptions.selectedOption.id = 0;
    this.userStatusOptions.selectedOption.id = 0;
    this.drUsersOptions.selectedOption.id = 0;
    this.companiesOptions.selectedOptn.id = 0;
    this.languageOptions.selectedOption.id = 0;
    this.programsOptions.selectedOption.id = 0;
    this.mcoUsersOptions.selectedOption.id = 0;
    this.setUserAccess();
    this.redirectUrl = CurrentUser.details.userAccessRight.routeUrl;
  }

  ngOnInit() {
    this.getUserDetails();
  }

  public getUserDetails() {
    AppConstants.showLoading = true;
    try {
      const roleId = CurrentUser.details.impersonateUserId ? CurrentUser.details.impersonateUserRoleId : CurrentUser.details.roleId;

      this.service.getUser(this.userEmail).toPromise().then((data) => {
        this.isDataLoaded = false;
        this.userData = data.item1[0];
        const userProfile = data.item1[0];
        const selectedEcms = new Array();
        for (const element of data) {
          if ((element.cmUserId) > 0) {
            selectedEcms.push({ id: element.cmUserId });
          }
        }
        this.phoneAddress = data.item2;

        this.setUserprofileData(userProfile, roleId);

        if (Number(userProfile.userStatusId) === EnrollmentStatus.RegistrationComplete) {
          this.registrationCompleted = true;
        }

        // (For ProgramCoordinator the links are displayed from dashboard. So no need to show it in user profile
        this.setProgramCordinatorProfile(userProfile, roleId);
        // Get States and State of Residence
        this.getStatesOfResidence();

        if (Number(roleId) === UserRole.SolomonUser ||
          Number(roleId) === UserRole.Administrator ||
          Number(roleId) === UserRole.HelpdeskWorker ||
          Number(roleId) === UserRole.ProgramCoordinator ||
          Number(roleId) === UserRole.LocalOfficeAdmin) {
          this.disableAllFields();

          this.setFieldsHelpDeskWorker(roleId);

          this.setSolomonIdModel();

          // Get Companies
          this.getCompanies();

          // Get Status
          this.getStatus();

          // Get Attestation Templates
          this.getAttestationTemplate();

          // Get Designated Represenative users
          this.getDesignatedRepresentativeUsers();

          // Get External Case Manager users
          this.getExternalCaseManagers(selectedEcms);
          this.languageOptions = {
            availableOptions: [{ name: 'English', id: 1 }, { name: 'Spanish', id: 2 }],
            selectedOption: { id: this.userData.preferredLanguageId } // This sets the default value of the select in the ui
          };
          this.languageOptionsChanged(this.userData.preferredLanguageId);
        } else if (Number(roleId) === UserRole.Participant ||
          Number(roleId) === UserRole.Caregiver ||
          Number(roleId) === UserRole.EmployerOfRecord) {
          this.setStatusForPTCGER();
          this.languageOptionsChanged(this.userData.preferredLanguageId);
          this.setDisableOREnableFields();
        }
        AppConstants.showLoading = false;
      });
      this.isDataLoaded = true;
    } catch {
      AppConstants.showLoading = false;
      this.toaster.error(AppConstants.httpErrorMessage);
    }
  }

  public setStatusForPTCGER(){
    this.isUserOnboarding = true;
    this.isDataLoaded = true;
    this.languageOptions = {
      availableOptions: [{ name: 'English', id: 1 }, { name: 'Spanish', id: 2 }],
      selectedOption: { id: this.userData.preferredLanguageId } // This sets the default value of the select in the ui
    };
    // Get Status
    this.service.getUserStatus().toPromise().then((userStatusData) => {
      this.userStatusOptions = {
        availableOptions: userStatusData,
        selectedOption: { id: this.userData.userStatusId !== null ? this.userData.userStatusId : 0 }
      };
    });
  }

  public setProgramCordinatorProfile(userProfile,roleId){
    this.isParticipant = userProfile.roleId === UserRole.Participant && (Number(roleId) === UserRole.Administrator
          || Number(roleId) === UserRole.Participant
          || Number(roleId) === UserRole.ExternalCaseManager)
          && (Number(userProfile.userStatusId) === EnrollmentStatus.RegistrationComplete
            || Number(userProfile.userStatusId) === EnrollmentStatus.Blocked);
        this.userId = userProfile.id;
  }

  public setFieldsHelpDeskWorker(roleId) {
    if (Number(roleId) === UserRole.HelpdeskWorker) {
      this.loggedUserDisable = true;
      this.otherDisable = true;
      this.careGiverDisable = true;
      this.pcDisable = false;
    }
  }
  public setUserprofileData(userProfile, roleId) {
    this.firstName = userProfile.firstName.trim();
    this.lastName = userProfile.lastName.trim();

    if (userProfile.dob) {
      this.userData.dob = userProfile.dob.trim();
    }

    if (userProfile.middleName) {
      this.userData.middleName = this.userData.middleName.trim();
    }

    if (userProfile.suffix) {
      this.userData.suffix = this.userData.suffix.trim();
    }


    if (userProfile.landPhone) {
      this.userData.landPhone = this.userData.landPhone.trim();
    }

    if (userProfile.ssn) {
      this.userData.ssn = userProfile.ssn.trim();
    }

    if (userProfile.cellphone) {
      this.userData.cellphone = this.userData.cellphone.trim();
    }

    if (userProfile.registrationCompleteDate !== null) {
      this.registrationCompleteDate = moment(this.userData.registrationCompleteDate).format(this.dateFormat) + ' UTC';
    }

    this.created = moment(this.userData.created).format(this.dateFormat) + ' UTC';

    this.solomonId = userProfile.solomonId !== null && userProfile.solomonId !== undefined && userProfile.solomonId.length > 0;
    this.isDR = Number(userProfile.roleId) === UserRole.DesignatedRepresentative;
    this.isMCO = Number(userProfile.roleId) === UserRole.ManagedCareOrganization;
    this.isAgencyAdmin = Number(userProfile.roleId) === UserRole.AgencyAdmin;
    this.isSF = Number(userProfile.roleId) === UserRole.ServiceFacilitator;
    this.canDisableCompany = this.isDR || this.isMCO || Number(roleId) === UserRole.HelpdeskWorker || this.isAgencyAdmin || this.isSF;
    this.canDisableSolomonId = this.canDisableCompany;
  }

  public getStatesOfResidence(){
    this.service.getStates().toPromise().then((statesData) => {
      this.stateOptions = {
        availableOptions: statesData,
        selectedOption: { id: this.userData.stateId !== null ? this.userData.stateId : 0 }
      };

      this.stateOfResidenceOptions = {
      availableOptions: statesData,
      selectedOption: { id: this.userData.stateOfResidenceId !== null ? this.userData.stateOfResidenceId : 0 }
    };
      if (Number(this.stateOfResidenceOptions.selectedOption.id) === 0 ||
          Number.isNaN(Number(this.stateOfResidenceOptions.selectedOption.id))) {
          this.isEditable = true;
      }
  });
  }

  public disableAllFields(){
    switch (this.userData.roleName) {
      case StorageConstants.participantUserRole.trim():
        this.careGiverDisable = false;
        this.otherDisable = false;
        this.loggedUserDisable = false;
        this.pcDisable = false;
        break;
      case StorageConstants.careGiverUserRole.trim():
        // disable all fields except User info
        this.careGiverDisable = true;
        this.otherDisable = false;
        this.loggedUserDisable = false;
        this.pcDisable = false;
        break;
      case StorageConstants.supportBrokerUserRole.trim():
        // disable all fields except User info
        this.careGiverDisable = true;
        this.otherDisable = false;
        this.loggedUserDisable = false;
        this.pcDisable = false;
        break;
      case StorageConstants.ProgramCoordinatorRole.trim():
        // disable all fields except User info
        this.careGiverDisable = true;
        this.otherDisable = true;
        this.loggedUserDisable = false;
        this.pcDisable = false;
        break;
      case null:
        this.careGiverDisable = true;
        this.otherDisable = true;
        this.loggedUserDisable = true;
        this.pcDisable = true;
        break;
      default:
        this.careGiverDisable = true;
        this.otherDisable = true;
        this.loggedUserDisable = false;
        this.pcDisable = false;
        break;
    }
  }

  public getCompanies() {
    this.service.getCompaniesByState(this.userData.stateId).toPromise().then((companyData) => {
      this.companiesOptions = {
        availableOption: companyData,
        selectedOptn: { id: this.userData.companyId !== null ? this.userData.companyId : 0 }
      };
      this.oncompaniesOptionsChange(this.userData.companyId);
    });
  }

  public getStatus(){
    this.service.getUserStatus().toPromise().then((userStatusData) => {
      this.userStatusOptions = {
        availableOptions: userStatusData,
        selectedOption: { id: this.userData.userStatusId !== null ? this.userData.userStatusId : 0 }
      };
    });
  }

  public getAttestationTemplate(){
    this.service.getAttestationTemplates().toPromise().then((attestationData) => {
      this.attestationTemplateOptions = {
        availableOptions: attestationData,
        selectedOption: { id: this.userData.attestationId !== null ? this.userData.attestationId : 0 }
      };
    });
  }

  public getDesignatedRepresentativeUsers(){
    this.service.getUsersByRole(UserRole.DesignatedRepresentative).toPromise().then((drdata) => {
      this.drUsersOptions = {
        availableOptions: drdata,
        selectedOption: { id: this.userData.drUserId !== null ? this.userData.drUserId : 0 }
      };
    });
  }

  public setDisableOREnableFields(){
    AppConstants.showLoading = false;
    this.disableB2CEdit = false;
    this.otherDisable = true;
    this.careGiverDisable = true;
    this.loggedUserDisable = true;
    this.solomonId = true;
    this.isDataLoaded = true;
    AppConstants.showLoading = false;

    this.otherDisable = true;
    this.careGiverDisable = true;
    this.loggedUserDisable = true;
    this.solomonId = true;
  }

  public getExternalCaseManagers(selectedEcms){
    this.service.getUsersByRole(UserRole.ExternalCaseManager).toPromise().then((userByRoleData) => {
      const activeCmUsers = new Array();
      const selectedecm = new Array();
      for (let i = 0; i < userByRoleData.length; i++) {
        if (userByRoleData[i].userStatusId === EnrollmentStatus.RegistrationComplete) {
          activeCmUsers.push(userByRoleData[i]);
        }
      }
      this.caseManagerOptions = {
        availableOptions: activeCmUsers,
        selectedOption: selectedEcms !== null ? selectedEcms : null // This sets the default value of the select in the ui
      };
    });
  }

  public setSolomonIdModel(){
    if ((this.userData.roleName !== UserRole.DesignatedRepresentative[UserRole.DesignatedRepresentative]) &&
            (this.userData.roleName !== UserRole.EmployerOfRecord[UserRole.EmployerOfRecord])
            && (this.userData.roleName !== UserRole.AgencyAdmin[UserRole.AgencyAdmin])
            && (this.userData.roleName !== UserRole.ServiceFacilitator[UserRole.ServiceFacilitator])
          ) {
            this.solomonId = (this.userData.solomonId !== null) ? true : false;
            this.solomonIdModel = {id: this.userData.solomonId, name: null};
          } else {
            this.solomonId = true;
          }
  }

   public oncompaniesOptionsChange(newVal) {
    if (Number(newVal)) {
      this.companyMissing  = false;
      this.service.getProgramsByCompany(newVal).toPromise().then((programsdata) => {
        this.programsOptions = {
          availableOptions: programsdata,
          selectedOption: { id: this.userData.programId !== null ? this.userData.programId : 0 }
        };

        this.isDataLoaded = true;
        this.userData.companyId = newVal;
        AppConstants.showLoading = false;
      });
    } else if ((newVal === null || newVal === '0' || newVal === '') && this.userData !== undefined) {
      this.companyMissing  = true;
      this.userData.companyId = 0;
      this.userData.programId = 0;
      this.programsOptions = {
        availableOptions: [],
        selectedOption : { id: 0 }
      };
    }

  }

  public onstateOfResidenceOptionsChange(newVal: any) {
    if (newVal) {
      this.userData.stateOfResidenceId = newVal;
    }
  }

  public onuserStatusOptionChanged(newVal) {
    if (newVal) {
      this.userData.userStatusId = newVal;
    }
  }

  public onprogramsOptionsChanged(newVal) {
    if (newVal) {
      this.userData.programId = newVal;
    } else if (newVal === null && this.userData !== undefined) {
      this.userData.programId = 0;
    }
  }

  public attestationTemplateOptionsChanged(newVal) {
    if (newVal) {
      this.userData.attestationId = newVal;
    } else if (newVal === null && this.userData !== undefined) {
      this.userData.attestationId = 0;
    }
  }

  public caseManagerOptionsChanged(event) {
    if (event && event.length > 0) {
      this.toggleList(false);
    } else {
      this.toggleList(true);
    }
  }

  private toggleList(hideCompanyList: boolean) {
    const ta = document.getElementsByClassName('k-reset') as HTMLCollectionOf<HTMLElement>;
    if (hideCompanyList || this.careGiverDisable) {
      ta[0].style.display = 'none';
    } else {
      ta[0].style.display = 'block';
    }
  }

  public onClose(event: any) {
    event.preventDefault();
    // Close the list if the component is no longer focused
    setTimeout(() => {
      if (!this.multiselect.wrapper.nativeElement.contains(document.activeElement)) {
        this.multiselect.toggle(false);
      }
    });
  }

  // public mcoUsersOptionsChanged(newVal) {
  //   if (newVal) {
  //     this.userData.mCOUserId = newVal;
  //   } else if (newVal === null && this.userData !== undefined) {
  //     this.userData.mCOUserId = 0;
  //   }
  // }

  public DRUserOptionsChanged(newVal) {
   if (newVal) {
      this.userData.drUserId = newVal;
    } else if (newVal === null && this.userData !== undefined) {
      this.userData.drUserId = 0;
    }
  }

  public languageOptionsChanged(newVal) {
    if (newVal) {
      this.userData.preferredLanguageId = newVal;
    } else if (newVal === null && this.userData !== undefined) {
      this.userData.preferredLanguageId = 0;
    }
  }

  public updateUser() {
    if (!this.common.checkUserAccess(this.canUpdateUserProfile)) {
      return false;
    }
    const isValid = false;

    if (this.validateRequestData()) {
      this.getCaseManagerOptions();
      
      this.participant = [];
      if (!this.isUserOnboarding && (Number(this.stateOfResidenceOptions.selectedOption.id) === 0 ||
          Number.isNaN(Number(this.stateOfResidenceOptions.selectedOption.id))) &&
          !(Number(this.userData.roleId) === UserRole.Administrator ||
          Number(this.userData.roleId) === UserRole.LocalOfficeAdmin ||
          Number(this.userData.roleId) === UserRole.SolomonUser ||
          Number(this.userData.roleId) === UserRole.HelpdeskWorker)) {
        this.toaster.error(AppConstants.preferredLanguageData['notification_msg_stateOfResidenceRequired'].value);
        this.isEditable = true;
        return;
      }

      if (this.firstName !== this.userData.firstName || this.lastName !== this.userData.lastName) {
        this.userData.isNameChanged = true;
        this.userData.oldFullName = this.userData.fullName;
      }
      AppConstants.showLoading = true;
      this.updateUserData();
    }
  }

  public getCaseManagerOptions(){
    const cArr = [];
    if (this.caseManagerOptions.selectedOption !== null) {
      for (const scomp of this.caseManagerOptions.selectedOption) {
        const cForm = scomp.id;
        cArr.push(cForm);
      }
      this.userData.cmUserIds = cArr.join(',');
    }
  }

  public updateUserData(){
    this.service.updateUser(this.userData).toPromise().then((result: any) => {
      if (result.isSuccess) {
       
        this.redirectToUserOnboard(result);
      } else {
        this.isEditable = false;
        AppConstants.showLoading = false;
        this.toaster.error(result.errorMessage);
      }

      // For ProgramCoordinator the links are displayed from dashboard. So no need to show it in user profile
     this.updateProgramCoordinator();
    }, (result) => {
      AppConstants.showLoading = false;
      this.toaster.error(result.error ? result.error : AppConstants.preferredLanguageData['notification_Http_InternalError']);
    });
  }

  public updateProgramCoordinator(){
    this.isParticipant = this.userData.roleId === UserRole.Participant
    && (Number(CurrentUser.details.roleId) === UserRole.Administrator
      || Number(CurrentUser.details.roleId) === UserRole.Participant
      || Number(CurrentUser.details.roleId) === UserRole.ExternalCaseManager)
    && (Number(this.userData.userStatusId) === EnrollmentStatus.RegistrationComplete
      || Number(this.userData.userStatusId) === EnrollmentStatus.Blocked);
  }

  public redirectToUserOnboard(result){
    AppConstants.showLoading = false;
    if (!this.isUserOnboarding) {
      this.toaster.success(AppConstants.preferredLanguageData['notification_msg_UpdateProfileSuccess'].value);
    } else if (!result.isOnboardingSuccessful) {
      this.toaster.warning(AppConstants.preferredLanguageData['autoonboard_failed'].value);
    } else {
      CurrentUser.details.enrollmentStatus = 5;
      window.location.href = window.location.origin;
    }
    this.solomonId = this.userData.solomonId !== null && this.userData.solomonId.length > 0;
    this.canDisableSolomonId = this.canDisableCompany;
    this.isEditable = false;
  }
  public getSpendingSummaryReport() {
    if (!this.common.checkUserAccess(this.canGetSummaryReports)) {
      return false;
    }
    this.showReportsPopup = true;
    this.dialogType = ModelDialogLoadingType.LoadingSummaryReport;
    this.service.getSpendingSummaryReport(this.userData.solomonId).subscribe((result: any) => {
      const fileName = this.userData.solomonId.trim() + AppConstants.summaryReportExtn;
      this.common.downloadPdfFile(result, fileName);
      this.showReportsPopup = false;
      this.dialogType = ModelDialogLoadingType.None;
    }, (err) => {
      this.showReportsPopup = false;
      this.dialogType = ModelDialogLoadingType.None;
      this.toaster.error(AppConstants.preferredLanguageData['notification_Http_InternalError'].value);
    });
  }

  public getSpendingDetailReport() {
    if (!this.common.checkUserAccess(this.canGetDetailedReports)) {
      return false;
    }
    this.showReportsPopup = true;
    this.dialogType = ModelDialogLoadingType.LoadingDetailReport;
    this.service.getSpendingDetailReport(this.userData.solomonId).subscribe((result: any) => {
        const fileName = this.userData.solomonId.trim() + AppConstants.detailReportExtn;
        this.common.downloadPdfFile(result, fileName);
        this.showReportsPopup = false;
        this.dialogType = ModelDialogLoadingType.None;
    }, (err) => {
      this.showReportsPopup = false;
      this.dialogType = ModelDialogLoadingType.None;
      this.toaster.error(AppConstants.preferredLanguageData['notification_Http_InternalError'].value);
    });
}
  private IsStringNullOrEmpty(str: any) {
    if (!str) { return true; }
    if (typeof str === 'string' && str.trim().length === 0) {
      return true;
    }
    return false;
  }
  private validateRequestData() {
    const userStatusId = Number(this.userData.userStatusId);

      this.setSolomonId();

    const loggedInRoleId = CurrentUser.details.impersonateUserId ? CurrentUser.details.impersonateUserRoleId : CurrentUser.details.roleId;

    let showDialog = false;
    if (userStatusId === 0 || Number.isNaN(userStatusId)) {
      this.toaster.error(AppConstants.preferredLanguageData['notification_req_UserStatus'].value);
      showDialog = true;
    }

     showDialog = this.validateMandatoryUserProfile(loggedInRoleId) || showDialog;


    const demographicsUpdateWithoutSolomonId = this.getDemographicUpdate();

    if (demographicsUpdateWithoutSolomonId && (Number(this.userData.roleId) !== UserRole.DesignatedRepresentative)
      && (Number(this.userData.roleId) !== UserRole.ManagedCareOrganization)
      && (Number(this.userData.roleId) !== UserRole.AgencyAdmin) && (Number(this.userData.roleId) !== UserRole.ServiceFacilitator)) {
      this.toaster.error(AppConstants.preferredLanguageData['notification_solomon_req_for_grace'].value);
      showDialog = true;
    }

    if (!demographicsUpdateWithoutSolomonId) {
    
      showDialog = this.validateNotificationReqSolomon(userStatusId);

    }

    showDialog = this.validateNotificationRequest();
    

    if (showDialog) {
      this.dialogContent += '</li></ul>';
      return false;
    } else {
      this.dialogHeader = '';
      this.dialogContent = '';
      return true;
    }
  }

  public validateMandatoryUserProfile(loggedInRoleId){
    if (Number(loggedInRoleId) === UserRole.Participant ||
    Number(loggedInRoleId) === UserRole.Caregiver ||
    Number(loggedInRoleId) === UserRole.EmployerOfRecord) {
      if (this.IsStringNullOrEmpty(this.userData.firstName) ||
      this.IsStringNullOrEmpty(this.userData.lastName) ||
      this.IsStringNullOrEmpty(this.userData.ssn) ||
      (Number(this.stateOfResidenceOptions.selectedOption.id) === 0 ||
       Number.isNaN(Number(this.stateOfResidenceOptions.selectedOption.id)))
      ) {
        this.toaster.error(AppConstants.preferredLanguageData['error_mandatory_fields_userprofile'].value);
        return true;
        
       } else if (
        !this.userData.ssn.match(new RegExp('^\\d{4}$'))
       ) {
         this.toaster.error(AppConstants.preferredLanguageData['error_ssn_field_userprofile'].value);
         return true;
         
        } else {
         return true;
       }
    }
  }
  public validateNotificationReqSolomon(userStatusId) {

    if (userStatusId > EnrollmentStatus.RegistrationInitiated &&
      (Number(this.userData.roleId) !== UserRole.DesignatedRepresentative) &&
      (Number(this.userData.roleId) !== UserRole.ManagedCareOrganization) &&
      (Number(this.userData.roleId) !== UserRole.AgencyAdmin) &&
      (Number(this.userData.roleId) !== UserRole.ServiceFacilitator) &&
      (Number(this.userData.roleId) !== UserRole.HelpdeskWorker) &&
      (Number(this.userData.roleId) !== UserRole.SolomonUser) &&
      (Number(this.userData.roleId) !== UserRole.LocalOfficeAdmin) &&
      (Number(this.userData.roleId) !== UserRole.Administrator)) {
      if (this.userData.solomonId == null || (this.userData.solomonId !== null && this.userData.solomonId.length === 0)) {
        this.toaster.error(AppConstants.preferredLanguageData['notification_req_Solomon'].value);
        return true;
      }
    }

    if (this.userData.solomonId !== null && this.userData.solomonId.length > 0) {
      if (Number(this.userData.companyId) > 0 && Number(this.userData.attestationId) > 0) {
        return false;
      } else {
          return this.validateNotificationAttestation();
      }
    }
  }

  public validateNotificationAttestation() {
    if (Number(this.userData.companyId) === 0 || Number.isNaN(Number(this.userData.companyId))) {
      this.toaster.error(AppConstants.preferredLanguageData['notification_req_Company'].value);
      return true;
    }
    const roleId = Number(this.userData.roleId);
    const isAttestationTemplateRequired = (roleId === UserRole.Caregiver) || (roleId === UserRole.Participant) ||
      (roleId === UserRole.SupportBroker);
    if ((Number(this.userData.attestationId) === 0 || Number.isNaN(Number(this.userData.attestationId)))
      && isAttestationTemplateRequired) {
      this.toaster.error(AppConstants.preferredLanguageData['notification_req_Attestation'].value);
      return true;
    }
  }

  public setSolomonId(){
    if (typeof this.solomonIdModel === 'string') {
      if (this.solomonIdModel !== null && this.solomonIdModel !== undefined) {
        this.userData.solomonId = this.solomonIdModel !== this.userData.solomonId ?
                                this.solomonIdModel.trim() : this.userData.solomonId.trim();
      }
    } else {
      if (this.solomonIdModel.id !== null && this.solomonIdModel.id !== undefined) {
        this.userData.solomonId = this.solomonIdModel.id !== this.userData.solomonId ?
                                this.solomonIdModel.id.trim() : this.userData.solomonId.trim();
      }
    }
  }
  public validateNotificationRequest(){
    if (this.userData.zip !== null && Number(this.userData.zip) !== 0) {
      if (!AppConstants.regexValidateZipcode.test(this.userData.zip)) {
        this.toaster.error(AppConstants.preferredLanguageData['notification_req_zip'].value);
        return true;
      }
    }

    if (this.userData.address1 !== null && Number(this.userData.address1) !== 0) {
      return this.validateAddress();
    }

    if (this.userData.address2 !== null && Number(this.userData.address2) !== 0) {
      if (!AppConstants.regexValidateAddress.test(this.userData.address2)) {
        this.toaster.error(AppConstants.preferredLanguageData['notification_req_validate'].value + ' '
          + AppConstants.preferredLanguageData['label_Address2'].value);
        return  true;
      }
    }

    if (this.userData.city !== null && Number(this.userData.city) !== 0) {
      if (!AppConstants.regexValidateHtml.test(this.userData.city)) {
        this.toaster.error(AppConstants.preferredLanguageData['notification_req_validate'].value + ' '
          + AppConstants.preferredLanguageData['label_City'].value);
        return true;
      }
    }
  }

  public validateAddress(){
    if (!AppConstants.regexValidateAddress.test(this.userData.address1)) {
      this.toaster.error(AppConstants.preferredLanguageData['notification_req_validate'].value + ' '
        + AppConstants.preferredLanguageData['label_Address1'].value);
       return true;
    }
  }
  public getDemographicUpdate(){
    return (this.userData.solomonId === null
      || (this.userData.solomonId !== null && this.userData.solomonId.trim().length === 0)) && (
        Number(this.userData.companyId) > 0 || Number(this.userData.programId) > 0 ||
        Number(this.userData.attestationId) > 0 ||
        Number(this.userData.drUserId) > 0 || Number(this.userData.cmUserIds) > 0);
  }

  public selectedUser($item, $model, $label) {
    this.userData.solomonId = this.slId;
  }

  public setUserAccess() {
    this.canUpdateUserProfile = this.portalUserService.checkUserAccessRights(AccessRightsKeys.UpdateProfileDetails);
    this.canGetSummaryReports = this.portalUserService.checkUserAccessRights(AccessRightsKeys.GetSummaryReports);
    this.canGetDetailedReports = this.portalUserService.checkUserAccessRights(AccessRightsKeys.GetDetailedReports);
  }

  getNameBySolomonId(value) {
    this.slId = value;
    if (!this.userData.companyId) {
      return null;
    }
    return this.service.getNameBySolomonId(value, this.userData.companyId).toPromise().then((result) => {
      if (result.value && result.value !== '' && result.value !== null) {
        return [result.value];
      }
      return null;
    });
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      // switchMap allows returning an observable rather than maps array
      switchMap((searchText) => {
        if (searchText && searchText.length > 5 && this.companiesOptions.selectedOptn.id > 0) {
          this.companyMissing  = false;
          return this.service.getNameBySolomonId(searchText, this.companiesOptions.selectedOptn.id).toPromise().then((result) => {
            if (result.value && result.value !== '' && result.value !== null) {
              return result.value;
            }
            return [];
          }, () => {
            return [];
          });
        } else {
          this.companyMissing = !(this.companiesOptions.selectedOptn.id > 0);
          const slModelTyp = typeof this.solomonIdModel;
          if (slModelTyp === 'string') {
            this.userData.solomonId = this.solomonIdModel;
          } else {
            this.userData.solomonId = this.solomonIdModel.id ? this.solomonIdModel.id.toString() : this.userData.solomonId;
          }
          return [];
        }
      }
      ),
    );
  }

  resultFormatBandListValue(value: any) {
    return value.name;
  }

  inputFormatBandListValue(value: any) {
    const typ = typeof value;
    if (value.id) {
      return value.id;
    } else if (typ === 'string') {
      return value;
    }
    return null;
  }

  changeSolomonId(event) {
    this.solomonIdModel = event.item;
    this.userData.solomonId = this.solomonIdModel.id ? this.solomonIdModel.id.toString() : this.userData.solomonId;
  }

  goBackToStatus() {
    this.showSignout = false;
    this.router.navigate(['user/registrationstatus']);
  }
}

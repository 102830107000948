<kendo-dialog class="loading-dialog" *ngIf="showPopup">  
  <div class="">
    <div class="">
      <div class="">
        <div class="row">
          <div class="col-lg-3"></div>
          <div class="col-lg-6">
            <div class="modal-header" *ngIf="dialogType === '1' || dialogType === '2'">
              <button *ngIf="dialogType === '2'" type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="modalCancelClick()">×</button>
              <h1 class="modal-title">{{header | langchange}}</h1>
            </div>
            <div class="modal-header"
              *ngIf="dialogType === '3' || dialogType === '4' || dialogType === '5' || dialogType === '6'">
              <h1 *ngIf="dialogType === '6'" class="modal-title" i18n="label_loading">{{'label_loading' | langchange}}
              </h1>
              <h1 *ngIf="dialogType === '3'" class="modal-title" data-i18n="label_loading_paystub_report">{{'label_loading_paystub_report' | langchange}}</h1>
              <h1 *ngIf="dialogType === '4'" class="modal-title" i18n="label_loading_summary_report">{{'label_loading_summary_report' | langchange}}</h1>
              <h1 *ngIf="dialogType === '5'" class="modal-title" i18n="label_loading_detail_report">
                {{'label_loading_detail_report' | langchange}}
              </h1>
            </div>
            <div class="modal-body" [innerHTML]="sanitizedContent" *ngIf="dialogType === '1' || dialogType === '2'">
            </div>
            <div class="modal-body"
              *ngIf="dialogType === '3' || dialogType === '4' || dialogType === '5' || dialogType === '6'">
              <section class="container-fluid">
                <div class="margin-b-30">
                  <div class="sk-fading-circle">
                    <div class="sk-circle1 sk-circle"></div>
                    <div class="sk-circle2 sk-circle"></div>
                    <div class="sk-circle3 sk-circle"></div>
                    <div class="sk-circle4 sk-circle"></div>
                    <div class="sk-circle5 sk-circle"></div>
                    <div class="sk-circle6 sk-circle"></div>
                    <div class="sk-circle7 sk-circle"></div>
                    <div class="sk-circle8 sk-circle"></div>
                    <div class="sk-circle9 sk-circle"></div>
                    <div class="sk-circle10 sk-circle"></div>
                    <div class="sk-circle11 sk-circle"></div>
                    <div class="sk-circle12 sk-circle"></div>
                  </div>
                </div>

              </section>
            </div>
            <div class="modal-footer pull-left" *ngIf="dialogType === '1'">
              <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal" (click)="modalOkClick()"
                i18n="button_Ok">{{'button_Ok' | langchange}}</button>
            </div>
            <div class="modal-footer pull-left" *ngIf="dialogType === '2'">
              <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal" (click)="modalOkClick()"
                i18n="button_Ok">{{'button_Ok' | langchange}}</button>
              <button type="button" class="btn btn-default" data-dismiss="modal" (click)="modalCancelClick()"
                data-i18n="button_Cancel">{{'button_Cancel' | langchange}}</button>
            </div>
          </div>
          <div class="col-lg-3"></div>
        </div>
      </div>
    </div>
  </div>
 
</kendo-dialog>
import { AppConstants } from './../core/appconstants';
import { Component } from '@angular/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.scss']
})
export class ErrorPageComponent {
  appConstants = AppConstants;
}

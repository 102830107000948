import { CurrentUser } from 'src/app/core/currentuser';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { tap } from "rxjs/operators";
import { AppConstants } from '../appconstants';

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {

    constructor() {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const user = CurrentUser?.details;
        if (user) {
            const userActive = user?.isActive;
            if (!userActive && !this.isAllowedUrl(request.url)) {
                AppConstants.showLoading = false;
                return EMPTY;
           }
        }

        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    //     // intercept success reponse.
                     },
                (err: HttpErrorResponse) => {
                         // intercept error reponse.
                     }
            )
          );

    }

    isAllowedUrl(url: string): boolean {
        const allowedUrls = CurrentUser.whitelistUrls;
        const inputUrl = url.toLocaleLowerCase();
        if (allowedUrls.some((arr) => inputUrl.indexOf(arr) >= 0)) {
            return true;
        } else {
            return false;
        }
    }
}

import { CommonConstants } from '../constants/common-constants';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegularExpressions } from '../constants/regular-expressions';

export class ValidationFunctions {
    public static isFullName(value: string): boolean {
        const nameArray = value.split(' ');
        if (nameArray.length !== 2) {
            return false;
        }
        nameArray.forEach(e => {
            if (this.isNumeric(e)) {
                return false;
            }
        });
        return true;
    }
    public static isValidPersonId(value: string): boolean {
        return (value.length === 7 && this.isNumeric(value));
    }
    public static isNumeric(val: any): val is number | string {
        return !Array.isArray(val) && (val - parseFloat(val) + 1) >= 0;
    }
    public static isValidDateRange(begDate: Date, endDate: Date) {
        return (endDate >= begDate);
    }
    public static isDate(value: string): boolean {
        return Object.prototype.toString.call(value) === '[object Date]' ? true : false;
    }
    public static isEmptyObject(obj) {
        return (obj && (Object.keys(obj).length === 0));
    }
    public static matchRegularExpression(strRgx: RegExp, value: string): boolean {
        const regexp = new RegExp(strRgx);
        const result = regexp.test(String(value));
        return result;
    }
    public static isKendoDatePickerEmpty(input: string): boolean {
        return CommonConstants.kendoDatePickerValueIfEmpty.indexOf(input) > -1 ? true : false;
    }
    public static phoneNumberValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value > 0) {
            const phLength = String(control.value).trim().length;
            if (phLength > 0 && (phLength < 10 || phLength > 10)) {
                return { invalidPhone: true };
            }
        }
        return null;
    }

    public static phoneExtensionValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value > 0 && !ValidationFunctions.matchRegularExpression(/^[0-9]{0,12}?$/, control.value)) {
            const phLength = String(control.value).trim().length;
            if (phLength > 12) {
                return { invalidPhone: true };
            }
        }
        return null;
    }

    public static nationalPlanIdValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            const length = String(control.value).trim().length;
            if (length < 2 || length > 80) {
                return { invalidNationalPlanId: true };
            }
        }
        return null;
    }

    public static validateDate(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value && control.value instanceof Date) {
            if (control.value > new Date()) {
                return { invalidDate: true };
            }
            if (control.value < new Date(1900, 0, 1)) {
                return { invalidDateMin: true };
            }
        }
        return null;
    }

    public static validateSmallDate(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value && control.value instanceof Date) {
            if (control.value < CommonConstants.minSmallDate) {
                return { invalidDateMin: true };
            }
            if (control.value > CommonConstants.maxSmallDate) {
                return { invalidDateMax: true };
            }
        }
        return null;
    }

    public static validateDateExists(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value && control.value instanceof Date) {
            if (control.value > new Date(9999, 11, 31)) {
                return { invalidDateMax: true };
            }
            if (control.value < new Date(1900, 0, 1)) {
                return { invalidDateMin: true };
            }
        }
        return null;
    }

    public static validateDOB(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value && control.value instanceof Date) {
            if (new Date(control.value.toDateString()) >= new Date(new Date().toDateString())) {
                return { invalidDOB: true };
            }
        }
        return null;
    }

    public static zipcodeValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined && String(control.value).length > 0) {
            const zipLength = String(control.value).length;
            if (zipLength > 0 && !ValidationFunctions.matchRegularExpression(/^[0-9]{5}(?:-[0-9]{4})?$/, control.value)) {
                return { invalidZip: true };
            }
        }
        return null;
    }

    public static nameOnlyAlphaWithSpacesValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            const regexStr = /^[\sa-z\-A-Z][a-z\-A-Z\s]*$/;
            const retVal = regexStr.test(control.value);
            if (!retVal) {
                return { invalidName: true };
            }
        }
        return null;
    }

    public static nameAlphaWithDiacriticsValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            const regexStr = /^(?!\s+$)[A-Za-zÀ-žÂ-ẑçġö .`'-]*$/;
            const retVal = regexStr.test(control.value);
            if (!retVal) {
                return { invalidName: true };
            }
        }
        return null;
    }

    public static suffixOnlyAlphaWithSpacesValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            const regexStr = /^[\sa-z\.\-A-Z][a-z\.\-A-Z\s]*$/;
            const retVal = regexStr.test(control.value);
            if (!retVal) {
                return { invalidSuffix: true };
            }
        }
        return null;
    }

    public static required(control: UntypedFormControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined) {
            if (typeof control.value === 'string' && !control.value.trim()) {
                if (control.value.trim() !== control.value) {
                    control.setValue(control.value.trim());
                }
                return {
                    required: true
                };
            }
        } else {
            return {
                required: true
            };
        }

        return null;
    }

    public static emailValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined && String(control.value).length > 0) {
            // eslint-disable-next-line max-len
            const regexp = new RegExp('^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}$');
            const result = regexp.test(String(control.value));
            if (!result) {
                return { invalidEmail: true };
            }
        }
        return null;
    }

    public static emailValidation2(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined && String(control.value).trim().length > 0) {
            const regexp = RegularExpressions.email;
            const result = regexp.test(String(control.value));
            if (!result) {
                return { invalidEmail: true };
            }
        }
        return null;
    }

    public static ssnValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if ((control.value === null) || (control.value === undefined)) {
            return { invalidSSN: true };
        } else if (control.value !== null && control.value !== undefined) {
            const ssnLength = String(control.value).trim().length;
            if (ssnLength > 0 && ssnLength !== 9) {
                return { incompleteSSN: true };
            } else if (!ValidationFunctions.matchRegularExpression(RegularExpressions.ssn, control.value)) {
                return { invalidSSN: true };
            }
        }
        return null;
    }

    public static personSSNValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        if ((control.value === null) || (control.value === undefined)) {
            return { invalidSSN: true };
        } else if (control.value !== null && control.value !== undefined) {
            const ssnLength = String(control.value).trim().length;
            const ssn = control.value;
            if (!ssn.startsWith('XXX-XX-')) {
                if (ssnLength > 0 && ssnLength !== 9) {
                    return { incompleteSSN: true };
                } else if (!ValidationFunctions.matchRegularExpression(RegularExpressions.ssn, control.value)) {
                    return { invalidSSN: true };
                }
            }
        }
        return null;
    }

    public static ssnItinValidation(control?: AbstractControl): { [key: string]: boolean } | null {
        const ssnLength = String(control.value).length;
        if (ssnLength > 0 && ssnLength < 9) {
            return { invalidSsn: true };
        }
        if (control.value !== null && control.value !== undefined && ssnLength > 0) {
            if (!ValidationFunctions.matchRegularExpression(RegularExpressions.formattedSsn, control.value) &&
                !ValidationFunctions.matchRegularExpression(RegularExpressions.formattedItin, control.value)) {
                return { invalidSsn: true };
            }
        }
        return null;
    }

    public static OnlyAlphanumericNoSpace(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            if (!ValidationFunctions.matchRegularExpression(RegularExpressions.alphaNumericNoSpace, control.value)) {
                return { invalidText: true };
            }
        }
        return null;
    }

    public static OnlyAlphanumericWithSpace(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value) {
            if (!ValidationFunctions.matchRegularExpression(RegularExpressions.alphaNumericWithSpace, control.value)) {
                return { invalidText: true };
            }
        }
        return null;
    }

    public static ivrValidation(control?: AbstractControl): { [key: string]: string } | null {
        const ivrLength = control.value ? String(control.value).length : 0;
        if (ivrLength === 0) {
            return { required: 'IVR Pin Required' };
        } else if (ivrLength !== 4) {
            return { invalidIvrPin: 'IVR Pin must be 4 digits' };
        }
    }

    public static numberGreaterThanZero(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value != null && control.value <= 0) {
            return { negativeOrZero: true };
        } else {
            return null;
        }
    }

    public static numberGreaterThanOrEqualtoZero(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value != null && control.value < 0) {
            return { negative: true };
        } else {
            return null;
        }
    }

    public static hoursQuarters(control: AbstractControl): { [key: string]: boolean } | null {
      if (control.value % 0.25 !== 0 ) {
          return { hoursQuarters: true };
      } else {
          return null;
      }
    }

    // multiple control validations
    public static endDateAfterStartDate(group: UntypedFormGroup, startDateName: string = 'startDate', endDateName: string = 'endDate') {
        if (group) {
          const startDate: Date = group.controls[startDateName]?.value;
          const endDate: Date = group.controls[endDateName]?.value;
            if (startDate && endDate) {
                if (startDate >= endDate) {
                    group.controls[endDateName].setErrors(
                        { 'endDateLessThanOrEqualStartDate': true }); // set error for message to appear.
                    return { startDateAfterEndDate: true };
                }
            }
        } else {
            return null;
        }
    }

    public static endDateAfterOrEqualStartDate(group: UntypedFormGroup, startDateName: string = 'startDate', endDateName: string = 'endDate') {
        if (group) {
            const startDate: Date = group.controls[startDateName]?.value;
            const endDate: Date = group.controls[endDateName]?.value;
            if (startDate && endDate) {
                if (startDate > endDate) {
                    group.controls[endDateName].setErrors(
                        { 'endDateLessThanStartDate': true }); // set error for message to appear.
                    return { startDateAfterOrEqualEndDate: true };
                }
            }
        } else {
            return null;
        }
    }

    public static ficaDateCheck(group: UntypedFormGroup) {
        return ValidationFunctions.endDateAfterStartDate(group, 'ficaStartDate', 'ficaEndDate');
    }

    public static twenty147DateCheck(group: UntypedFormGroup) {
        return ValidationFunctions.endDateAfterStartDate(group, 'twenty147StartDate', 'twenty147EndDate');
    }

    public static liveInDateCheck(group: UntypedFormGroup) {
        return ValidationFunctions.endDateAfterStartDate(group, 'liveInStartDate', 'liveInEndDate');
    }

    public static relationshipEndDateAfterStartDate(group: UntypedFormGroup) {
        return ValidationFunctions.endDateAfterStartDate(group, 'relationshipStartDate', 'relationshipEndDate');
    }

    public static workWeekLimitDateCheck(group: UntypedFormGroup) {
        return ValidationFunctions.endDateAfterStartDate(group, 'limitStartDate', 'limitEndDate');
    }

    public static exclusionReasonNotSelectedCheck(group: UntypedFormGroup) {
        if (group) {
            const exclusion = group.get('exclusion')?.value;
            const exclusionReason = group.get('exclusionReason')?.value;
            if (exclusion === true && (exclusionReason === null || exclusionReason === undefined || exclusionReason === '')) {
                group.get('exclusion').setErrors({ 'exclusionReasonMissing': true });
                return { exclusionReasonNotSelected: true };
            }
        } else {
            return null;
        }
    }

    public static isValidReasonLength(control?: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== null && control.value !== undefined && String(control.value).length > 50) {
                return { invalidText: true };
        }
        return null;
    }

    public static allocatedOverServiceAvailable(group: UntypedFormGroup): any {
      if (group.controls['serviceCodeAvail']?.value < (group.controls['allocatedUnits']?.value - group.controls['allocatedUnitsOriginal']?.value)) {
          group.controls['allocatedUnits'].setErrors(
              { 'allocatedOverServiceAvailable': true });
          return { allocatedOverServiceAvailable: true };
      }
      return null;
  }

  public static allocatedOverCaregiverAvailable(group: UntypedFormGroup) {
      if (group.controls['caregiverAvail']?.value < (group.controls['allocatedUnits']?.value - group.controls['allocatedUnitsOriginal']?.value)) {
          group.controls['allocatedUnits'].setErrors(
              { 'allocatedOverCaregiverAvailable': true });
          return { allocatedOverCaregiverAvailable: true };
      }
      return null;
  }

  public static caregiverNoAvailable(group: UntypedFormGroup) {
      if (group.controls['caregiverAvail']?.value === undefined) {
          if (group.controls['allocatedUnits']) {
              group.controls['allocatedUnits'].setErrors(
                  { 'caregiverNoAvailable': true });
              return { caregiverNoAvailable: true };
          }
      }
      return null;
  }
  public static validSearchParam(value: string): boolean {
    return ValidationFunctions.matchRegularExpression(/^[a-zA-Z0-9 \-]*$/,value);
  }
}

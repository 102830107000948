import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppInjector,AppModule } from './app/app.module';
import { AppInsightsService } from './app/core/services/app-insights.service';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}
let appInsightService = null;
setTimeout(() => appInsightService = AppInjector.get(AppInsightsService));
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => appInsightService.logError(err));

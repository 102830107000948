import { PagerType } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

export class KendoGridFilterColumnNameColumnValue {
    public columnName?: string | null;
    public columnValue?: string | null;
}

export class KendoMasterGridProperties {
    public static readonly popupPageable = {buttonCount : 5};
    public static readonly pageSizeTen = 10;
    public static readonly pageNumber = 1;
    public static readonly pageSize = 5;
    public static readonly skip = 0;
    public static readonly take = 5;
    public static readonly loading = true;
    public static readonly pageSizeLarge = 20;
    public static readonly pageSizeXLarge = 50;
    public static readonly pageSizes = [5, 10, 20, 50];
    public static readonly type: PagerType = 'numeric';
    public static readonly buttonCount = 10;
    public static readonly info = true;
    public static readonly previousNext = true;
    public static getPageNumber(skip: number, take: number): number {
        return (skip / take) + 1;
    }
    public static getFilterColumnNameColumnValue(filter: CompositeFilterDescriptor): KendoGridFilterColumnNameColumnValue {
        const kendoGridFilterColumnNameColumnValue = new KendoGridFilterColumnNameColumnValue();
        filter.filters.forEach((filterItem) => {
            const typeOfCompositeFilterDescriptor = filterItem as CompositeFilterDescriptor;
            typeOfCompositeFilterDescriptor.filters.forEach((compositeFilterDescriptor) => {
                kendoGridFilterColumnNameColumnValue.columnName = compositeFilterDescriptor['field'];
                kendoGridFilterColumnNameColumnValue.columnValue = this.setFilterDateValue(compositeFilterDescriptor['value']);
            });
        });
        return kendoGridFilterColumnNameColumnValue;
    }

    private static setFilterDateValue(filterValue: any): any {
        if (filterValue && Object.prototype.toString.call(filterValue) === '[object Date]') {
            const date = new Date(filterValue);
            let ddstr = '';
            let mmstr = '';
            const dd = date.getDate();
            const mm = date.getMonth() + 1;
            const yyyy = date.getFullYear();
            if (dd < 10) {
              ddstr = '0' + dd;
            } else {
              ddstr = dd.toString();
            }
            if (mm < 10) {
              mmstr = '0' + mm;
            } else {
              mmstr = mm.toString();
            }
            filterValue = mmstr + '/' + ddstr + '/' + yyyy;
        }
        return filterValue;
      }
}

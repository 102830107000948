import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/core/appconstants';
import { AttestationStatusEnum, QualificationCategory } from 'src/app/core/userrole';
import { AttestationServiceProxy, CaregiverAttestationStatusDetailsDto, CaregiverQualifications } from 'src/app/external-user/services/v4ApiServices/v4ApiServices.services';
import { xIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styles: [
  ]
})
export class QualificationsComponent implements OnInit {

  backgroundCheckData: CaregiverQualifications[];
  trainingCertificateData: CaregiverQualifications[];
  popupWidth = 500;
  mainpopupWidth = 300;
  @Input() languageText;
  @Output() langChange = new EventEmitter();
  @Input() showCloseBtn;
  @Output() closeQualificationModal = new EventEmitter();
  @Output() fromQualifications = new EventEmitter();
  bccuNo;
  dshsNo;
  mrcNo;
  showConfirmation = false;
  isbackgroundcheck = false;
  istraining = false;
  gridLoading = true;
  @Input() solomonId;
  @Input() isImpersonateUser;
  @Input() showBackGround;
  @Input() showTraining;
  @Input() showTrainingText;
  @Input() showAttestationList;
  bgStatus = false;
  tgStatus = false;
  companyConfig;
  public xIcon = xIcon;

  constructor(private AttestationServiceApi: AttestationServiceProxy, private toaster: ToastrService) { }

  ngOnInit(): void {
    this.getQualificationsData();
    this.getQualificationStatus();
    const companyConfigValue = sessionStorage.getItem(AppConstants.companyConfigStorageKey);
    this.companyConfig = (JSON.parse(atob(companyConfigValue)));
    this.bccuNo = this.companyConfig.bccuPhoneNumber;
    this.dshsNo = this.companyConfig.dshsPhoneNumber;
    this.mrcNo = this.companyConfig.mrcPhoneNumber;
  }

  changeLanguage() {
    this.langChange.emit();
  }

  closeModal() {
    this.closeQualificationModal.emit();
  }

  getQualificationStatus() {
    this.AttestationServiceApi.getCaregiverAttestationStatusDetails(this.solomonId)
    .subscribe((res: CaregiverAttestationStatusDetailsDto) => {
      this.bgStatus = this.showBackGround &&
      res.caregiverAttestationDetails.backgroundCheckStatus === AttestationStatusEnum.Pending ? true : false;
      this.tgStatus = this.showTraining &&
      res.caregiverAttestationDetails.trainingStatus === AttestationStatusEnum.Pending ? true : false;
    }, error => {
      this.toaster.error(error);
    });
  }

  getQualificationsData() {
    this.gridLoading = true;
    const currentDate = new Date();
    this.AttestationServiceApi.getCaregiverQualificationDetails(this.solomonId).subscribe(res => {
      const actualData = res.items;
      this.backgroundCheckData = actualData.filter(el => el.category === QualificationCategory.BackgroundCheck);
      this.trainingCertificateData = actualData;
      this.gridLoading = false;
    }, error => {
      this.toaster.error(error);
    });
  }

  backgroundAttestation(option) {
    if (option) {
      AppConstants.showLoading = true;
      this.AttestationServiceApi.backgroundCheckAttestationVerify(this.solomonId).subscribe(res => {
        this.bgStatus = false;
        this.emitBack();
        AppConstants.showLoading = false;
      }, error => {
        this.toaster.error(error);
        AppConstants.showLoading = false;
      });
    } else {
      this.isbackgroundcheck = true;
      this.istraining = false;
      this.showConfirmation = true;
    }
  }

  trainingAttestation(option) {
    if (option) {
      AppConstants.showLoading = true;
      this.AttestationServiceApi.trainingAttestationVerify(this.solomonId).subscribe(res => {
        this.tgStatus = false;
        this.emitBack();
        AppConstants.showLoading = false;
      }, error => {
        this.toaster.error(error);
        AppConstants.showLoading = false;
      });
    } else {
      this.isbackgroundcheck = false;
      this.istraining = true;
      this.showConfirmation = true;
    }
  }

  rejectQualifications(option) {
    if (option) {
      this.showConfirmation = false;
      AppConstants.showLoading = true;
      if (this.isbackgroundcheck) {
        this.AttestationServiceApi.backgroundCheckAttestationReject(this.solomonId).subscribe(res => {
          this.bgStatus = false;
          this.emitBack();
          AppConstants.showLoading = false;
        }, error => {
          this.toaster.error(error);
          AppConstants.showLoading = false;
        });
      } else if (this.istraining) {
        this.AttestationServiceApi.trainingAttestationReject(this.solomonId).subscribe(res => {
          this.tgStatus = false;
          this.emitBack();
          AppConstants.showLoading = false;
        }, error => {
          this.toaster.error(error);
          AppConstants.showLoading = false;
        });
      }
    } else {
      this.showConfirmation = false;
    }
  }

  emitBack() {
    if (!this.bgStatus && !this.tgStatus) {
      this.fromQualifications.emit();
    }
  }

}

import { UserProfileComponent } from './internal-user/user-profile/user-profile.component';
import { MsalGuard } from './core/routeguards/msal.guard';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyComponent } from './empty/empty.component';
import { RegistrationStatusComponent } from './registration-status/registration-status.component';
import { SignoutComponent } from './signout/signout.component';
import { ErrorPageComponent } from './errorpage/errorpage.component';
import { B2CLoginRedirectComponent } from './b2c-login-redirect/b2cloginredirect.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

const routes: Routes = [
  { path: 'login/impersonate/:role/:personId/:companyCode/:objectId', component: LoginComponent, pathMatch: 'full', },
  { path: 'login', component: LoginComponent, pathMatch: 'full', },
  { path: 'b2credirect/:ui_locales/:login_hint', component: B2CLoginRedirectComponent, pathMatch: 'full', },
  { path: 'signout', component: SignoutComponent, pathMatch: 'full' },
  { path: 'user/registrationstatus', component: RegistrationStatusComponent, pathMatch: 'full' },
  { path: 'user/landingpage', component: LandingPageComponent, pathMatch: 'full' },
  { path: 'user/editdetails/:email', component: UserProfileComponent, pathMatch: 'full' },
  { path: 'id_token', redirectTo: '', component: EmptyComponent, pathMatch: 'full' },
  { path: 'code', redirectTo: '', component: EmptyComponent, pathMatch: 'full' },
  { path: 'access_token', redirectTo: '', component: EmptyComponent, pathMatch: 'full' },
  { path: 'state', redirectTo: '', component: EmptyComponent, pathMatch: 'full' },
  { path: 'error', component: ErrorPageComponent, pathMatch: 'full' },
  {path: 'impersonate/:role/:personId/:companyCode/:objectId', component: EmptyComponent, pathMatch: 'full'},
  { path: '', component: EmptyComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: '', loadChildren: () => import('./external-user/external-user.module').then(m => m.ExternalUserModule)},
  { path: '', loadChildren: () => import('./internal-user/internal-user.module').then(m => m.InternalUserModule)},
  { path: '', loadChildren: () => import('./common-pages/common-pages.module').then(m => m.CommonPagesModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<header class="head-container">
  <section class="container-fluid">
    <div class="row">
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
      <div class="head-container-logo">
        <img alt="CDMS Logo" src="./../../assets/images/logo.svg" width="181" />
      </div>
    </div>
  </div>
  </section>
</header>






import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styles: [
  ]
})
export class ConfirmModalComponent {

  popupWidth = 500;
  @Output() modalBtnClick = new EventEmitter();
  constructor() { }

  confirmClose(option) {
    this.modalBtnClick.emit(option);
  }

}

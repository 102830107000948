<kendo-dialog class="custom-dialog lg custom-dialog-darkOverlay" width="{{mainpopupWidth}}" height="auto">
    <section class="cdms-persona">
        <div class="attestation-header">
            <h1 data-i18n="label_user_profile">
                {{'label_Qualifications'|langchange}}
            </h1>
            <button class="dialog-button-close" (click)="closeModal()" *ngIf="showCloseBtn">
                <kendo-svg-icon [icon]="xIcon"></kendo-svg-icon></button>
                <a href="javascript:void(0)" id="language-selector" *ngIf="!showCloseBtn"
                    (click)="changeLanguage()">{{languageText}}</a>
        </div>
        <div class="h-line margin-b-30"></div>
    </section>
    <div class="modal-cont">
        <div class="row">
            <div class="col-md-12" *ngIf="showAttestationList">
                <ul class="attestation-list padding-l-18">
                    <li><strong>{{'label_qualifications_01' | langchange}}</strong></li>
                    <li>{{'label_qualifications_02' | langchange}}</li>
                    <li>{{'label_qualifications_03' | langchange}}</li>
                    <li>{{'label_qualifications_04' | langchange}}</li>
                    <li><strong>{{'label_qualifications_05' | langchange}}</strong></li>
                </ul>
            </div>
            <div class="col-md-6" [ngClass]="{'col-md-12':!showTraining}" *ngIf="showBackGround">
                <h2 class="padding-b-10 text-bold" data-i18n="label_header_BasicInfo">{{'label_background_check'|langchange}}</h2>
                <div class="margin-b-2 min-h-qualifications">
                    <div class="margin-b-2">
                        {{'label_background_check_text_01' | langchange}}
                    </div>
                    <ul class="attestation-list margin-b-5">
                        <li>{{'label_background_check_text_02' | langchange}}</li>
                        <li>{{'label_background_check_text_03' | langchange}}</li>
                        <li>{{'label_background_check_text_04' | langchange}}
                            <ul>
                                <li>{{'label_background_check_text_05' | langchange}}  <a href="mailto:{{'label_BCCU_Email' | langchange}}">{{'label_BCCU_Email' | langchange}}</a> {{'label_or_lowercase' | langchange}} <a href="tel:{{'label_BCCU_Tel' | langchange}}">{{'label_BCCU_Tel' | langchange}}</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <app-qualifications-grid [isloading]="gridLoading" [gridData]="backgroundCheckData">
                </app-qualifications-grid>
                <div class="margin-b-2" [ngClass]="{'min-h-btns':bgStatus || tgStatus}">
                    <div class="pull-right" *ngIf="bgStatus">
                        <button [disabled]="isImpersonateUser" type="button" class="btn btn-secondary btn-lg" (click)="backgroundAttestation(false)"
                            i18n="button_Reject">{{'button_Reject' | langchange}}</button>
                        <button [disabled]="isImpersonateUser" type="button" class="btn btn-primary btn-lg" (click)="backgroundAttestation(true)"
                            i18n="button_Approve">{{'button_Approve' | langchange}}</button>
                    </div>
                </div>
            </div>
            <div class="col-md-6" [ngClass]="{'col-md-12':!showBackGround}" *ngIf="showTraining">
                <div class="margin-b-2 min-h-qualifications" *ngIf="showTrainingText">
                    <div class="margin-b-2">
                        {{'label_training_certificate_text_01' | langchange}}
                    </div>
                    <ul class="attestation-list margin-b-5">
                        <li>{{'label_training_certificate_text_02' | langchange}}</li>
                        <li>{{'label_training_certificate_text_03' | langchange}}</li>
                        <li>{{'label_training_certificate_text_04' | langchange}}
                            <ul>
                                <li>{{'label_training_certificate_text_04_01' | langchange}}<br><a href="mailto:{{'label_MRC_Email' | langchange}}">{{'label_MRC_Email' | langchange}}</a> {{'label_or_lowercase' | langchange}} <a href="tel:{{'label_MRC_Tel' | langchange}}">{{'label_MRC_Tel' | langchange}}</a></li>
                                <li>{{'label_training_certificate_text_04_02' | langchange}} <a href="tel:{{'label_DOH_Tel' | langchange}}">{{'label_DOH_Tel' | langchange}}</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <app-qualifications-grid [isloading]="gridLoading" [gridData]="trainingCertificateData">
                </app-qualifications-grid>
                <div class="margin-b-2" [ngClass]="{'min-h-btns':bgStatus || tgStatus}">
                    <div class="pull-right" *ngIf="tgStatus">
                        <button [disabled]="isImpersonateUser" type="button" class="btn btn-secondary btn-lg" (click)="trainingAttestation(false)"
                            i18n="button_Reject">{{'button_Reject' | langchange}}</button>
                        <button [disabled]="isImpersonateUser" type="button" class="btn btn-primary btn-lg" (click)="trainingAttestation(true)"
                            i18n="button_Approve">{{'button_Approve' | langchange}}</button>
                    </div>
                </div>
            </div>
        </div>
</div>
</kendo-dialog>

<kendo-dialog class="custom-dialog" *ngIf="showConfirmation" width="{{popupWidth}}" height="auto">
    <div class="row">
        <div class="col-lg-12">
                <p class="model-text" *ngIf="isbackgroundcheck">
                    {{'label_are_you_sure_bc_reject' | langchange}}
                </p>
                <p class="model-text" *ngIf="istraining">
                    {{'label_are_you_sure_tc_reject' | langchange}}
                </p>
            <div class="modal-body h-auto"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-lg" (click)="rejectQualifications(false)"
                    i18n="button_Ok">{{'button_no' | langchange}}</button>
                <button type="button" class="btn btn-primary btn-lg" (click)="rejectQualifications(true)"
                    i18n="button_Ok">{{'button_yes' | langchange}}</button>
            </div>
        </div>
    </div>
</kendo-dialog>
<div class="table-responsive cdms-table cdms-popup-grid">
    <kendo-grid [data]="gridData" [kendoGridBinding]="gridData" [pageSize]="pageSize" [skip]="skip" [sort]="sort"
        [filter]="filter" [sortable]="true" [pageable]="true" filterable="false" (sortChange)="sortChange($event)"
        (filterChange)="filterChange($event)" (pageChange)="pageChange($event)" [loading]="loading" [height]="270"
        class="outstandingGrid cust-kendo-grid">
        <kendo-grid-column field="qualificationType" [width]="200">
            <ng-template kendoGridHeaderTemplate let-column>
                <span title="{{'label_qualification' | langchange}}">
                    {{'label_qualification' | langchange}}</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span title=" {{dataItem.qualificationType}}">
                    {{dataItem.qualificationType}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="description" [width]="200">
            <ng-template kendoGridHeaderTemplate let-column>
                <span title="{{'label_description' | langchange}}">
                    {{'label_description' | langchange}}</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span title=" {{dataItem.description}}">
                    {{dataItem.description}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="category" [width]="200">
            <ng-template kendoGridHeaderTemplate let-column>
                <span title="{{'label_category' | langchange}}">
                    {{'label_category' | langchange}}</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span title=" {{dataItem.category}}">
                    {{dataItem.category}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="expires" [width]="120">
            <ng-template kendoGridHeaderTemplate let-column>
                <span title="{{'label_expires' | langchange}}">
                    {{'label_expires' | langchange}}</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span title="{{dataItem.expires ? 'Y':'N'}}">{{dataItem.expires ? 'Y':'N'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="expirationDate" [width]="120">
            <ng-template kendoGridHeaderTemplate let-column>
                <span title="{{'label_expiration_date' | langchange}}">
                    {{'label_expiration_date' | langchange}}</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span title="{{dataItem.expirationDate | date: 'MM/dd/yyyy'}}">{{dataItem.expirationDate |
                    date:
                    'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
            <div class="watermark-top80">{{'label_no_data_avaialble' | langchange}}</div>
        </ng-template>
        <ng-template kendoPagerTemplate>
            <div class="pagerStyle">
                <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
            </div>
        </ng-template>
    </kendo-grid>
</div>

import { MsalCustomService } from '../core/services/msal.service';
import { Component, OnInit } from '@angular/core';
import { StorageConstants } from '../core/storageconstants';

@Component({
  selector: 'app-b2c-redirect',
  templateUrl: './b2cloginredirect.component.html',
  styleUrls: ['./b2cloginredirect.component.scss']
})

export class B2CLoginRedirectComponent implements OnInit {
  constructor(private msalService: MsalCustomService) {
  }

  ngOnInit() {
    this.signInExternalUser();
  }

  signInExternalUser() {
    sessionStorage.setItem(StorageConstants.userType, StorageConstants.externalUserValue);
    this.msalService.signInExternalUser();
  }
}

import { AppConstants } from './appconstants';
export class ErrorExpressionConstants {
    public static get unAuthorize401ErrorMessage(): string {
        return AppConstants.preferredLanguageData['access_required_message'].value;
    }
    public static get kendoGridFilterOnlybyOneColumnErrorMessage(): string {
        return AppConstants.preferredLanguageData['please_select_one_column'].value;
    }
    public static get evvAddressRequired(): string {
      return 'address is required';
    }

    public static get AdjustmentReasonRequired(): string {
      return 'adjustment reason is required';
    }
    public static get inOutRounded(): string {
      return 'Shift times must be rounded to the nearest quarter hour';
    }

    public static get trainingQualificationVerificationErrorMessage(): string {
      return AppConstants.preferredLanguageData['must_have_training_qualification'].value;
    }

    public static get backgroundCheckQualificationVerificationErrorMessage(): string {
      return AppConstants.preferredLanguageData['must_have_bgcheck_qualification'].value;
    }

    public static get requestRelationshipDuplicateFound(): string {
      return AppConstants.preferredLanguageData['request_relationship_duplicate_found'].value;
    }

    public static get requestRelationshipMaxLimit(): string {
      return AppConstants.preferredLanguageData['request_relationship_max_limit'].value;
    }
}

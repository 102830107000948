import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class SuccessLandingPageService {
  private _show: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
  ) {}

  public setShow(status: boolean) {
    this._show.next(status);
  }

  public getShow(): Observable<boolean> {
    return this._show;
  }

}

<kendo-dialog class="custom-dialog lg custom-dialog-darkOverlay" width="{{mainpopupWidth}}" height="auto">
    <div>
        <div class="attestation-header">
            <h1 class="mr-5">
                {{'label_fica_title' | langchange}}
            </h1>
                <a href="javascript:void(0)" id="language-selector"
                    (click)="changeLanguage()">{{languageText}}</a>
        </div>
        <div class="h-line"></div>
    </div>
    <div class="modal-cont">
        <form name="ficaAttestationForm" (ngSubmit)="buildRequest()" [formGroup]="ficaForm">
            <div class="attestation-form" *ngIf="isCaregiverFicaEnabled">
                <div class="attestation-form-subtitle attestation-form-user-details margin-b-15">
                    <span>
                        <b>{{'label_client' | langchange}}: </b>
                    </span>
                    <span>
                        <b>{{CaregiverParticipantDetails.participantFirstName + ' ' + CaregiverParticipantDetails.participantLastName}}</b>
                    </span>
                </div>
                <ng-container *ngFor='let detail of attestationDetails; let option = index'>
                    <div *ngIf="detail.componentType === 'Title' || detail.componentType === 'SubTitle'">
                        <div [class]="detail.componentType === 'Title' ? 'attestation-form-title':'attestation-form-subtitle'">
                            {{detail.componentText}}
                        </div>
                    </div>
                    <div *ngIf="detail.componentType === 'Header'" class="margin-b-10">
                        <span>{{detail.componentText}}</span>
                    </div>
                    <ng-container *ngIf="detail.componentType === 'LabelWithLink'">
                        <span>{{detail.componentText}}</span>
                    </ng-container>
                    <ng-container *ngIf="detail.componentType === 'HyperLink'" class="margin-t-10">
                        <a href="{{detail.componentText}}" target="_blank" rel="noopener noreferrer" class="attestation-form-hyperlink">{{detail.componentText}}</a>
                    </ng-container>
                    <div *ngIf="detail.componentType === 'Question' && detail.optionType === 'Single'">
                        <div class="att-question" [attr.data-attr-qid]="detail.componentDetailId"
                        [class]="detail.parentComponentId !== null ? 'att-sub-question':''"  [class.disabled]="detail.parentComponentId === 38 && !ShowQuestion42">
                            <div class="margin-t-10 fw-bold">
                                {{detail.componentText}}
                            </div>
                            <div class="text-danger font-weight-bold" *ngIf="ficaFormsubmitted && ficaFormControls.controls[detail.componentId].errors?.required">
                                {{'label_this_is_required' | langchange}}</div>
                            <div class="att-answer" [class.disabled-ctrl]="isImpersonateUser">
                                <div class="margin-t-10">
                                    <div *ngFor="let answer of detail.questionnaireOptionDetails">
                                        <div class="att-answer-block clearfix">
                                            <input required [attr.name]="detail.componentId" [attr.formControlName]="detail.componentId" class="att-answer-opt" [value]="answer.optionId" type="radio"  [disabled]="isImpersonateUser"
                                            [attr.data-attr-cid]="answer.componentId" [attr.data-attr-oid]="answer.optionId" [attr.data-attr-odid]="answer.optionDetailId" (change)="valueChange($event,detail.componentId)">
                                            <label class="att-answer-val">
                                                {{answer.optionText}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="detail.componentType === 'Question' && detail.optionType === 'Multi'">
                        <div class="att-question" [attr.data-attr-qid]="detail.componentDetailId"
                        [class]="detail.parentComponentId !== null ? 'att-sub-question':''"  [class.disabled]="detail.parentComponentId === 41 && !ShowQuestion45">
                            <div class="margin-t-10 fw-bold">
                                {{detail.componentText}}
                            </div>
                            <div class="text-danger font-weight-bold" *ngIf="ficaFormsubmitted && ficaFormControls.controls[detail.componentId].errors?.required">
                                {{'label_this_is_required' | langchange}}</div>
                            <div class="att-multiselect-answer" [class.disabled-ctrl]="isImpersonateUser">
                                <div class="margin-t-10">
                                    <div *ngFor="let answer of detail.questionnaireOptionDetails">
                                        <div class="att-answer-block clearfix">
                                            <input required [attr.name]="'q'+detail.componentId+'o'+answer.optionId" [attr.formControlName]="detail.componentId" class="att-answer-opt att-multiselect-answer-opt  k-checkbox" [value]="answer.optionId" type="checkbox"  [disabled]="isImpersonateUser"
                                            [attr.data-attr-cid]="answer.componentId" [attr.data-attr-oid]="answer.optionId" [attr.data-attr-odid]="answer.optionDetailId" (change)="valueChange($event,detail.componentId)">
                                            <label class="att-answer-val">
                                                {{answer.optionText}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="detail.componentType === 'Question' && detail.optionType === 'Affirmation'">
                        <div class="att-question"  [attr.data-attr-qid]="detail.componentDetailId">
                            <div class="att-answer-block clearfix">
                                <div *ngFor="let answer of detail.questionnaireOptionDetails">
                                        <input type="checkbox" required [attr.name]="detail.componentId" [disabled]="isImpersonateUser"  *ngIf="answer.optionId === 47"
                                        class="k-single-select att-answer-opt k-checkbox"
                                        data-attr-visible="true" [attr.data-attr-cid]="answer.componentId" [attr.data-attr-oid]="answer.optionId" [attr.data-attr-odid]="answer.optionDetailId" (change)="valueChange($event,detail.componentId)">

                                        <input type="checkbox" class="k-single-select k-checkbox d-none" data-attr-visible="false"  *ngIf="answer.optionId === 48"
                                        [attr.data-attr-cid]="answer.componentId" [attr.data-attr-oid]="answer.optionId" [attr.data-attr-odid]="answer.optionDetailId">

                                </div>
                                <label class="k-checkbox-label">
                                    {{detail.componentText}}
                                </label>
                            </div>
                            <div class="text-danger font-weight-bold" *ngIf="ficaFormsubmitted && ficaFormControls.controls[detail.componentId].errors?.required">
                                {{'label_this_is_required' | langchange}}</div>
                        </div>
                    </div>
                </ng-container>
                <div class="mt-5">
                    <button [disabled]="isImpersonateUser" type="submit" class="btn btn-primary btn-lg" i18n="button_Ok">{{'button_Continue' |
                        langchange}}</button>
                </div>


            </div>

        </form>
    </div>
</kendo-dialog>


<app-confirm-modal *ngIf="showConfirmModal" (modalBtnClick)="closeModal($event)"></app-confirm-modal>

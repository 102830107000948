import { PortalUserService } from './../core/services/portalUser.service';
import { Component, OnInit } from '@angular/core';
import { StorageConstants } from '../core/storageconstants';
import { MsalCustomService } from '../core/services/msal.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiURLConstants } from '../../app/core/apiurlconstants';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit {


  constructor(private portalUserService: PortalUserService, private route: Router, private msalCustomService: MsalCustomService,
    private httpService: HttpClient) {

  }

  async ngOnInit() {
    const href = this.route.url;
    const isImpersonateRequest = href.indexOf('impersonate');
    if (isImpersonateRequest > 0) {
      sessionStorage.setItem(StorageConstants.ImpersonateSession, href);
      sessionStorage.setItem(StorageConstants.userType, StorageConstants.internalUserValue);

      const impersonateSessionData = {
        data: sessionStorage.getItem(StorageConstants.ImpersonateSession),
        toBeSaved: true
      };
      
      // To Save the impersonate data in rediscache 
      if (impersonateSessionData.data !== null && impersonateSessionData.data !== undefined && impersonateSessionData.data !== '') {
        const isImpersonateSessionDetailsSet = sessionStorage.getItem(StorageConstants.IsImpersonateSessionDetailsSet);
        if (isImpersonateSessionDetailsSet == 'false' || isImpersonateSessionDetailsSet == null || isImpersonateSessionDetailsSet == '' || isImpersonateSessionDetailsSet == undefined) {
          this.httpService.post(ApiURLConstants.SetImpersonateSessionDetailsUrl, impersonateSessionData).subscribe(
            () => {
              sessionStorage.setItem(StorageConstants.IsImpersonateSessionDetailsSet, 'true');
              this.msalCustomService.signInInternalUser();
            }
          );
        }    
        else{
          sessionStorage.setItem(StorageConstants.IsImpersonateSessionDetailsSet, 'false');
        }   
      } else {
        this.msalCustomService.signInInternalUser();
      }
    }
    else {
      await this.portalUserService.InitiateUserSession(true);
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-workday-popup',
  templateUrl: './workday-popup.component.html',
  styles: [
  ]
})
export class WorkdayPopupComponent implements OnInit {

  popupWidth = 800;
  @Input() languageText;
  @Output() langChange = new EventEmitter();
  @Input() isImpersonateUser;
  workdayURL: string;
  constructor() { }

  ngOnInit(): void {
    this.workdayURL = environment.workdayURL;
  }

  public toWorkdayURL() {
    window.open(this.workdayURL, '_blank');
  }

  changeLanguage() {
    this.langChange.emit();
  }

}

import { ApiURLConstants } from './core/apiurlconstants';
import { AppConstants, EnrollmentStatus } from './core/appconstants';
import { PortalUserService } from './core/services/portalUser.service';
import { CurrentUser } from './core/currentuser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RoutePermissions } from './core/models/routepermissions';
import { UserIdleService } from 'angular-user-idle';
import { LanguageChangeService } from './core/services/language-change.service';
import { StorageConstants } from './core/storageconstants';
import { CommonFunctionsService } from './core/services/common-functions.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { AppInsightsService } from './core/services/app-insights.service';
import { MsalService, MsalBroadcastService, MsalCustomNavigationClient } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Location } from "@angular/common";
import { FeatureStateService } from './core/services/featureStateService';
import { SetLastV4LoginOnRequest, UserManagementServiceProxy } from './external-user/services/v4ApiServices/v4ApiServices.services';
import { UserRole } from './core/userrole';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private pingSubscription: Subscription;
  copyrightYear = '2016';
  title = 'ConsumerDirect';
  loggedInUser = CurrentUser;
  appConstants = AppConstants;
  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router,
              private http: HttpClient,
              private portalUserService: PortalUserService,
              private userIdle: UserIdleService,
              private languageChangeService: LanguageChangeService,
              public appInsightsService: AppInsightsService,
              public common: CommonFunctionsService,
              private msalBroadcastService: MsalBroadcastService,
              private msalService: MsalService,
              private location: Location,
              private featureStateService: FeatureStateService,
              private userManagementServiceProxy: UserManagementServiceProxy) {
    this.copyrightYear = new Date().getFullYear().toString();
    AppConstants.headerLoading = true;
    const customNavigationClient = new MsalCustomNavigationClient(msalService, this.router, this.location);
    this.msalService.instance.setNavigationClient(customNavigationClient);
  }

  ngOnInit(): void {

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    ).subscribe(() => {
      this.checkAndSetActiveAccount();
    })

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
      takeUntil(this._destroying$)
    )
    .subscribe(
      (result: EventMessage) => {
        // Handle the user hitting cancel on resetting their password
        if (result.error && result.error.message.indexOf('AADB2C90091') > -1) {
          this.msalService.logout();
          window.sessionStorage.clear();
          location.reload();
        };
      },
    );

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const routePermissions: RoutePermissions = this.portalUserService.getRouteData();
      if (!this.portalUserService.checkUserUrlAccessRights(routePermissions)) {
        this.appInsightsService.trackTrace('no access for : ' + JSON.stringify(routePermissions));
      }
    });

    const selectedlanguage = sessionStorage.getItem(StorageConstants.selectedlanguage);

    if (selectedlanguage) {
      this.languageChangeService.use(selectedlanguage);
    } else {
      this.languageChangeService.use(AppConstants.defaultLanguage);
    }
    this.configureIdleTimeOut();
    this.appInsightsService.logPageView(document.title, this.router.url, { url: document.URL });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  checkAndSetActiveAccount(){
    let activeAccount = this.msalService.instance.getActiveAccount();
    let allAccounts = this.msalService.instance.getAllAccounts();
    if (!activeAccount && allAccounts.length > 0) {
      let accounts = allAccounts;
      activeAccount = accounts[0];
    }
    if(activeAccount !== null && activeAccount !== undefined){
      if(activeAccount.idTokenClaims?.newUser){
        sessionStorage.setItem(StorageConstants.msalIdToken, JSON.stringify(activeAccount.idTokenClaims));
      }
      this.msalService.instance.setActiveAccount(activeAccount);
      const usertype = sessionStorage.getItem(StorageConstants.userType);
      this.common.setUserType(usertype, activeAccount.idTokenClaims);
      this.initiateUser();
    } else {
      this.appInsightsService.trackTrace("Login User Flow - No active account found");
    }
  }

  async initiateUser() {
    try {
      // get the user information
      if (!sessionStorage.getItem(StorageConstants.userLoginStatus)) {
        AppConstants.showLoading = true;
        await this.portalUserService.InitiateUserSession(true);
        AppConstants.showLoading = false;
      } else {
        AppConstants.showLoading = true;
        await this.portalUserService.InitiateUserSession();
        AppConstants.showLoading = false;
      }
      // if no user information go to registration screen
      if (CurrentUser.details === undefined || CurrentUser.details === null) {
        this.common.registrationstatus();
        return;
      }
      // if the user doesn't have a solomon id (and isn't an MCO) go to registration screen
      if ((CurrentUser.details.solomonId === undefined || CurrentUser.details.solomonId === null)
        && CurrentUser.details?.roleId !== UserRole.ManagedCareOrganization) {
          this.common.registrationstatus();
          return;
      }
      // if not registered go to registration screen
      if (CurrentUser.details.enrollmentStatus !== EnrollmentStatus.RegistrationComplete) {
        this.common.registrationstatus();
        return;
      }
      // if ssn validation role and ssn not valid, go to ssn validation screen
      if (this.featureStateService.isSSNValidation()
        && CurrentUser.details
        && CurrentUser.details.isSsnValidated === false) {
          const companyConfigValue = sessionStorage.getItem(AppConstants.companyConfigStorageKey);
          const companyConfig = companyConfigValue ? (JSON.parse(atob(companyConfigValue))) : undefined;
          if (companyConfig && companyConfig?.landingPageRoles.find(x => x.split(" ").join("").toLowerCase() === CurrentUser.details?.roleName?.toLowerCase())) {
            this.router.navigate(['user/landingpage']);
            return;
          }
      }
      this.updateLogin();
    }
    catch(err) {
        this.appInsightsService.trackTrace("Error while initiating user session "+err);
    }
  }

private updateLogin() {
  // update last log in
  if (CurrentUser.details.impersonateUserId === 0
    && CurrentUser.details.enrollmentStatus === EnrollmentStatus.RegistrationComplete) {
      if (CurrentUser.details.solomonId && CurrentUser.details.companyCode) {
        const req = new SetLastV4LoginOnRequest();
        req.personId = String(CurrentUser.details.solomonId);
        req.companyCode = CurrentUser.details.companyCode;
        this.userManagementServiceProxy.setLastV4LoginOn(req).subscribe();
      }
  }
  // go to the dashboard if all checks pass
  let url = window.location.hash.replace('#', '');

  // check if the url is a login path or an empty path. If true, then navigate it to the user's default route.
  if(url.includes(AppConstants.loginPath) || url === AppConstants.emptyPath) {
    url = CurrentUser.details.userAccessRight.routeUrl;
  }
  this.router.navigateByUrl(url);
  AppConstants.showLoading = false;
}

  configureIdleTimeOut(): void {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe();
    this.userIdle.onTimeout().subscribe(() => {
      AppConstants.sessionExpires = true;
      this.restart();
    });

    this.pingSubscription = this.userIdle.ping$
      .subscribe(() => {
        this.updateUserSession();
      });
  }

  updateUserSession() {
    return this.http.get<any>(ApiURLConstants.updateUserSession, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
    this.pingSubscription.unsubscribe();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
}

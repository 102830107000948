import { CommonFunctionsService } from 'src/app/core/services/common-functions.service';
import { StorageConstants } from '../storageconstants';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../appconstants';
import { AppInsightsService } from './app-insights.service';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { aadMsalConfig, b2cMsalConfig, getAuthorityUrlForExternalUser, getAuthorityUrlForInternalUser } from './msal.config';
import { defer, from, switchMap } from 'rxjs';

@Injectable()
export class MsalCustomService {
    public pcaInstance: PublicClientApplication;
    public b2CExternalSignIn: PublicClientApplication;
    public b2cSignUp: PublicClientApplication;
    public b2CInternalSignIn: PublicClientApplication;

    constructor(public common: CommonFunctionsService, public appInsightsService: AppInsightsService,
        private msalService: MsalService) {
        this.b2CExternalSignIn = new PublicClientApplication(b2cMsalConfig);
        this.b2CInternalSignIn = new PublicClientApplication(aadMsalConfig)
    }

    get authenticated() {
        const loggedInUser = this.msalService.instance.getAllAccounts().length;
        return loggedInUser > 0;
    }

    public getLanguage() {
        const langselectHtML = (document.getElementById('langselect') as HTMLInputElement);
        let langselect = 'ui_locales=en';
        if (langselectHtML) {
            langselect = langselectHtML.value;
        }
        return langselect
    }

    public signInExternalUser() {
        // Assigning login details for External user - B2C Login 
        // This will be used as parameters for External user flow at MSAL LoginRedirect
        this.pcaInstance = this.b2CExternalSignIn;
        sessionStorage.setItem(StorageConstants.userType, StorageConstants.externalUserValue);
        this.loginRedirect();
    }


    public signInInternalUser() {
        // Assigning login details for internal user - AD Login (Impersonation flow)
        // This will be used as parameters for Impersonation flow at MSAL LoginRedirect
        this.pcaInstance = this.b2CInternalSignIn;
        sessionStorage.setItem(StorageConstants.userType, StorageConstants.internalUserValue);
        //Checking if any active session exists for an internal user/external user
        if (sessionStorage.getItem(StorageConstants.sessionId)) {
            //Clearing session before impersonation as we have different session generated for internal/external user flows
            sessionStorage.removeItem(StorageConstants.sessionId);
        }
        //Checking if any internal user/external user is logged in
        if (sessionStorage.getItem(StorageConstants.userLoginStatus)) {
            //Clearing user login before impersonation
            sessionStorage.removeItem(StorageConstants.userLoginStatus);
        }
        this.loginRedirect();
    }


    public logoutRedirect() {
        return this.initializeMsal().pipe(
            switchMap(() => {
                const userType = sessionStorage.getItem(StorageConstants.userType);
                const isInternalUser = userType === StorageConstants.internalUserValue;
                const authoritypolicyUrl = isInternalUser ? getAuthorityUrlForInternalUser() : getAuthorityUrlForExternalUser();
                return this.msalService.logoutRedirect({
                    authority: authoritypolicyUrl,
                    postLogoutRedirectUri: environment.postLogoutRedirectUrl
                });
            })
        );
    }

    public register() {
        this.initializeMsal().subscribe(() => {
            const authoritypolicyUrl = 'https://' + environment.authority + '/' + environment.TenentIdB2C + '/' + environment.signUpPolicy;
            this.checkInteractionStatusSession();
            this.msalService.loginRedirect({
                authority: authoritypolicyUrl,
                scopes: [environment.graphScopes[0], environment.apiScope]
            });
            AppConstants.showLoading = false;
        });
    }

    public checkInteractionStatusSession() {
        if (document.cookie.indexOf('msal.interaction.status') > -1) {
            document.cookie = 'msal.interaction.status=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    private loginRedirect() {
        this.initializePcaInstance().subscribe(() => {
            const langselect = this.getLanguage()
            this.checkInteractionStatusSession();
            this.pcaInstance.loginRedirect({
                scopes: [environment.graphScopes[0], environment.apiScope],
                extraQueryParameters: { ui_locales: langselect },
            });
            this.appInsightsService.trackTrace('Login successful', null, 0);
        });
    }

    private initializeMsal() {
        return defer(() => from(this.msalService.instance.initialize()));
    }

    private initializePcaInstance() {
        return defer(() => from(this.pcaInstance.initialize()));
    }
}
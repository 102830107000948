<kendo-dialog class="custom-dialog" width="{{popupWidth}}" height="auto">
    <div class="row">
        <div class="col-lg-12">
                <p class="model-text">
                    {{'label_are_you_sure' | langchange}}
                </p>
            <div class="modal-body h-auto"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-lg" (click)="confirmClose(false)"
                i18n="button_Ok">{{'button_no' | langchange}}</button>
                    <button type="button" class="btn btn-primary btn-lg" (click)="confirmClose(true)"
                     i18n="button_Ok">{{'button_yes' | langchange}}</button>
            </div>
        </div>
    </div>
</kendo-dialog>
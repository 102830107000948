// User Role Enum
export enum UserRole {
    Administrator = 1,
    Participant = 2,
    Caregiver = 3,
    DesignatedRepresentative = 4,
    ExternalCaseManager = 5,
    SupportBroker = 6,
    ProgramCoordinator = 7,
    LocalOfficeAdmin = 8,
    SolomonUser = 9,
    HelpdeskWorker = 10,
    ManagedCareOrganization = 11,
    EmployerOfRecord = 12,
    ExternalCaseManagerSuperVisor = 13,
    AgencyAdmin = 14,
    ServiceFacilitator = 15,
    CaregiverProspect = 16,
}

// EnrollmentStatus
export enum EnrollmentStatus {
    Blocked = 1,
    RegistrationInitiated = 2,
    PacketCreated = 3,
    PacketReceived = 4,
    RegistrationComplete = 5
}

// AttestationStatus
export enum AttestationStatusEnum {
    Transition = 1,
    Pending = 2,
    Verified = 3,
    CaregiverRejected = 4,
    Approved = 5,
    InternalRejected = 6
}

// QualificationCategory
export enum QualificationCategory {
    BackgroundCheck = 'Background Check',
    Training = 'Training',
    Certification = 'Certification'
}

// User Role Name
export enum UserRoleDetails {
    Caregiver = 'Caregiver',
    EmployerOfRecord = 'EmployerOfRecord',
    Participant = 'Participant'
}

import { AppConstants } from './../../core/appconstants';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'langchange',
  pure: false
})
export class LangchangePipe implements PipeTransform {

  allLabels = [
    'label_myParticipants',
    'label_participants',
    'label_participants_count',
    'label_participant',
    'label_grid_ParticipantName',
    'Participant',
    'label_no_participant_mapped',
    'placeholder_auth_search',
    'abel_client_name',
    'label_client_id',
    'label_my_participant_dashboard',
    'label_participant_id',
    'label_participant_details',
    'label_add_participant',
    'label_delete_participant',
    'notification_confirm_delete_caseload_line2',
    'notification_delete_participant_validation',
    'notification_participant_required',
    'placeholder_participantId_name',
    'shift_start_address_message',
    'shift_start_location_message',
    'shift_end_location_message',
    'label_careGiver',
    'label_caregivers',
    'label_caregiver_comments',
    'label_no_caregiver_mapped',
    'notification_invalid_GHS_Exceed_Time',
    'employee_id',
    'label_caregiver',
    'label_employee',
    'label_employee_list',
    'label_Employee_Name',
    'label_DR',
    'label_client_name',
    'label_employee_details'
  ];
  constructor() { }

  transform(key: any): any {
    if (AppConstants.preferredLanguageData) {
      const userState = sessionStorage.getItem('userState');
      const isLabel = this.allLabels.find(el => el === key);
      if (isLabel && AppConstants.cdwa.includes(userState?.toUpperCase())) {
        const newkey = key + '_' + userState;
        const transFromText = AppConstants.preferredLanguageData[newkey];
        if (transFromText && transFromText.value) {
          return transFromText.value;
        }
      } else {
        const transFromText = AppConstants.preferredLanguageData[key];
        if (transFromText && transFromText.value) {
          return transFromText.value;
        }
      }
    }
    return '';
  }

}

export class FeatureConstants {
    public static readonly CCH = 'Global.Company.People.CCH';
    public static readonly Qualifications = 'Global.Company.People.Qualifications';
    public static readonly Notifications = 'Global.Company.NotificationHub.Notifications';
    public static readonly CaregiverDemographics = 'Global.Company.People.CaregiverDemographicsConfirmation';
    public static readonly CaregiverQualification = 'Global.Company.People.CaregiverQualificationsConfirmation';
    public static readonly CaregiverDoc = 'Global.Company.People.CaregiverDifficultOfCareConfirmation';
    public static readonly CaregiverFica = 'Global.Company.People.CaregiverFICAConfirmation';
    public static readonly CaregiverRel = 'Global.Company.People.CaregiverParticipantRelationshipConfirmation';
    public static readonly CaregiverTraining = 'Global.Company.People.CaregiverQualificationsConfirmation';
    public static readonly CaregiverBackgroundCheck = 'Global.Company.People.CaregiverBackgroundCheckConfirmation';
    public static readonly WWL = 'Global.Company.People.WWL';
    public static readonly Tasks = 'Global.Company.People.Tasks';
    public static readonly AuthAllocation = 'Global.Company.Authorization.AuthAllocation';
    public static readonly GHS = 'Global.Company.Shift.GHS';
    public static readonly AdminTimeAdjustment = 'U2.Company.Billing.AdminTimeAdjustment';
    public static readonly CopyForward = 'V4.Company.Shift.CopyForward';
    public static readonly PTO = 'Global.Company.People.PTO';
    public static readonly InternalAuth = 'U2.Company.Authorization.InternalAuth';
    public static readonly EmployerPayRates  = 'U2.Company.People.EmployerPayRates';
    public static readonly ShiftSubmitCountsAsApproved  = 'Global.Company.Shift.ShiftSubmitCountsAsApproved';
    public static readonly WaiverCodeSingleLineEdit  = 'U2.Company.Authorization.WaiverCodeSingleLineEdit';
    public static readonly PSST  = 'Global.Company.People.PSST';
    public static readonly CoEmploymentAutoEmployerRelationship = 'U2.Company.People.CoEmploymentAutoEmployerRelationship';
    public static readonly CoEmploymentEmployerOfRecordDisabled = 'U2.Company.People.CoEmploymentEmployerOfRecordDisabled';
    public static readonly CaregiverDemographicsConfirmation = 'Global.Company.People.CaregiverDemographicsConfirmation';
    public static readonly CGParticipantRelationshipConfirmation = 'Global.Company.People.CaregiverParticipantRelationshipConfirmation';
    public static readonly CaregiverDifficultOfCareConfirmation = 'Global.Company.People.CaregiverDifficultOfCareConfirmation';
    public static readonly CaregiverFICAConfirmation = 'Global.Company.People.CaregiverFICAConfirmation';
    public static readonly CaregiverQualificationsConfirmation = 'Global.Company.People.CaregiverQualificationsConfirmation';
    public static readonly CaregiverBackgroundCheckConfirmation = 'Global.Company.People.CaregiverBackgroundCheckConfirmation';
    public static readonly DisplayWorkdayButton = 'Global.Company.People.DisplayWorkdayButton';
    public static readonly PayStubHistoryGrid = 'Global.Company.Payroll.PayStubHistoryGrid';
    public static readonly ADPCompany = 'Global.Company.Payroll.ADPCompany';
    public static readonly SickTime = 'U2.Company.Authorization.SickTime';
    public static readonly ViewDocumentation = 'Global.Company.People.ViewDocumentation';
    public static readonly CGPTReqRelConfirmation = 'Global.Company.People.CaregiverParticipantRequestRelationConfirmation';
    public static readonly EditableIvrPin  = 'Global.Company.People.EditableIvrPin';
    public static readonly CaregiverParticipantDocument  = 'Global.Company.People.CaregiverViewPartcipantDocument';
    public static readonly RemoveReadyTimeEntry  = 'Global.Company.People.RemoveReadyTimeEntry';
    public static readonly RemoveReadyMileageEntry  = 'Global.Company.People.RemoveReadyMileageEntry';
    public static readonly CaregiverParticipantRequestRelationshipAuthorizedRep = 'Global.Company.People.CaregiverParticipantRequestRelationshipAuthorizedRep';
    public static readonly ShowCaregiverParticipantAttestations = 'Global.Company.People.ShowCaregiverParticipantAttestations';
    public static readonly SSNValidation = 'Global.Company.People.SSNValidation';
    public static readonly TestShiftEntry = 'V4.Company.People.V4TestShiftEntry';
    public static readonly VASickTime = 'Global.Company.Authorization.VASickTime'
    public static readonly V4CoreApi = 'Global.Company.V4CoreAPI.UseNewV4CoreAPI';
    public static readonly ShowQualificationRole = 'Global.Company.People.ShowQualificationRole';
    public static readonly HideSummaryDetailReports = 'V4.Company.People.HideSummaryAndDetailReports'
    public static readonly ParticipantSpendingSummaryOnCGV4 = "V4.Company.People.ParticipantSpendingSummaryOnCGV4";
    public static readonly V4NewShiftService = 'V4.Company.Shift.UseNewShiftService';
    public static readonly EnableShiftComments = 'V4.Company.People.EnableShiftComments';
    public static readonly V4McoShowShiftSummaryTasks = 'V4.Company.People.McoViewShiftSummaryTasks';
    public static readonly EnableSubmitMultipleMileageShifts = 'V4.Company.People.EnableSubmitMultipleMileageShifts';
    public static readonly isV4McoShowPayStubsEnabled = 'V4.Company.People.McoShowCaregiverPaystubs';
}

import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
import { ErrorExpressionConstants } from 'src/app/core/error-expression-constants';
import { KendoMasterGridProperties } from 'src/app/shared/utilities/kendomastergridproperties';

@Component({
  selector: 'app-qualifications-grid',
  templateUrl: './qualifications-grid.component.html',
  styleUrls: []
})
export class QualificationsGridComponent implements OnInit, OnChanges {

  @Input() gridData;
  @Input() isloading;
  pageSize: number;
  pageNumber: number;
  skip: number;
  searchParam: string;
  sortColumn: string;
  sortDirection: string;
  filterColumn: string;
  filterValue: string;
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[] = [];
  loading: boolean;
  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
    this.setKendoGridDefaultValues();
    this.loading = this.isloading;
  }

  ngOnChanges() {
    if (this.gridData) {
      this.loading = false;
    }
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.sortColumn = sort[0].dir !== undefined ? sort[0].field : undefined;
    this.sortDirection = sort[0].dir;
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    if (filter.filters.length > 1) {
      this.toastr.error(ErrorExpressionConstants.kendoGridFilterOnlybyOneColumnErrorMessage);
      this.filter = { filters: [this.filter.filters[0]], logic: 'and' } as CompositeFilterDescriptor;
    } else {
      this.filter = filter;
      this.clearRequestFields();
      if (this.filter.filters.length === 1) {
        const filterColumnNameColumnValue = KendoMasterGridProperties.getFilterColumnNameColumnValue(this.filter);
        this.filterColumn = filterColumnNameColumnValue.columnName;
        this.filterValue = filterColumnNameColumnValue.columnValue;
      } else {
        this.filterColumn = undefined;
        this.filterValue = undefined;
      }
    }
  }

  pageChange(event: PageChangeEvent): void {
    this.pageNumber = KendoMasterGridProperties.getPageNumber(event.skip, event.take);
    this.skip = event.skip;
  }

  setKendoGridDefaultValues(): void {
    this.pageSize = KendoMasterGridProperties.pageSize;
    this.pageNumber = KendoMasterGridProperties.pageNumber;
    this.skip = KendoMasterGridProperties.skip;
    this.sort = [{ field: 'qualificationType', dir: 'asc' }];
    this.filter = { filters: [], logic: 'and' };
    this.sortColumn = 'qualificationType';
    this.sortDirection = 'asc';
    this.filterColumn = undefined;
    this.filterValue = undefined;
  }


  clearRequestFields(): void {
    this.pageSize = KendoMasterGridProperties.pageSize;
    this.pageNumber = KendoMasterGridProperties.pageNumber;
    this.skip = KendoMasterGridProperties.skip;
    this.loading = false;
    this.filterColumn = undefined;
    this.filterValue = undefined;
  }

}

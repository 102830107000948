import { MsalChildGuard } from './core/routeguards/msal-child.gaurd';
import { MsalCustomService } from './core/services/msal.service';
import { MsalGuard } from './core/routeguards/msal.guard';
import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DialogsModule, DialogModule } from '@progress/kendo-angular-dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EmptyComponent } from './empty/empty.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/TokenInterceptor';
import { InternalUserModule } from './internal-user/internal-user.module';
import { ExternalUserModule } from './external-user/external-user.module';
import { MainHeaderComponent } from './main-header/main-header.component';
import { AppSharedModule } from './app-shared-module/app-shared-module.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PortalUserService } from './core/services/portalUser.service';
import { UserIdleModule } from 'angular-user-idle';
import { LocationStrategy, HashLocationStrategy, DatePipe, DecimalPipe } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ToastrModule } from 'ngx-toastr';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ApiResponseInterceptor } from './core/interceptors/api-response-interceptor';
import { LanguageChangeService } from './core/services/language-change.service';
import { AppConstants } from './core/appconstants';
import { CommonPagesModule } from './common-pages/common-pages.module';
import { RegistrationStatusComponent } from './registration-status/registration-status.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SignoutComponent } from './signout/signout.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ErrorPageComponent } from './errorpage/errorpage.component';
import { LogoHeaderComponent } from './logo-header/logo-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { V4_API_BASE_URL } from './external-user/services/v4ApiServices/v4ApiServices.services';
import { environment } from 'src/environments/environment';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DemographicAttestationComponent } from './external-user/attestation/demographic-attestation/demographic-attestation.component';
import { B2CLoginRedirectComponent } from './b2c-login-redirect/b2cloginredirect.component';

export function setupLanguageChangeFactory(
  service:
    // eslint-disable-next-line @typescript-eslint/ban-types
    LanguageChangeService): Function {
  return () => service.use(AppConstants.defaultLanguage);
}
import {
  MsalBroadcastService,
  MsalModule, MsalService, MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory } from './core/services/msal.config';
import { ErrorHandlerService } from './core/services/error-handler.service';
import { LandingPageSuccessPopupComponent } from './landing-page/success-popup/success-popup.component';

export let AppInjector: Injector;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationStatusComponent,
    LandingPageComponent,
    EmptyComponent,
    MainHeaderComponent,
    SignoutComponent,
    ErrorPageComponent,
    LogoHeaderComponent,
    DemographicAttestationComponent,
    B2CLoginRedirectComponent,
    LandingPageSuccessPopupComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    DialogsModule,
    BrowserAnimationsModule,
    AppSharedModule,
    GridModule,
    ReactiveFormsModule,
    NgbModule,
    MsalModule,
    UserIdleModule.forRoot({ idle: AppConstants.sessionTimeOut, timeout: AppConstants.sessionLogOut, ping: AppConstants.keepAliveIntervel }),
    DateInputsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
      enableHtml: true,
    }),
    InputsModule,
    DialogModule,
    TooltipModule,
    DropDownsModule,
    FormsModule,
    PDFExportModule,
    ChartsModule
  ],
  providers: [
    MsalGuard,
    MsalChildGuard,
    MsalService,
    PortalUserService,
    MsalCustomService,
    MsalBroadcastService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true
    },
    {
      provide: V4_API_BASE_URL,
      useFactory: () => {
        return environment.apiBaseUrl;
      },

    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    DatePipe,
    DecimalPipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    LanguageChangeService,
    { provide: LOCALE_ID, useValue: 'en-US' }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}

import { UserRole } from './../userrole';
import { StorageConstants } from './../storageconstants';
import { Actions, AppConstants, EnrollmentStatus } from './../appconstants';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiURLConstants } from '../apiurlconstants';
import { CurrentUser } from '../currentuser';
import { CurrentUserDetails, PortalActions } from '../models/currentuserdetails';
import { RoutePermissions } from '../models/routepermissions';
import { AppInsightsService } from './app-insights.service';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsConstants } from '../application-insights-constants';
import { SessionService } from './session.service';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionsService } from './common-functions.service';
import { FeatureStateService } from './featureStateService';
import { MsalCustomService } from './msal.service';
import { catchError, map, of, switchMap } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PortalUserService {
  private unread = 0;
  private personStopTimeEntryList : PortalActions [] = [];

  get unreadCnt() {
    return this.unread;
  }

  set unreadCnt(value: number) {
    this.unread = value;
  }
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private httpService: HttpClient,
              private appInsightsService: AppInsightsService, private sessionSvc: SessionService, private toastr: ToastrService,
              private commonFunction: CommonFunctionsService, private featureStateService: FeatureStateService,
              private msalService: MsalCustomService) {

  }

private setUserDisplayName() {
  AppConstants.userDisplayName = CurrentUser.details.firstName + ' ' + CurrentUser.details.lastName;
}

  public async getUserDetails(redirectToDefault = false, roleId: number = 0) {
    AppConstants.showLoading = true;
    return new Promise((resolve, reject) => {
      this.httpService.get(ApiURLConstants.GetUserDetails + roleId)
        .subscribe(
          (x: CurrentUserDetails) => {
            (async () => {
              CurrentUser.details = x;
              if (CurrentUser?.details?.fullName) {
                this.setUserDisplayName();
                this.getPersonsStopTimeEntryList();
              }
              CurrentUser.role = x.roleName;
              if (CurrentUser.details.impersonateUserId > 0) {
              this.setImpersonateUserName();
              }
              const getimpersonateData = sessionStorage.getItem(StorageConstants.ImpersonateSession);
              this.getImpersonateData(getimpersonateData);
              sessionStorage.setItem(StorageConstants.LoginUserDetails, JSON.stringify(CurrentUser.details));
              await this.getV4RoleScreensDetails(this.V4CompanyCode);

              if (redirectToDefault && x.roleText && (getimpersonateData === null || getimpersonateData === 'null')) {
                this.redirectToImpersonate(x);
              }
              resolve(true);
              AppConstants.showLoading = false;
            })()},
          err => {
            this.handleUserDetailsError(err);
            resolve(true);
          }
      );
    });
  }

  public setImpersonateUserName(){
    CurrentUser.details.impersonateFirstName = CurrentUser.details.impersonateFirstName.replace('&apos;', '\'') || CurrentUser.details.impersonateFirstName;
    CurrentUser.details.impersonateLastName = CurrentUser.details.impersonateLastName.replace('&apos;', '\'') || CurrentUser.details.impersonateLastName;
  }

  public getImpersonateData(getimpersonateData){
    if (!sessionStorage.getItem(StorageConstants.userLoginStatus)) {
      if (CurrentUser.isExternalUser) {
        this.appInsightsService.logEvent('External user - sign in');
      } else {
        this.appInsightsService.logEvent('Internal user - sign in');
        if (getimpersonateData &&  getimpersonateData.indexOf('impersonate') > 0) {
          const impersonateUrl = getimpersonateData.split('/');
          const role = impersonateUrl[2];
          const personId = impersonateUrl[3];
          if (role && personId) {
              this.impersonateU2User(role, personId);
            }
        }
      }
      this.updateUserLogin();
    }
  }

  public redirectToImpersonate(x){
    if (CurrentUser.details.impersonateUserId > 0) {
      this.router.navigateByUrl(this.sessionSvc.impersonatedUserRouteUrl);
    } else {
      this.router.navigateByUrl(x.userAccessRight.routeUrl);
    }
  }
  public async updateUserLogin() {
    return new Promise((resolve, reject) => {
      this.httpService.get(ApiURLConstants.userLoginAudit)
        .subscribe(
          (x) => {
            sessionStorage.setItem(StorageConstants.userLoginStatus, CurrentUser.details.solomonId);
            resolve(true);
          },
          err => {
            resolve(true);
          }
        );
    });
  }

  public async getCompanyInfo(companyCode: string) {
    if (!companyCode) {
      return Promise.resolve(true);
    }
    return new Promise((resolve, reject) => {
      this.httpService.get(ApiURLConstants.CompanyInfo)
        .subscribe(
          (data: any) => {
            sessionStorage.setItem(AppConstants.companyFeaturesStorageKey, btoa(JSON.stringify(data.features)));
            sessionStorage.setItem(AppConstants.companyConfigStorageKey, btoa(JSON.stringify(data.configuration)));
            resolve(true);
          },
          err => {
            this.toastr.error(AppConstants.unableToLoadFeatures);
            resolve(true);
          }
        );
    });
  }

  public async getV4RoleScreensDetails(companyCode: string) {
    if (!companyCode) {
      return Promise.resolve(true);
    }
    return new Promise((resolve, reject) => {
      this.httpService.get(ApiURLConstants.V4RoleScreensDetails)
        .subscribe(
          (data: any) => {
            sessionStorage.setItem(AppConstants.roleScreensConfigStorageKey, btoa(JSON.stringify(data)));
            resolve(true);
          },
          err => {
            this.toastr.error(AppConstants.unableToLoadFeatures);
            resolve(true);
          }
        );
    });
  }

  get V4CompanyCode() {
    return CurrentUser.details.impersonateUserId > 0 ?
    CurrentUser.details.impersonateUserCompanyCode : CurrentUser.details.companyCode;
  }

  public handleUserDetailsError(err: any) {
    if (err.status === 400 || err.status === 401) {
      AppConstants.showErrorPage = true;
      AppConstants.errorMessage = err.error;
      this.router.navigate(['error']);
    } else {
      AppConstants.showErrorPage = false;
    }
    AppConstants.showLoading = false;
  }

  public async registerUser() {
    const token = sessionStorage.getItem(StorageConstants.msalIdToken);
    if (token) {
      const deToken = JSON.parse(token);
      if (deToken.acr && (deToken.acr as string).toLowerCase() === environment.signUpPolicy.toLowerCase()) {
        const registartionStatus = sessionStorage.getItem(StorageConstants.userRegistration);
        if (registartionStatus === StorageConstants.userRegistrationStatus) {
          return Promise.resolve(true);
        }
        
        const userDto = {
          objectId: deToken.oid
        };
        return await new Promise((resolve, reject) => {
          this.httpService.post(ApiURLConstants.registerUserApiUrl, userDto)
            .subscribe(
              (x) => {
                sessionStorage.setItem(StorageConstants.userRegistration, StorageConstants.userRegistrationStatus);
                resolve(true);
              },
              err => {
                resolve(true);
              }
            );
        });

      } else {
        return Promise.resolve(true);
      }
    }
  }

  public async InitiateUserSession(redirectToDefault = false, roleId: number =  0) {
    return new Promise((resolve, reject) => {
      this.httpService.get(ApiURLConstants.InitiateUserSession)
        .subscribe(
          (x: any) => {
            (async () => {
            sessionStorage.setItem(StorageConstants.sessionId, x.sessionId);
            const getimpersonateData = sessionStorage.getItem(StorageConstants.ImpersonateSession);
            if (getimpersonateData === null || getimpersonateData === undefined || getimpersonateData === 'null') {
              await this.registerUser();
            }
            await this.setWhiteListUrls();
            await this.getUserDetails(redirectToDefault, roleId);
            if (CurrentUser.details !== null && CurrentUser.details !== undefined){
              await this.getCompanyInfo(CurrentUser.details.impersonateUserId > 0 ?
                  CurrentUser.details.impersonateUserCompanyCode : CurrentUser.details.companyCode);
            }
            AppConstants.showLoading = false;
            resolve(true);
          })()},
          err => {
            if (err.status === 403) {
              AppConstants.showErrorPage = true;
              AppConstants.errorMessage = err.error;
              this.router.navigate(['error']);
            }
            AppConstants.showLoading = false;
            resolve(err);
          }
        );
    });
  }

  public async setWhiteListUrls() {
    this.httpService.get(ApiURLConstants.getWhiteListUrls)
        .subscribe((x: any) => {
             CurrentUser.whitelistUrls = x;
          },
          err => {
            CurrentUser.whitelistUrls = [];
          }
      );
  }


  checkUserUrlAccessRights(routePermissions: RoutePermissions): boolean {
    let hasAccess = true;
    if (routePermissions) {
      if (routePermissions.actions) {
        let userHasAccess = false;
        for (let i = 0; i < routePermissions.actions.length; i++) {
          const actionRight = routePermissions.actions[i];
          userHasAccess = CurrentUser.details.userAccessRight.rolePermissions.filter(x => x.accessRight === actionRight).length > 0;
          if (userHasAccess) {
            break;
          }
        }
        hasAccess = userHasAccess;
      }
    }
    return hasAccess;
  }

  checkUserAccessRights(accessRight: string): boolean {
    let hasAccess = false;
    if (accessRight && CurrentUser.details) {
      if (CurrentUser.details.impersonateUserId) {
        hasAccess = true; // TODO - Have to use Impersonated user's permissions. Akanksha is working
      } else {
        hasAccess = CurrentUser.details.userAccessRight.rolePermissions.filter(x => x.accessRight === accessRight).length > 0;
      }
    }
    return hasAccess;
  }

  getRouteData() {
    let child = this.activatedRoute.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['permissions']) {
        this.appInsightsService.logPageView(child.snapshot.data['title']);
        return child.snapshot.data['permissions'] as RoutePermissions;
      } else {
        return null;
      }
    }
    return null;
  }

  public showNotificationIcon() {
    const roleId = CurrentUser.details.impersonateUserId > 0 ?
      CurrentUser.details.impersonateUserRoleId :
      CurrentUser.details.roleId;
    let showIcon = false;
    showIcon = (roleId === UserRole.Caregiver
      || roleId === UserRole.Participant
      || roleId === UserRole.SupportBroker
      || roleId === UserRole.DesignatedRepresentative
      || roleId === UserRole.EmployerOfRecord)
      && (CurrentUser.details.enrollmentStatus === EnrollmentStatus.RegistrationComplete);
    return showIcon;
  }

  public getAllCanBeImpersonatedUser(ImpersonatefilterRequest: any): any {
    return this.httpService.post(ApiURLConstants.getAllCanBeImpersonatedUser, ImpersonatefilterRequest);
  }

  public setPrimaryRole(primaryRole: any): any {
    return this.httpService.post(ApiURLConstants.setPrimaryRole, primaryRole);
  }

  public impersonatedSelectedUser(impersonatedSelectedUser: any) {
    const userDTO = impersonatedSelectedUser;
    const { Id, ImpersonateUserId } = impersonatedSelectedUser;
    const logData = { Id, ImpersonateUserId };
    this.appInsightsService.trackTrace(ApplicationInsightsConstants.impersonatedSelectedUser, logData);
    return this.httpService.post(ApiURLConstants.CreateSessionForImpersonatedUser, userDTO);
  }

  public getUserDetailsByPersonId(personId: string, role: string): any {
    const roleId = UserRole[role];
    this.appInsightsService.trackTrace(ApplicationInsightsConstants.getUserDetailsByPersonId, {personId});
    return this.httpService.get(ApiURLConstants.getUserDetailsByPersonId + '?personId=' + personId + '&roleId=' + roleId);
  }

  private impersonateU2User(role: string, personId: string) {
    const impersonateSessionData = {
      data : sessionStorage.getItem(StorageConstants.ImpersonateSession),
      toBeSaved: false
    };

    this.httpService.post(ApiURLConstants.SetImpersonateSessionDetailsUrl, impersonateSessionData).subscribe(
      (x) => {
        this.getUserDetailsByPersonId(personId, role).subscribe((result) => {
          const data = result;
          this.impersonatedData(data);
         });
      }
    );
  }

  private impersonatedData(data) {
      if (data !== null) {
        const impersonateUserSelectedItem: any = {};
        impersonateUserSelectedItem.ImpersonateFirstName = data.firstName;
        impersonateUserSelectedItem.ImpersonateUserCompanyCode = data.companyCode;
        impersonateUserSelectedItem.ImpersonateUserEmail = data.email;
        impersonateUserSelectedItem.ImpersonateUserId = data.id;
        impersonateUserSelectedItem.ImpersonateLastName = data.lastName;
        impersonateUserSelectedItem.ImpersonatePreferredLanguage = data.preferredLanguage;
        impersonateUserSelectedItem.ImpersonatePreferredLanguageId = data.preferredLanguageId;
        impersonateUserSelectedItem.ImpersonateUserRoleId = data.roleId;
        impersonateUserSelectedItem.ImpersonateUserRole = data.roleName;
        impersonateUserSelectedItem.ImpersonateRoleDisplayText = data.roleText;
        impersonateUserSelectedItem.ImpersonateUserSolomonId = data.solomonId;
        impersonateUserSelectedItem.ImpersonateUserStatecode = data.statecode;
        impersonateUserSelectedItem.ImpersonateUserStateId = data.stateId;
        impersonateUserSelectedItem.ImpersonateUserStatusId = data.userStatusId;
        this.impersonateSelectedUser(impersonateUserSelectedItem);
      } else {
        this.toastr.error('No Data to impersonate');
        sessionStorage.removeItem(StorageConstants.ImpersonateSession);
      }
    }

    private impersonateSelectedUser(impersonateUserSelectedItem) {
      impersonateUserSelectedItem.isImpersonating = true;
      this.impersonatedSelectedUser(impersonateUserSelectedItem).subscribe((result: any) => {
        if (result !== null) {
          CurrentUser.details.impersonateFirstName = impersonateUserSelectedItem.ImpersonateFirstName;
          CurrentUser.details.impersonateUserCompanyCode = impersonateUserSelectedItem.ImpersonateUserCompanyCode;
          CurrentUser.details.impersonateUserEmail = impersonateUserSelectedItem.ImpersonateUserEmail;
          CurrentUser.details.impersonateUserId = impersonateUserSelectedItem.ImpersonateUserId;
          CurrentUser.details.impersonateLastName = impersonateUserSelectedItem.ImpersonateLastName;
          CurrentUser.details.impersonatePreferredLanguage = impersonateUserSelectedItem.ImpersonatePreferredLanguage;
          CurrentUser.details.impersonatePreferredLanguageId = impersonateUserSelectedItem.ImpersonatePreferredLanguageId;
          CurrentUser.details.impersonateUserRoleId = impersonateUserSelectedItem.ImpersonateUserRoleId;
          CurrentUser.details.impersonateUserRole = impersonateUserSelectedItem.ImpersonateUserRole;
          CurrentUser.details.impersonateRoleDisplayText = impersonateUserSelectedItem.ImpersonateRoleDisplayText;
          CurrentUser.details.impersonateUserSolomonId = impersonateUserSelectedItem.ImpersonateUserSolomonId;
          CurrentUser.details.impersonateUserStateCode = impersonateUserSelectedItem.ImpersonateUserStatecode;
          CurrentUser.details.impersonateUserStateId = impersonateUserSelectedItem.ImpersonateUserStateId;
          CurrentUser.details.impersonateUserStatusId = impersonateUserSelectedItem.ImpersonateUserStatusId;
          this.sessionSvc.setImpersonatedUserUrl(AppConstants.impersonatedUserRouteUrl, result.routeUrl);
          window.location.href = window.location.origin;
          if (CurrentUser.details.impersonateUserId > 0) {
            sessionStorage.removeItem(StorageConstants.ImpersonateSession);
          } else {
            this.toastr.error('No Data to impersonate');
            sessionStorage.removeItem(StorageConstants.ImpersonateSession);
          }

        }
      });
    }

  public getPersonsStopTimeEntryList() {
    let portalActions = CurrentUser.details?.portalActions;
    this.personStopTimeEntryList = portalActions?.filter((element) => element.actions.some((subElement) => subElement.action.toString() === Actions.V4_Stop_Time_Entry));
  }

  public checkPersonTimeEntryAccessWithAssociates(date: Date, selectedPersonId: number): boolean {
    let isTimeEntryEnable = false
    let personTimeEntry : PortalActions[]= [];


      personTimeEntry = this.personStopTimeEntryList?.filter((entry) => entry.associatedPerson.personId === selectedPersonId.toString());

    if (personTimeEntry.length > 0) {
      let startDate = new Date(personTimeEntry[0]?.startDate);
      let endDate = new Date(personTimeEntry[0]?.endDate);
      let selDate = new Date(date);



      if (selDate <= endDate && selDate >= startDate) {
        isTimeEntryEnable = true;
      }
    }
    return isTimeEntryEnable;
  }


  public checkPersonTimeEntryAccessWithLoginPerson(date: Date, selectedPersonId: number): boolean {
    let isTimeEntryEnable = false
    let personTimeEntry : PortalActions[]= [];


      personTimeEntry = this.personStopTimeEntryList?.filter((entry) => entry.associatedPerson.personId === null);


    if (personTimeEntry.length > 0) {
      let startDate = new Date(personTimeEntry[0]?.startDate);
      let endDate = new Date(personTimeEntry[0]?.endDate);
      let selDate = new Date(date);


      if (selDate <= endDate && selDate >= startDate) {
        isTimeEntryEnable = true;
      }
    }
    return isTimeEntryEnable;
  }

  public checkPersonHideRelationAccess(date: Date): boolean {
    let isTimeEntryEnable = false
    let personHideRelation : PortalActions[]= [];
    let portalActions = CurrentUser.details?.portalActions;

    personHideRelation = portalActions.filter((element) => element.actions.some((subElement) => subElement.action.toString() === Actions.V4_Hide_Relatioship));

    if (portalActions) {
      let startDate = this.commonFunction.convertToUTCDateTimeZeroHour(new Date(personHideRelation[0].startDate)).getTime();
      let endDate = this.commonFunction.convertToUTCDateTimeZeroHour(new Date(personHideRelation[0].endDate)).getTime();
      let selDate = this.commonFunction.convertToUTCDateTimeZeroHour(date).getTime()


      if (selDate >= startDate && selDate <= endDate) {
        isTimeEntryEnable = true;
      }
    }
    return isTimeEntryEnable;
  }

  public logout() {
    return this.httpService.get(ApiURLConstants.userLogout).pipe(
      switchMap(() => this.msalService.logoutRedirect()),
      catchError((error) => this.handleErrorLogout(error)),
      map(() => sessionStorage.clear())
    );
  }

  private async handleErrorLogout(error: any) {
    if (error instanceof HttpErrorResponse) {
      return this.msalService
        .logoutRedirect()
        .pipe(catchError(() => this.router.navigateByUrl("signout")));
    } else {
      this.router.navigateByUrl("signout");
      return of(null);
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants, AttestationCode } from 'src/app/core/appconstants';
import { CommonFunctionsService } from 'src/app/core/services/common-functions.service';
import { FeatureStateService } from 'src/app/core/services/featureStateService';
import { StorageConstants } from 'src/app/core/storageconstants';
import { CaregiverParticipantRelQuestionnaireDto, AttestationFormResponse, 
  AttestationServiceProxy, AttestationFormRequest } from 'src/app/external-user/services/v4ApiServices/v4ApiServices.services';

@Component({
  selector: 'app-fica-attestation',
  templateUrl: './fica-attestation.component.html',
  styles: [
  ]
})
export class FicaAttestationComponent implements OnInit {

  docReqObj = new CaregiverParticipantRelQuestionnaireDto();
  attestationRes = [];
  participantId;
  CaregiverParticipantDetails;
  isCaregiverFicaEnabled = false;
  caregiverParticipantData = JSON.parse(sessionStorage.getItem(StorageConstants.CaregiverParticipantRel));
  showConfirmModal = false;
  mainpopupWidth = 500;
  @Input() participantData;
  @Input() solomonId;
  @Output() emitCompleteAttestation = new EventEmitter();
  @Input() languageText;
  @Output() langChange = new EventEmitter();
  @Input() isImpersonateUser;
  companyConfig;
  questionnaireLanguage;
  attestationDetails: AttestationFormResponse[] = [];
  ficaForm: UntypedFormGroup;
  ficaFormsubmitted = false;
  ShowQuestion42 = false;
  ShowQuestion45 = false;

  constructor(private AttestationServiceApi: AttestationServiceProxy, private toaster: ToastrService,
              public activatedRoute: ActivatedRoute, public formBuilder: UntypedFormBuilder,
              public commonFunction: CommonFunctionsService, private featureStateService: FeatureStateService) {
                this.isCaregiverFicaEnabled = this.featureStateService.isCaregiverFicaValid();
                const companyConfigValue = sessionStorage.getItem(AppConstants.companyConfigStorageKey);
                this.companyConfig = (JSON.parse(atob(companyConfigValue)));
  }

  ngOnInit(): void {
    this.participantId = this.participantData.participantId ? this.participantData.participantId : undefined;
    if (this.isCaregiverFicaEnabled) {
      this.initializeFicaForm();
      this.getCaregiverParticipantDetails();
      this.getSelectedLanguageCode();
      this.getFicaQuestions();
    }
  }

  changeLanguage() {
    this.langChange.emit();
    this.getSelectedLanguageCode();
    this.getFicaQuestions();
    this.initializeFicaForm();
    this.ficaFormsubmitted = false;
    this.ShowQuestion42 = false;
    this.ShowQuestion45 = false;
  }

  getCaregiverParticipantDetails() {
    this.CaregiverParticipantDetails = this.participantData;
  }

  closeModal(option) {
    this.showConfirmModal = false;
  }

  buildRequest() {
    this.ficaFormsubmitted = true;
    const postObj = this.commonFunction.buildRequestForDocFica();
    this.docReqObj.attestationResponseId = 0;
    this.docReqObj.attestationVersion = this.companyConfig.ficaAttestationVersion;
    this.docReqObj.participantPersonId = this.participantId;
    this.docReqObj.caregiverPersonId = this.solomonId;
    this.docReqObj.confirmationDate = new Date();
    this.docReqObj.languageCode = this.questionnaireLanguage;
    this.docReqObj.attestationResponseDetails = postObj;
    this.docReqObj.participantFirstName = this.participantData.participantFirstName;
    this.docReqObj.participantLastName = this.participantData.participantLastName;
    if (this.ficaForm.valid){
      this.showConfirmModal = true;
    }
  }

  initializeFicaForm(){
    this.ficaForm = this.formBuilder.group({
      37: new UntypedFormControl('', Validators.required),
      38: new UntypedFormControl('', Validators.required),
      39: new UntypedFormControl(''),
      40: new UntypedFormControl('', Validators.required),
      41: new UntypedFormControl('', Validators.required),
      42: new UntypedFormControl(''),
      43: new UntypedFormControl('', Validators.required)
    });
  }

  getFicaQuestions(){
    AppConstants.showLoading = true;
    const docFormReq = new AttestationFormRequest();
    docFormReq.attestationVersion = this.companyConfig.ficaAttestationVersion;
    docFormReq.languageCode = this.questionnaireLanguage;
    docFormReq.attestationCode = AttestationCode.FICA;
    this.AttestationServiceApi.getAttestationForm(docFormReq).subscribe(res => {
      if (res.attestationFormComponents && res.attestationFormComponents.length > 0){
        this.attestationDetails = res.attestationFormComponents;
        AppConstants.showLoading = false;
      }
    }, error => {
      this.toaster.error(error);
      AppConstants.showLoading = false;
    });
  }

  getSelectedLanguageCode(){
    const selectedLanguage = sessionStorage.getItem(StorageConstants.selectedlanguage);
    this.questionnaireLanguage = this.commonFunction.fetchU2LanguageCode(selectedLanguage);
  }

  get ficaFormControls() {
    return this.ficaForm;
  }

  valueChange(e, selectedControl){
    this.ficaForm.patchValue({[selectedControl]: e.target.value});

    if (selectedControl === 38){
      if (e.target.value === '36'){
        this.ShowQuestion42 = true;
        this.ficaForm.controls['39'].setValidators(Validators.required);
        this.ficaForm.controls['39'].updateValueAndValidity();
      } else {
        this.ShowQuestion42 = false;
        this.disableNextQuestionOrAnswer(['39']);
        this.ficaForm.controls['39'].setValidators(null);
        this.ficaForm.get('39').reset('');
      }
    }

    if (selectedControl === 41){
      if (e.target.value === '42'){
        this.ShowQuestion45 = true;
        this.ficaForm.controls['42'].setValidators(Validators.required);
        this.ficaForm.controls['42'].updateValueAndValidity();
      } else {
        this.ShowQuestion45 = false;
        this.disableNextQuestionOrAnswer(['q42o44', 'q42o45', 'q42o46', 'q42o53']);
        this.ficaForm.controls['42'].setValidators(null);
        this.ficaForm.controls['42'].updateValueAndValidity();
        this.ficaForm.get('42').reset('');
      }
    }

    if (selectedControl === 42){
      if ((e.target.value === '44') || (e.target.value === '45') || (e.target.value === '46')){
        this.disableNextQuestionOrAnswer(['q42o53']);
      } else{
        this.disableNextQuestionOrAnswer(['q42o44', 'q42o45', 'q42o46']);
      }
    }

    if (selectedControl === 43){
      this.checkIfAffirmationSelected(['43']);
    }
  }

  disableNextQuestionOrAnswer(currentQuestion){
    currentQuestion.forEach(aid => {
      const element = document.getElementsByName(aid);
      element.forEach(el => {
        const elm = el as HTMLInputElement;
        if (elm.checked){
          elm.checked = false;
        }
      });
    });
  }

  checkIfAffirmationSelected(currentQuestion){
    const element = document.getElementsByName(currentQuestion);
    element.forEach(el => {
      const elm = el as HTMLInputElement;
      if (!elm.checked){
        this.ficaForm.get('43').reset('');
      }
    });
  }
}

import { Role } from './../core/models/currentuserdetails';
import { LanguageChangeService } from './../core/services/language-change.service';
import { HttpClient } from '@angular/common/http';
import { MsalCustomService } from '../core/services/msal.service';
import { Component, OnInit, AfterViewChecked, DoCheck } from '@angular/core';
import { CurrentUser } from '../core/currentuser';
import { AppConstants, EnrollmentStatus } from '../core/appconstants';
import { Router } from '@angular/router';
import { PortalUserService } from '../core/services/portalUser.service';
import { AccessRightsKeys } from '../core/accessrightskeys';
import { StorageConstants } from '../core/storageconstants';
import { locales } from '../core/locales.values';
import { SelectableSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { KendoMasterGridProperties } from '../shared/utilities/kendomastergridproperties';
import { ToastrService } from 'ngx-toastr';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { AttestationStatusEnum, UserRole } from '../core/userrole';
import { CommonFunctionsService } from 'src/app/core/services/common-functions.service';
import { SessionService } from '../core/services/session.service';
import { Subject } from 'rxjs';
import { AppInsightsService } from '../core/services/app-insights.service';
import { FeatureStateService } from '../core/services/featureStateService';
import { AttestationServiceProxy, CaregiverAttestationStatusDetailsDto,
  CaregiverParticipantRelAttestationResponse } from '../external-user/services/v4ApiServices/v4ApiServices.services';
import { environment } from 'src/environments/environment';
import { CommonService } from '../core/services/common.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, AfterViewChecked, DoCheck {
  loggedInUser = CurrentUser;
  selectedMenuIndex = 1;
  languageText = locales[1].text;
  registrationComplete = false;
  appConstants = AppConstants;
  showNotifictaionIcon = false;
  showImpersonateUserPopup = false;
  ImpersonateGridAllValue: any[] = [];
  public ImpersonateGridAll: any = [];
  sortImpersonateGridAll: any = [{ field: 'solomonId', dir: 'asc' }];
  filterImepersonateGridAll: CompositeFilterDescriptor = { logic: 'or', filters: [] };
  public loadingImpersonateUserGridAll = false;
  public loading = true;
  skipImpersonateGridAll: number;
  public sortColumn: string;
  public sortDirection: string;
  public filterColumn: string;
  selectedrow: any = [];
  public filterValue: string;
  impersonateUserSelectedItem: any = {};
  public pageNumber: number;
  public searchParam: string;
  public pageSize = KendoMasterGridProperties.pageSize;
  public ImpersonateUserModelSearchRequest: any = {};
  public showSelectAllBtn = true;
  firstName: string;
  public routeUrlForImpersonateUser: string;
  public ImpersonateGridAllSelectableSettings: SelectableSettings = { checkboxOnly: false, mode: 'single' };

  canGetProfileDetails = false;
  canGetAllCanBeImpersonatedUser = false;
  canImpersonateAccess = false;
  currentLanguage = new Subject<string>();
  setPrimaryRole = false;
  multiRoleMsg = false;
  primaryRoleSet = false;
  primaryRole: number;
  roles: Role[] = [];
  primaryRoleRequired = false;
  isNotificationEnabled = false;
  StorageConstants = StorageConstants;
  solomonId;
  environment = '';
  currentUserData = JSON.parse(sessionStorage.getItem(StorageConstants.LoginUserDetails));
  caregiverId;
  isCaregiverDocEnabled = false;
  isCaregiverFicaEnabled = false;
  isCaregiverRelEnabled = false;
  isCaregiverDemographicsEnabled = false;
  isCaregiverTrainingEnabled = false;
  isCaregiverBackgroundCheckEnabled = false;
  showEmpInfo = false;
  isdemograpRejected = false;
  showCaregiverModel = false;
  showConfirmModal = false;
  showQualificationCheck = false;
  showQualificationGrid = false;
  showTrainingnGrid = false;
  showTrainingText = false;
  showAttestationList = false;
  attestationData: CaregiverAttestationStatusDetailsDto;
  noOfParticipants = 0;
  currentLoop = 0;
  showCgRelCheck = false;
  showCgDocCheck = false;
  showCgFicaCheck = false;
  showWorkDayCheck = false;
  currentParticipantData: CaregiverParticipantRelAttestationResponse;
  pageLoading = false;
  isApiCalled = false;
  isImpersonate = false;
  constructor(
    public msalService: MsalCustomService, private httpService: HttpClient, public common: CommonFunctionsService,
    private router: Router, private portalUserService: PortalUserService, private languageChangeService: LanguageChangeService,
    private toaster: ToastrService, private AttestationServiceApi: AttestationServiceProxy,
    public sessionSvc: SessionService, private appInsightsService: AppInsightsService,
    private featureStateService: FeatureStateService, private commonService: CommonService) {
    this.setPreferredLanguage();
    this.setUserAccess();
    AppConstants.currentLanguage = this.currentLanguage.asObservable();
  }

  ngOnInit() {
    this.getAllAttestationData();
    this.environment = environment.envName;
    this.loggedInUser = CurrentUser;
    if (this.loggedInUser.details) {
      sessionStorage.setItem('userState', this.loggedInUser.details.impersonateUserId > 0 ?
      this.loggedInUser.details.impersonateUserStateCode :
      this.loggedInUser.details.stateCode);
      const userdetails = this.loggedInUser.details;
      this.multiRoleMsg = userdetails.userStatusId === 5 && userdetails.allRoles.length > 1 && !userdetails.primaryRoleId;
      this.roles = this.loggedInUser.details.allRoles;
      CurrentUser.details.impersonateUserAccessRight = {};
      this.registrationComplete = CurrentUser.details.enrollmentStatus === EnrollmentStatus.RegistrationComplete;
      this.showNotifictaionIcon = this.portalUserService.showNotificationIcon();
      this.isImpersonate = this.currentUserData.impersonateUserId > 0;
    }
    this.isNotificationEnabled =  this.featureStateService.isNotificationEnabled();
  }

  ngAfterViewChecked(): void {
    this.isNotificationEnabled =  this.featureStateService.isNotificationEnabled();
  }

  public signOutFromApp() {
    AppConstants.showLoading = true;
    this.portalUserService.logout().subscribe();
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageNumber = KendoMasterGridProperties.getPageNumber(event.skip, event.take);
    this.skipImpersonateGridAll = event.skip;
    this.getAllCanBeImpersonatedUser();
  }

  public rowChange(eventData) {
    if (eventData.selectedRows && eventData.selectedRows.length > 0) {
      const selectedItem = eventData.selectedRows[0].dataItem;
      if (selectedItem !== null) {
        this.impersonateUserSelectedItem.ImpersonateFirstName = selectedItem.firstName,
          this.impersonateUserSelectedItem.ImpersonateUserCompanyCode = selectedItem.companyCode,
          this.impersonateUserSelectedItem.ImpersonateUserEmail = selectedItem.email,
          this.impersonateUserSelectedItem.ImpersonateUserId = selectedItem.id,
          this.impersonateUserSelectedItem.ImpersonateLastName = selectedItem.lastName,
          this.impersonateUserSelectedItem.ImpersonatePreferredLanguage = selectedItem.preferredLanguage,
          this.impersonateUserSelectedItem.ImpersonatePreferredLanguageId = selectedItem.preferredLanguageId,
          this.impersonateUserSelectedItem.ImpersonateUserRoleId = selectedItem.roleId,
          this.impersonateUserSelectedItem.ImpersonateUserRole = selectedItem.roleName,
          this.impersonateUserSelectedItem.ImpersonateRoleDisplayText = selectedItem.customRoleDisplayText,
          this.impersonateUserSelectedItem.ImpersonateUserSolomonId = selectedItem.solomonId,
          this.impersonateUserSelectedItem.ImpersonateUserStatecode = selectedItem.statecode,
          this.impersonateUserSelectedItem.ImpersonateUserStateId = selectedItem.stateId;
        this.impersonateUserSelectedItem.ImpersonateUserStatusId = selectedItem.userStatusId;
      }

    }
  }

  public findIndexWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (String(array[i][attr]) === String(value)) {
        return i;
      }
    }
    return -1;
  }

  public languageChange() {
    this.languageText = this.common.languageChange();
  }
  public setSelectedMenu(index: number) {
    this.selectedMenuIndex = index;
    switch (index) {
      case 1:
        if (AppConstants.showErrorPage) {
          this.router.navigateByUrl('error');
        } else {
          const navigateToUrl = CurrentUser.details.impersonateUserId === 0 ? CurrentUser.details.userAccessRight.routeUrl
            : this.sessionSvc.impersonatedUserRouteUrl;
          this.router.navigateByUrl(navigateToUrl);
        }
        break;
      case 2:
        this.router.navigate(['/user/faq']);
        break;
      case 3:
        this.router.navigate(['/user/contact']);
        break;
      default:
        break;
    }
  }

  public setPreferredLanguage() {
    let preferredLang = sessionStorage.getItem(StorageConstants.selectedlanguage);
    if (!preferredLang) {
      preferredLang = AppConstants.defaultLanguage;
    }

    switch (preferredLang) {
      case locales[0].code:
        const languageModelEn = locales[0];
        this.languageChangeService.use(languageModelEn.code);
        sessionStorage.setItem(StorageConstants.selectedlanguage, languageModelEn.code);
        this.languageText = locales[1].text;
        break;
      case locales[1].code:
        const languageModelEs = locales[1];
        this.languageChangeService.use(languageModelEs.code);
        sessionStorage.setItem(StorageConstants.selectedlanguage, languageModelEs.code);
        this.languageText = locales[0].text;
        AppConstants.isEsPreferredLang = true;
        break;
      default:
        break;
    }
  }

  public resetImpersonatedUserAll() {
    this.sortImpersonateGridAll = [{ field: 'solomonId', dir: 'asc' }];
    this.filterImepersonateGridAll = { logic: 'or', filters: [] };
    this.skipImpersonateGridAll = 0;
  }

  public setImpersonateRequestHeaderDefaultValue(): void {
    this.pageNumber = KendoMasterGridProperties.pageNumber;
    this.pageSize = KendoMasterGridProperties.pageSize;
    this.skipImpersonateGridAll = KendoMasterGridProperties.skip;
    this.loading = KendoMasterGridProperties.loading;
    this.filterColumn = undefined;
    this.filterValue = undefined;
    this.sortColumn = 'solomonId';
    this.sortDirection = 'asc';
  }

  public toggleImpersonateUsersPopup(open: boolean) {
    if (!this.common.checkUserAccess(this.canGetAllCanBeImpersonatedUser)) {
      return false;
    }
    this.showImpersonateUserPopup = open;
    if (open) {
      this.ImpersonateGridAllValue = [];
      this.resetImpersonatedUserAll();
      this.setImpersonateRequestHeaderDefaultValue();
      this.getAllCanBeImpersonatedUser();
    } else {
      this.selectedrow = [];
      this.impersonateUserSelectedItem = {};
    }
  }

  public setImpersonateRequestHeaderValue() {
    this.ImpersonateUserModelSearchRequest = {};
    this.ImpersonateUserModelSearchRequest.sortColumn = this.sortColumn;
    this.ImpersonateUserModelSearchRequest.sortDirection = this.sortDirection;
    this.ImpersonateUserModelSearchRequest.filterColumn = this.filterColumn;
    this.ImpersonateUserModelSearchRequest.filterValue = this.filterValue;
    this.ImpersonateUserModelSearchRequest.pageNumber = this.pageNumber;
    this.ImpersonateUserModelSearchRequest.pageSize = KendoMasterGridProperties.pageSize;
  }

  public getAllCanBeImpersonatedUser() {
    this.loadingImpersonateUserGridAll = true;
    this.setImpersonateRequestHeaderValue();
    this.portalUserService.getAllCanBeImpersonatedUser(this.ImpersonateUserModelSearchRequest).subscribe((result) => {
      this.loadingImpersonateUserGridAll = false;
      this.ImpersonateGridAll = {
        data: result.impersonateUsers,
        total: result.totalRecordCount
      };
      this.showSelectAllBtn = result.totalRecordCount > 0;
      this.loading = false;
    },
      () => {
        this.loadingImpersonateUserGridAll = false;
        this.toaster.error(AppConstants.httpErrorMessage);
        this.loading = false;
      });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.loading = true;
    if (filter.filters.length > 1) {
      this.toaster.error(AppConstants.selectOnlyOneColumnToFilter);
      this.filterImepersonateGridAll = { filters: [this.filterImepersonateGridAll.filters[0]], logic: 'and' } as CompositeFilterDescriptor;
      this.loadingImpersonateUserGridAll = false;
      this.loading = false;
    } else {
      this.filterImepersonateGridAll = filter;
      this.setImpersonateRequestHeaderDefaultValue();
      if (this.filterImepersonateGridAll.filters.length === 1) {
        const filterColumnNameColumnValue = KendoMasterGridProperties.getFilterColumnNameColumnValue(this.filterImepersonateGridAll);
        this.filterColumn = filterColumnNameColumnValue.columnName;
        this.filterValue = filterColumnNameColumnValue.columnValue;
      } else {
        this.filterColumn = undefined;
        this.filterValue = undefined;
      }
      this.getAllCanBeImpersonatedUser();
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.loading = true;
    this.sortImpersonateGridAll = sort;
    this.sortColumn = sort[0].dir !== undefined ? sort[0].field : undefined;
    this.sortDirection = sort[0].dir;
    this.getAllCanBeImpersonatedUser();
  }

  public impersonateSelectedUser() {
    if (!this.common.checkUserAccess(this.canImpersonateAccess)) {
      return false;
    }
    if (JSON.stringify(this.impersonateUserSelectedItem) === '{}') {
      this.toaster.error(AppConstants.selectUserToImpersonate);
      return false;
    }
    this.impersonateUserSelectedItem.isImpersonating = true;
    this.portalUserService.impersonatedSelectedUser(this.impersonateUserSelectedItem).subscribe((result: any) => {
      if (result !== null) {
        CurrentUser.details.impersonateFirstName = this.impersonateUserSelectedItem.ImpersonateFirstName;
        CurrentUser.details.impersonateUserCompanyCode = this.impersonateUserSelectedItem.ImpersonateUserCompanyCode;
        CurrentUser.details.impersonateUserEmail = this.impersonateUserSelectedItem.ImpersonateUserEmail;
        CurrentUser.details.impersonateUserId = this.impersonateUserSelectedItem.ImpersonateUserId;
        CurrentUser.details.impersonateLastName = this.impersonateUserSelectedItem.ImpersonateLastName;
        CurrentUser.details.impersonatePreferredLanguage = this.impersonateUserSelectedItem.ImpersonatePreferredLanguage;
        CurrentUser.details.impersonatePreferredLanguageId = this.impersonateUserSelectedItem.ImpersonatePreferredLanguageId;
        CurrentUser.details.impersonateUserRoleId = this.impersonateUserSelectedItem.ImpersonateUserRoleId;
        CurrentUser.details.impersonateUserRole = this.impersonateUserSelectedItem.ImpersonateUserRole;
        CurrentUser.details.impersonateRoleDisplayText = this.impersonateUserSelectedItem.ImpersonateRoleDisplayText;
        CurrentUser.details.impersonateUserSolomonId = this.impersonateUserSelectedItem.ImpersonateUserSolomonId;
        CurrentUser.details.impersonateUserStateCode = this.impersonateUserSelectedItem.ImpersonateUserStatecode;
        CurrentUser.details.impersonateUserStateId = this.impersonateUserSelectedItem.ImpersonateUserStateId;
        CurrentUser.details.impersonateUserStatusId = this.impersonateUserSelectedItem.ImpersonateUserStatusId;
        this.sessionSvc.setImpersonatedUserUrl(this.appConstants.impersonatedUserRouteUrl, result.routeUrl);
        window.location.href = window.location.origin;
      }
    });
  }

  public backToMyProfile() {
    this.appConstants.showLoading = true;
    this.impersonateUserSelectedItem.isImpersonating = false;
    sessionStorage.removeItem(StorageConstants.ImpersonateSession);
    const currentLang = sessionStorage.getItem(StorageConstants.selectedlanguage);
    if (currentLang === locales[1].code) {
      this.languageChange();
    }
    this.portalUserService.impersonatedSelectedUser(this.impersonateUserSelectedItem).subscribe((result: any) => {
      if (result.routeUrl == null) {
        this.appConstants.showLoading = false;
        window.location.href = window.location.origin;
      } else {
        this.appConstants.showLoading = false;
      }
    });
  }

  public redirectToProfile() {
    if (!CurrentUser.details || !CurrentUser.details.roleName) {
      return false;
    }

    if (!this.common.checkUserAccess(this.canGetProfileDetails)) {
      return false;
    }
    if ((!(this.loggedInUser.isInternalUser)) || this.loggedInUser.details.impersonateUserId > 0) {
      const email = CurrentUser.details.impersonateUserId > 0 ? CurrentUser.details.impersonateUserEmail :
        CurrentUser.details.email;
      this.router.navigateByUrl('/user/myProfile/' + email);
    }
  }


  public setUserAccess() {
    this.canGetProfileDetails = this.portalUserService.checkUserAccessRights(AccessRightsKeys.GetProfileDetails);
    this.canGetAllCanBeImpersonatedUser = this.portalUserService.checkUserAccessRights(AccessRightsKeys.GetAllCanBeImpersonatedUser);
    this.canImpersonateAccess = this.portalUserService.checkUserAccessRights(AccessRightsKeys.ImpersonateUser);
  }

  public gotoHomePage() {
    // eslint-disable-next-line max-len
    if (this.loggedInUser && this.loggedInUser.details && this.loggedInUser.details.userAccessRight && this.loggedInUser.details.impersonateUserId === 0) {
      const routeVal = this.loggedInUser.details.userAccessRight.routeUrl;
      this.router.navigateByUrl(routeVal);
    }
  }

  public async changeRole(roleId: number) {
    if (roleId !== this.loggedInUser.details.roleId && this.loggedInUser.details.impersonateUserId === 0) {
      await this.portalUserService.InitiateUserSession(true, roleId);
      this.setUserAccess();
    }
  }

  private impersonateOnRoleChange() {
    this.impersonateUserSelectedItem.ImpersonateFirstName = CurrentUser.details.impersonateFirstName;
    this.impersonateUserSelectedItem.ImpersonateUserCompanyCode = CurrentUser.details.impersonateUserCompanyCode;
    this.impersonateUserSelectedItem.ImpersonateUserEmail = CurrentUser.details.impersonateUserEmail;
    this.impersonateUserSelectedItem.ImpersonateUserId = CurrentUser.details.impersonateUserId;
    this.impersonateUserSelectedItem.ImpersonateLastName = CurrentUser.details.impersonateLastName;
    this.impersonateUserSelectedItem.ImpersonatePreferredLanguage = CurrentUser.details.impersonatePreferredLanguage;
    this.impersonateUserSelectedItem.ImpersonatePreferredLanguageId = CurrentUser.details.impersonatePreferredLanguageId;
    this.impersonateUserSelectedItem.ImpersonateUserRole = CurrentUser.details.impersonateUserRole;
    this.impersonateUserSelectedItem.ImpersonateRoleDisplayText = CurrentUser.details.impersonateRoleDisplayText;
    this.impersonateUserSelectedItem.ImpersonateUserSolomonId = CurrentUser.details.impersonateUserSolomonId;
    this.impersonateUserSelectedItem.ImpersonateUserStatecode = CurrentUser.details.impersonateUserStateCode;
    this.impersonateUserSelectedItem.ImpersonateUserStateId = CurrentUser.details.impersonateUserStatusId;
    this.impersonateUserSelectedItem.ImpersonateUserStatusId = CurrentUser.details.impersonateUserStatusId;
  }

  public updatePrimaryRole() {
    if (!this.primaryRole) {
      this.primaryRoleRequired = true;
      return;
    }
    AppConstants.showLoading = true;
    this.primaryRoleRequired = false;
    this.portalUserService.setPrimaryRole(
      { roleId: this.primaryRole, userId: this.loggedInUser.details.id })
      .subscribe(async (result) => {
        if (result) {
          this.setPrimaryRole = false;
          this.primaryRoleSet = true;
        }
        AppConstants.showLoading = false;
      }, error => {
        AppConstants.showLoading = false;
        this.toaster.error('Error occured while saving primary role. Please try again.');
      });
  }

  openSetPrimaryPopup() {
    this.primaryRole = undefined;
    const primary = this.loggedInUser.details.allRoles.find(x => x.isPrimary);
    if (primary) {
      this.primaryRole = primary.roleId;
    }
    this.setPrimaryRole = true;
  }

  changePrimary(roleId: number) {
    this.primaryRole = roleId;
  }

  togglePrimaryRolePopup() {
    if (this.loggedInUser.details.roleText) {
      this.setPrimaryRole = false;
    }
  }

  enablePrimaryRolePopup() {
    this.multiRoleMsg = false;
    this.setPrimaryRole = true;
  }

  public async initiateUserSession() {
    this.primaryRoleSet = false;
    await this.portalUserService.InitiateUserSession(true, this.primaryRole);
    this.setUserAccess();
  }

  public endImpersonation() {
    window.close();
  }

  debugChangeEnvironmentStyle(event) {
    if (!event.shiftKey || environment.envName === 'production') { return; }
    const names = ['dev', 'qa', 'pre-qa', 'performance', 'perf2', 'integration', 'migration', 'alpha', 'uat', 'pre-prod', 'production'];
    let index = names.indexOf(this.environment) + 1;
    if (!names[index]) { index = 0; }
    this.environment = names[index];
  }

  ngDoCheck(): void {
    if (!this.isApiCalled) {
      this.getAllAttestationData();
    }
  }

  getAllAttestationData() {
    if (this.currentUserData && this.currentUserData.roleId === UserRole.Caregiver) {
      this.pageLoading = true;
      this.isCaregiverRelEnabled = this.featureStateService.isCaregiverRelValid();
      this.isCaregiverDocEnabled = this.featureStateService.isCaregiverDocValid();
      this.isCaregiverFicaEnabled = this.featureStateService.isCaregiverFicaValid();
      this.isCaregiverDemographicsEnabled = this.featureStateService.isCaregiverDemographicsValid();
      this.isCaregiverTrainingEnabled = this.featureStateService.isCaregiverTrainingValid();
      this.isCaregiverBackgroundCheckEnabled = this.featureStateService.isCaregiverBackgroundCheckValid();
      if (this.isCaregiverRelEnabled || this.isCaregiverDocEnabled ||
      this.isCaregiverFicaEnabled || this.isCaregiverDemographicsEnabled ||
      this.isCaregiverTrainingEnabled || this.isCaregiverBackgroundCheckEnabled) {
        if (!this.currentUserData.solomonId || this.currentUserData.solomonId.length === 0 || this.currentUserData.solomonId === 'null') {
          this.caregiverId = this.common.getSolomonId();
        } else {
          this.caregiverId = this.currentUserData.solomonId;
        }
        this.isImpersonate = this.currentUserData.impersonateUserId > 0 ? true : false;
        this.isApiCalled = true;
        AppConstants.showLoading = true;
        this.pageLoading = true;
        this.AttestationServiceApi.getCaregiverAttestationStatusDetails(this.caregiverId)
        .subscribe((res: CaregiverAttestationStatusDetailsDto) => {
          this.attestationData = res;
          this.noOfParticipants = this.attestationData.caregiverParticipantRelAttestationDetails.length;
          if (this.isCaregiverDemographicsEnabled) {
            if (this.attestationData.caregiverAttestationDetails.demographicStatus === AttestationStatusEnum.Pending) {
              this.showEmpInfo = false;
              this.isdemograpRejected = false;
              this.showCaregiverModel = true;
            } else {
              if (this.attestationData.caregiverAttestationDetails.demographicStatus === AttestationStatusEnum.CaregiverRejected) {
                this.showEmpInfo = true;
                this.isdemograpRejected = true;
                this.showCaregiverModel = true;
              } else {
                if (this.attestationData.caregiverAttestationDetails.demographicStatus === AttestationStatusEnum.Approved) {
                    this.checkQualifications();
                }
              }
            }
          } else {
            this.checkQualifications();
          }
          this.pageLoading = false;
          AppConstants.showLoading = false;
        }, error => {
          this.toaster.error(error);
          this.pageLoading = false;
          AppConstants.showLoading = false;
        });
      } else {
        this.pageLoading = false;
      }
    } else {
      this.cancelLoader();
    }
  }

  private cancelLoader() {
    this.isApiCalled = true;
    this.pageLoading = false;
    AppConstants.showLoading = false;
  }

  confirmModalchange() {
    this.showConfirmModal = true;
  }

  confirmModalClick(eventdata) {
    if (eventdata) {
      AppConstants.showLoading = true;
      this.AttestationServiceApi.demographicAttestationReject(this.caregiverId).subscribe(res => {
        this.showCaregiverModel = false;
        AppConstants.showLoading = false;
        this.signOutFromApp();
      }, error => {
        this.toaster.error(error);
      });
    } else {
      this.showConfirmModal = false;
    }
  }

  demoGraphicscontinue() {
    AppConstants.showLoading = true;
    this.AttestationServiceApi.demographicAttestationVerify(this.caregiverId).subscribe(res => {
      this.showCaregiverModel = false;
      this.checkQualifications();
      AppConstants.showLoading = false;
    }, error => {
      this.toaster.error(error);
    });
  }

  checkQualifications() {
    if (this.isCaregiverTrainingEnabled || this.isCaregiverBackgroundCheckEnabled) {
      if (this.attestationData.caregiverAttestationDetails.backgroundCheckStatus === AttestationStatusEnum.Pending ||
        this.attestationData.caregiverAttestationDetails.trainingStatus === AttestationStatusEnum.Pending) {
        if (this.isCaregiverBackgroundCheckEnabled &&
          this.attestationData.caregiverAttestationDetails.backgroundCheckStatus === AttestationStatusEnum.Pending) {
          this.showQualificationCheck = true;
          this.showQualificationGrid = true;
        }
        if (this.isCaregiverTrainingEnabled &&
          this.attestationData.caregiverAttestationDetails.trainingStatus === AttestationStatusEnum.Pending) {
          this.showQualificationCheck = true;
          this.showTrainingnGrid = true;
          this.showTrainingText = true;
          this.showAttestationList = true;
        }
        if (!this.showQualificationGrid && !this.showTrainingnGrid) {
          this.goToCaregiverRel();
        }
      } else {
        this.showQualificationCheck = false;
        this.goToCaregiverRel();
      }
    } else {
      this.showQualificationCheck = false;
      this.goToCaregiverRel();
    }
  }

  goToCaregiverRel() {
    this.currentParticipantData = undefined;
    this.showQualificationCheck = false;
    if (this.currentLoop < this.noOfParticipants) {
      if (this.isCaregiverRelEnabled) {
        if (this.attestationData.caregiverParticipantRelAttestationDetails[this.currentLoop].relationshipConfirmation ===
          AttestationStatusEnum.Pending) {
            this.currentParticipantData = this.attestationData.caregiverParticipantRelAttestationDetails[this.currentLoop];
            this.showCgRelCheck = true;
        } else {
          if (this.attestationData.caregiverParticipantRelAttestationDetails[this.currentLoop].relationshipConfirmation ===
            AttestationStatusEnum.CaregiverRejected) {
              this.showCgRelCheck = false;
              this.goToCompleteAttestation();
          } else {
            this.goToDocAttestation();
          }
        }
      } else {
        this.goToDocAttestation();
      }
    } else {
      if (!this.attestationData.employeeOkToWork) {
          this.showWorkDayCheck = true;
      } else {
        this.commonService.isAttestationCompleted(true);
      }
    }
  }

  goToDocAttestation() {
    this.currentParticipantData = undefined;
    this.showCgRelCheck = false;
    if (this.isCaregiverDocEnabled) {
      if (this.attestationData.caregiverParticipantRelAttestationDetails[this.currentLoop].docConfirmation ===
        AttestationStatusEnum.Pending) {
          this.currentParticipantData = this.attestationData.caregiverParticipantRelAttestationDetails[this.currentLoop];
          this.showCgDocCheck = true;
      } else {
        this.goToFicaAttestation();
      }
    } else {
      this.goToFicaAttestation();
    }
  }

  goToFicaAttestation() {
    this.currentParticipantData = undefined;
    this.showCgDocCheck = false;
    if (this.isCaregiverFicaEnabled) {
      if (this.attestationData.caregiverParticipantRelAttestationDetails[this.currentLoop].ficaConfirmation ===
        AttestationStatusEnum.Pending) {
          this.currentParticipantData = this.attestationData.caregiverParticipantRelAttestationDetails[this.currentLoop];
          this.showCgFicaCheck = true;
      } else {
        this.goToCompleteAttestation();
      }
    } else {
      this.goToCompleteAttestation();
    }
  }

goToNextParticipant() {
  this.currentParticipantData = undefined;
  this.showCgRelCheck = false;
  this.showCgDocCheck = false;
  this.showCgFicaCheck = false;
  this.currentLoop = this.currentLoop + 1;
  this.ngOnInit();
}

  goToCompleteAttestation() {
    this.currentParticipantData = undefined;
    this.showCgRelCheck = false;
    this.showCgDocCheck = false;
    this.showCgFicaCheck = false;
    this.currentLoop = this.currentLoop + 1;
    this.goToCaregiverRel();
  }
}

export class StorageConstants {
    public static userType = 'usertype';
    public static externalUserValue = 'externaluser';
    public static internalUserValue = 'internaluser';
    public static sessionId = 'sessionId';
    public static userDetailsNotifications = 'userDetailsNotifications';
    public static msalIdToken = 'msal.idtoken';
    public static selectedlanguage = 'selectedlanguage';
    public static selectedGrids = 'selectedGrids';
    public static evvAddressValidation = 'evvAddressValidation';
    public static currentCellDate = 'currentCellDate';
    public static participantUserRole = 'Participant';
    public static careGiverUserRole = 'Caregiver';
    public static supportBrokerUserRole = 'SupportBroker';
    public static ProgramCoordinatorRole = 'PortalProgramCoordinator';
    public static userRegistration = 'userRegistrationSuccess';
    public static userRegistrationStatus = '1';
    public static userLoginStatus = 'userLoginStatus';
    public static userState = 'userState';
    public static LoginUserDetails = 'LoginUserDetails';
    public static evvStates = 'evvStates';
    public static ImpersonateSession = 'ImpersonateSession';
    public static states = 'states';
    public static MonthlyHoursAllocation = 'MonthlyHoursAllocation';
    public static ExceededWWL = 'ExceededWWL';
    public static CaregiverParticipantRel = 'CaregiverParticipantRel';
    public static CaregiverCoordinatorRel = 'CaregiverCoordinatorRel';
    public static OverBudgetUnits = 'OverBudgetUnits';
    public static OverInternalAuth = 'OverInternalAuth';
    public static RangeOverBudgetUnits = 'RangeOverBudgetUnits';
    public static OkToWork = 'OkToWork';
    public static Exceeded24Hours = 'Exceeded24Hours';
    public static MonthlyUnitsAllocation = 'MonthlyUnitsAllocation';
    public static ApprovalResponse = 'ApprovalResponse';
    public static prosProvID = 'prosProvID';
    public static prosAge = 'prosAge';
    public static IsImpersonateSessionDetailsSet = 'false';
}

<main class="page-wrap">
  <app-logo-header *ngIf="!loggedInUser.details && !appConstants.showErrorPage && appConstants.headerLoading"></app-logo-header>
  <div class="page-header" [ngClass]="{'page-header-impersonation':loggedInUser.details && loggedInUser.details.impersonateUserId>0}">
    <app-main-header *ngIf="loggedInUser.details || appConstants.showErrorPage"></app-main-header>
  </div>
  <div class="page-component">
    <router-outlet></router-outlet>
  </div>
</main>

<kendo-dialog title="Loading" *ngIf="appConstants.showLoading" class="loading-dialog" (close)="close('cancel')">
  <div class="modal-body">
    <section class="container-fluid">
      <div>
        <div class="margin-b-30">
          <div class="sk-fading-circle">
            <div class="sk-circle1 sk-circle"></div>
            <div class="sk-circle2 sk-circle"></div>
            <div class="sk-circle3 sk-circle"></div>
            <div class="sk-circle4 sk-circle"></div>
            <div class="sk-circle5 sk-circle"></div>
            <div class="sk-circle6 sk-circle"></div>
            <div class="sk-circle7 sk-circle"></div>
            <div class="sk-circle8 sk-circle"></div>
            <div class="sk-circle9 sk-circle"></div>
            <div class="sk-circle10 sk-circle"></div>
            <div class="sk-circle11 sk-circle"></div>
            <div class="sk-circle12 sk-circle"></div>
          </div>
        </div>
      </div>

    </section>
  </div>
</kendo-dialog>

<footer class="site-footer">
  <div id=”montanap2w”></div>
  Copyright © {{copyrightYear}} Consumer Direct. All rights reserved.
</footer>

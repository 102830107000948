<main class="page-wrap">
    <section class="container-fluid padding-t-20 user-profile  page-component-scroll">
        <form name="frmUserProfile">
            <div>
                <div>
                    <h1>{{userData.fullName}}</h1>
                </div>

                <span class="pull-right">
                    <button class="btn btn-primary btn-lg" *ngIf="isParticipant" (click)="getSpendingSummaryReport()"
                        data-i18n="label_summary_reports">{{'label_summary_reports'|langchange}}</button>
                    <button class="btn btn-primary btn-lg" *ngIf="isParticipant" (click)="getSpendingDetailReport()"
                        data-i18n="label_detail_reports">{{'label_detail_reports'|langchange}}</button>
                </span>
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-sm-4 col-xs-8">
                        <div class="margin-b-15">
                            <select name="dplUserStatus" id="dplUserStatus" [disabled]="loggedUserDisable"
                                [(ngModel)]="userStatusOptions?.selectedOption.id"
                                (change)="onuserStatusOptionChanged(userStatusOptions.selectedOption.id)">
                                <option value="0">Please Select</option>
                                <option *ngFor="let data of userStatusOptions.availableOptions" [value]="data.id">
                                    {{data.statusDisplayText}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="h-line margin-b-30">
                </div>
                <div class="form-controls-global">
                    <div class="row">
                        <div [ngClass]="(isUserOnboarding)?'col-lg-offset-3 col-lg-6 col-md-offset-3 col-md-6 col-sm-offset-3 col-sm-6 col-xs-12':'col-lg-4 col-md-6 col-sm-6 col-xs-12'">
                          <div class="col-container three-column-bg h-1140">
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_FirstName'|langchange}}*</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="firstName" [(ngModel)]="userData.firstName"
                                       class="textbox" [disabled]="disableB2CEdit" />
                              </div>
                            </div>

                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_MiddleName'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="middleName" [(ngModel)]="userData.middleName"
                                       class="textbox" [disabled]="disableB2CEdit" />
                              </div>
                            </div>

                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_LastName'|langchange}}*</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="lastName" [(ngModel)]="userData.lastName"
                                       class="textbox" [disabled]="disableB2CEdit" />
                              </div>
                            </div>

                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_suffix'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="suffix" [(ngModel)]="userData.suffix"
                                       class="textbox" [disabled]="disableB2CEdit" />
                              </div>
                            </div>

                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'employee_dob'|langchange}}*</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="dob" [(ngModel)]="userData.dob"
                                       class="textbox" [disabled]="disableB2CEdit" />
                              </div>
                            </div>



                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_EmailID'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="email" [(ngModel)]="userData.email"
                                       class="textbox" [disabled]="true" />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_last_digits_ssn'|langchange}}*</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="ssn" [(ngModel)]="userData.ssn"
                                       class="textbox" [disabled]="disableB2CEdit" maxlength=4 />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_Role'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="roleName" [(ngModel)]="userData.customRoleDisplayText"
                                       class="textbox" [disabled]="true" />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_state_of_residence'|langchange}}*</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <select name="dplStateOfResidence" id="dplStateOfResidence"
                                        [(ngModel)]="stateOfResidenceOptions?.selectedOption.id"
                                        (change)="onstateOfResidenceOptionsChange(stateOfResidenceOptions.selectedOption.id)">
                                  <option value="0">{{'please_select'|langchange}}</option>
                                  <option *ngFor="let data of stateOfResidenceOptions.availableOptions"
                                          [value]="data.id">
                                    {{data.name}}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_State'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <select name="dplSate" id="dplSate" [disabled]="disableB2CEdit"
                                        [(ngModel)]="stateOptions?.selectedOption.id">
                                  <option value="0">{{'please_select'|langchange}}</option>
                                  <option *ngFor="let data of stateOptions.availableOptions"
                                          [value]="data.id">
                                    {{data.name}}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_Address1'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="address1" [(ngModel)]="userData.address1"
                                       class="textbox" [disabled]="pcDisable || disabledInternal" />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_Address2'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="address2" [(ngModel)]="userData.address2"
                                       class="textbox" [disabled]="pcDisable || disabledInternal" />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_City'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="city" [(ngModel)]="userData.city" class="textbox"
                                       [disabled]="pcDisable || disabledInternal" />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_ZIP'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="zip" [(ngModel)]="userData.zip" class="textbox"
                                       maxlength="5" [disabled]="pcDisable || disabledInternal" />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_LandPhone'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="landPhone" [(ngModel)]="userData.landPhone"
                                       class="textbox" maxlength="10" [disabled]="pcDisable || disabledInternal" />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_Cellphone'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <input type="text" name="cellphone" [(ngModel)]="userData.cellphone"
                                       class="textbox" maxlength="10" [disabled]="pcDisable || disabledInternal" />
                              </div>
                            </div>
                            <div class="form-row-container">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <span>{{'label_PreferredLanguage'|langchange}}</span>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <select name="dplLang" id="dplLang" [disabled]="pcDisable || disabledInternal"
                                        [(ngModel)]="languageOptions?.selectedOption.id"
                                        (ngModelChange)="languageOptionsChanged($event)">
                                  <option value="0">{{'please_select'|langchange}}</option>
                                  <option *ngFor="let data of languageOptions.availableOptions"
                                          [value]="data.id">
                                    {{data.name}}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12" *ngIf="!isUserOnboarding">
                            <div class="col-container three-column-bg h-1140">
                                <div class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>
                                            Company
                                        </span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <select name="dplcompany" id="dplcompany" [disabled]="canDisableCompany || disabledInternal"
                                            [(ngModel)]="companiesOptions?.selectedOptn.id"
                                            (ngModelChange)="oncompaniesOptionsChange(companiesOptions.selectedOptn.id)">
                                            <option value="0">Please Select</option>
                                            <option *ngFor="let data of companiesOptions?.availableOption"
                                                [value]="data.id">
                                                {{data.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>Person ID</span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <div class="pos-rel">
                                            <div class="pos-rel">
                                                <input type="text" name="solomon" placeholder="Search"
                                                    class="textbox padding-r-30 form-control" [ngbTypeahead]="search"
                                                    [(ngModel)]="solomonIdModel"  [disabled]="canDisableSolomonId || disabledInternal"
                                                    [resultFormatter]="resultFormatBandListValue" [inputFormatter]="inputFormatBandListValue"
                                                    (selectItem)="changeSolomonId($event)" [resultTemplate]="rt"/>
                                            </div>
                                            <div *ngIf="companyMissing" class="cross-validation-error-message">
                                                Please choose company
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>
                                            Program
                                        </span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <select name="dplProgram" id="dplProgram" [disabled]="otherDisable"
                                            [(ngModel)]="programsOptions?.selectedOption.id"
                                            (ngModelChange)="onprogramsOptionsChanged(programsOptions.selectedOption.id)">
                                            <option value="0">Please Select</option>
                                            <option *ngFor="let data of programsOptions.availableOptions"
                                                [value]="data.id">
                                                {{data.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>
                                            Attestation Form
                                        </span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <select name="dplAttestation" id="dplAttestation" [disabled]="otherDisable"
                                            [(ngModel)]="attestationTemplateOptions?.selectedOption.id"
                                            (ngModelChange)="attestationTemplateOptionsChanged(attestationTemplateOptions.selectedOption.id)">
                                            <option value="0">Please Select</option>
                                            <option *ngFor="let data of attestationTemplateOptions.availableOptions"
                                                [value]="data.id">
                                                {{data.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>
                                            Ex. Case Manager
                                        </span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 divscroll">
                                        <div class="grid-action-wrap grid-action-wrap-kendsomulti scrollmultiselect">
                                            <div class="col multi-select-wrap1">
                                                <kendo-multiselect #multiselect name="dplCaseManager"
                                                    [data]="caseManagerOptions.availableOptions"
                                                    [(ngModel)]="caseManagerOptions.selectedOption"
                                                    [textField]="'fullName'" [valueField]="'id'"
                                                    [disabled]="careGiverDisable"
                                                    (ngModelChange)="caseManagerOptionsChanged($event)"
                                                    (close)="onClose($event)">
                                                </kendo-multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>
                                            Designated Representative
                                        </span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <select name="dplDRUser" id="dplDRUser" [disabled]="careGiverDisable"
                                            [(ngModel)]="drUsersOptions?.selectedOption.id"
                                            (ngModelChange)="DRUserOptionsChanged($event)">
                                            <option value="0">Please Select</option>
                                            <option *ngFor="let data of drUsersOptions.availableOptions"
                                                [value]="data.id">
                                                {{data.fullName}} - {{data.email}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>User Created Date</span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <span class="t-a-l">{{created}}</span>
                                    </div>
                                </div>
                                <div *ngIf="registrationCompleted" class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>Registration Completed Date</span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <span class="t-a-l">{{registrationCompleteDate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6" *ngIf="!isUserOnboarding">
                            <div class="col-container three-column-bg column3 h-1140">
                                <div class="form-row-container">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <span>Shift Comment Required</span>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                        <div class="task-req">
                                            <label class="radio-inline"><input type="radio"
                                                    name="isShiftCommentRequired"
                                                    [(ngModel)]="userData.isShiftCommentRequired" [value]="true"
                                                    [checked]="userData.isShiftCommentRequired">Yes</label>
                                            <label class="radio-inline"><input type="radio"
                                                    name="isShiftCommentRequired"
                                                    [(ngModel)]="userData.isShiftCommentRequired" [value]="false"
                                                    [checked]="!userData.isShiftCommentRequired">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="pull-right">

                            <span>
                                <a class="btn btn-default btn-lg" type="button" href="#/{{redirectUrl}}"
                                    data-i18n="button_Cancel">{{'button_Cancel'|langchange}}</a>
                                <button class="btn btn-primary btn-lg" type="button" (click)="updateUser()"
                                    data-i18n="button_Update">{{'button_Update'|langchange}}</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <br />
        </form>
        <app-modal-dialog *ngIf="showReportsPopup" [dialogType]="dialogType"></app-modal-dialog>
        <kendo-dialog *ngIf="showSignout" class="custom-dialog session-timeout" title="{{'notification_information' | langchange}}" [width]="400">
            <p class="session-timeout-txt">{{'notification_msg_useronboard_signout' | langchange }}</p>
            <kendo-dialog-actions>
              <button kendoButton class="btn-green"  (click)="goBackToStatus()" primary="true">{{'button_Ok' | langchange}}</button>
            </kendo-dialog-actions>
          </kendo-dialog>
    </section>
</main>

import { PortalUserService } from 'src/app/core/services/portalUser.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../../core/services/common.service';
import { ApiURLConstants } from '../../../core/apiurlconstants';
import { CommonFunctionsService } from '../../../core/services/common-functions.service';
import { FeatureStateService } from 'src/app/core/services/featureStateService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'notification-count',
  templateUrl: './notification-count.component.html',
  styleUrls: ['./notification-count.component.scss']
})
export class NotificationCountComponent implements OnInit, OnChanges {

  constructor(
    private httpService: HttpClient, private router: Router, public portalUserService: PortalUserService,
    private commonService: CommonService, public commonFunctionsService: CommonFunctionsService,
    private featureStateService: FeatureStateService
  ) { }

  @Input()
  canshow = false;
  notReadNotifications;

  ngOnInit() {
    if (this.featureStateService.isNotificationEnabled()) {
      this.commonService.currentCount.subscribe(res => this.getUnreadCount());
      this.getUnreadCount();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.featureStateService.isNotificationEnabled()) {
      this.commonService.currentCount.subscribe(res => this.getUnreadCount());
      this.getUnreadCount();
    }
  }

  openMyNotification() {
    this.router.navigateByUrl('/user/notification');
  }

  public getUnreadCount() {
    this.httpService.get(ApiURLConstants.getNotificationUnReadCntUrl + '/' + this.commonFunctionsService.getSolomonId())
      .toPromise()
      .then(result => {
        if (result) {
          this.notReadNotifications = result;
        } else {
          this.notReadNotifications = 0;
        }
      });
  }

  public goToNotificationsmessages() {
    this.router.navigateByUrl('user/notificationMessages/view');
  }
}

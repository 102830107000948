<section class="container-fluid">
  <!--<div class="text-center padding-t-15" ng-hide="isDataLoaded">
      <i class="fa fa-spinner fa-spin fa-3x"></i>
  </div>-->
  <!-- Registration Status -->
  <div class="row cdms-enroll-status" *ngIf="!isImpersonation">
    <div class="col-lg-12 enroll-box">
    <div>
      <h1>{{'label_please_allow' | langchange}}</h1>
    </div>
  </div>
  </div>
  <!-- Blocked Profile -->
      <div class="row cdms-enroll-status" *ngIf="isCurrentStatus(1)">
        <div class="col-lg-3 col-md-3"></div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 enroll-box">
          <div class="">
            <div>
              <span class="fa fa-ban text30"></span>
              <h1 class="enroll-welcome" data-i18n="label_BlockedProfile">{{'label_BlockedProfile' | langchange}}</h1>
            </div>
          </div>
          <div>
            <h1 data-i18n="label_ContactAdmin">{{'label_ContactAdmin' | langchange}}</h1>
          </div>
          <div class="col-lg-2 col-md-2"></div>
        </div>
        <div class="col-lg-3 col-md-3"></div>
      </div>

</section>

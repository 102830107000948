import { environment } from 'src/environments/environment';

export class ApiURLConstants {
    public static readonly SearchUsers = environment.apiBaseUrl + '/api/UserManagement/SearchUsers';
    public static readonly GetUserRoles = environment.apiBaseUrl + '/api/UserManagement/GetUserRoles';
    public static readonly GetStates = environment.apiBaseUrl + '/api/UserManagement/getStates';
    public static readonly RetrieveParticipantsForCaregiver =
        environment.apiBaseUrl + '/api/dashboard/RetrieveParticipantsForCaregiver/{getInactiveRelations}';
    public static readonly RetrievePersonDetailsByPersonId =
        environment.apiBaseUrl + '/api/dashboard/RetrievePersonDetailsByPersonId';
    public static readonly GetUserDetails = environment.apiBaseUrl + '/api/UserManagement/GetUserDetails?roleId=';
    public static readonly InitiateUserSession = environment.apiBaseUrl + '/api/UserManagement/initiateusersession';
    public static readonly getWhiteListUrls = environment.apiBaseUrl + '/api/UserManagement/getwhitelisturls';
    public static readonly userLogout = environment.apiBaseUrl + '/api/UserManagement/logoutuser';
    public static readonly userLoginAudit = environment.apiBaseUrl + '/api/UserManagement/UserLoginAudit';
    public static readonly updateUserSession = environment.apiBaseUrl + '/api/UserManagement/updatesession';
    public static readonly getUser = environment.apiBaseUrl + '/api/UserManagement/getUser?email=';
    public static readonly getAnnouncementsList = environment.apiBaseUrl + '/api/dashboard/getAnnouncementsList';
    public static readonly updateAnnouncement = environment.apiBaseUrl + '/api/dashboard/updateAnnouncement';
    public static readonly saveAnnouncement = environment.apiBaseUrl + '/api/dashboard/saveAnnouncement';
    public static readonly deleteAnnouncements = environment.apiBaseUrl + '/api/dashboard/deleteAnnouncements?ids=';
    public static readonly GetAllUserDocuments = environment.apiBaseUrl + '/api/userdocuments/GetAllUserDocuments';
    public static readonly GetUserDocument = environment.apiBaseUrl + '/api/userdocuments/GetUserDocument';
    public static readonly validateSSN = environment.apiBaseUrl + '/api/UserManagement/validateSSN';

    // Caseload
    public static readonly getAllCaseLoads: string = environment.apiBaseUrl + '/api/caseload/getall';
    public static readonly deleteCaseLoads: string = environment.apiBaseUrl + '/api/caseload/delete';
    public static readonly getCaseLoadById: string = environment.apiBaseUrl + '/api/caseload/getbyid';
    public static readonly createUpdateCaseLoad: string = environment.apiBaseUrl + '/api/caseload/createupdatecaseload';
    public static readonly getAllPartcipantCaseLoad: string = environment.apiBaseUrl + '/api/caseload/getparticipants';
    public static readonly getAllSfCaseLoad: string = environment.apiBaseUrl + '/api/caseload/getsfs';
    public static readonly getAllPartcipantAgency: string = environment.apiBaseUrl + '/api/caseload/getallparticipants';
    // Participants
    public static getAllParticipants: string = environment.apiBaseUrl + '/api/Participant/GetAll';
    public static getParticipantById: string = environment.apiBaseUrl + '/api/Participant/GetParticipantDetailByIdAsync';
    public static getcustEmpRateList: string = environment.apiBaseUrl + '/api/participant/GetCustEmpRateListAsync';
    public static saveAgencyList: string = environment.apiBaseUrl + '/api/Participant/SaveAgencyList';
    public static getMcoParticipantsList: string = environment.apiBaseUrl + '/api/Participant/GetMcoParticipants';
    // Employee
    public static getEmployeeById: string = environment.apiBaseUrl + '/api/Employee/GetEmployeeDetailsById';
    // EA
    public static getEaById: string = environment.apiBaseUrl + '/api/participant/GetEaByIdAsync';
    // Service Facilitator
    public static getAllSf: string = environment.apiBaseUrl + '/api/ServiceFacilitator/GetAll';
    public static getSfById: string = environment.apiBaseUrl + '/api/ServiceFacilitator/GetById';
    public static removeSfsFromAgency = environment.apiBaseUrl + '/api/ServiceFacilitator/RemoveFromAgency';
    // Authorization
    public static getAllAuthsForParticipants: string = environment.apiBaseUrl + '/api/Authorization/GetAll';
    public static getAllAuthsForMco: string = environment.apiBaseUrl + '/api/Authorization/GetAllAuthByMco';
    public static getAllAuthsListForParticipants: string = environment.apiBaseUrl + '/api/Authorization/GetAllAuth';
    public static getAuthByAcn: string = environment.apiBaseUrl + '/api/Authorization/GetByAcn';
    public static getAuthAmountDetails: string = environment.apiBaseUrl + '/api/Authorization/GetAmountDetails';
    public static getServiceDetails: string = environment.apiBaseUrl + '/api/Authorization/GetServeCodeDetails';
    public static getAuthCodeServiceHistory: string = environment.apiBaseUrl + '/api/Authorization/GetServCodeHistory';
    public static getAmountRange: string = environment.apiBaseUrl + '/api/Authorization/GetAmtRange';
    public static getUnitRange: string = environment.apiBaseUrl + '/api/Authorization/GetUnitRange';
    // User
    public static getUserAgencys: string = environment.apiBaseUrl + '/api/participant/GetAgencyList';
    // Shift Summary
    public static getAllShiftAndPayrollForParticipants: string =
        environment.apiBaseUrl + '/api/ShiftAndPayroll/GetAllShiftAndPayrollDetailsForParticipant';

    // Dashboards
    public static readonly RetrieveOutstandingTimeEntries = environment.apiBaseUrl + '/api/dashboard/retrieveOutstandingTimeEntries';
    public static readonly RetrieveMalformedEntries = environment.apiBaseUrl + '/api/dashboard/retrieveMalformedEntries';
    public static readonly getOutstandingTimeEntriesForDRUrl: string =
        environment.apiBaseUrl + '/api/dashboard/getOutstandingTimeEntriesForDR';
    public static readonly getOutstandingMileageEntriesForDRUrl: string =
        environment.apiBaseUrl + '/api/dashboard/getOutstandingMileageEntriesForDR';
    public static readonly getAllParticipantsForDRUrl: string =
        environment.apiBaseUrl + '/api/dashboard/RetrieveAllParticipantsForDR';
    public static readonly getAllCaregiversForDRUrl: string =
        environment.apiBaseUrl + '/api/dashboard/RetrieveAllCaregiversForDR';
    public static readonly validateProgramCoordinatorUrl: string =
        environment.apiBaseUrl + '/api/UserManagement/ValidateProgramCoordinator';
    public static readonly GetAllExternalUsersUrl: string = environment.apiBaseUrl + '/api/dashboard/GetAllExternalUsers';
    public static readonly getSpendingDetailReportUrl: string = environment.apiBaseUrl + '/api/Reports/GetSpendingDetailReport';
    public static readonly getOutStandingMileageEntriesUrl: string =
        environment.apiBaseUrl + '/api/dashboard/retrieveOutstandingMileageEntries';
    public static readonly getSpendingSummaryReportUrl: string =
        environment.apiBaseUrl + '/api/Reports/GetSpendingSummaryReport';
    public static readonly getExternalUserNamesByFilterUrl: string =
        environment.apiBaseUrl + '/api/UserManagement/GetExternalUserNamesByFilter';
    public static readonly getPCParticipantNamesByFilterUrl: string =
        environment.apiBaseUrl + '/api/UserManagement/GetPCParticipantNamesByFilter';
    public static readonly GetParticipantsForProgramCoordinatorUrl: string =
        environment.apiBaseUrl + '/api/dashboard/GetParticipantsForProgramCoordinator';
    public static readonly getAllContactsForParticipantUrl: string =
        environment.apiBaseUrl + '/api/dashboard/retrieveAllContactsForParticipant';
    public static readonly getOutstandingTimeEntriesForParticipantUrl: string =
        environment.apiBaseUrl + '/api/dashboard/getOutstandingTimeEntriesForParticipant';
    public static readonly getOutstandingMileageEntriesForParticipantUrl: string =
        environment.apiBaseUrl + '/api/dashboard/getOutstandingMileageEntriesForParticipant';
    public static readonly getPatientPayDataUrl: string =
        environment.apiBaseUrl + '/api/dynamicreports/getPatientPayData';
    public static readonly getServiceSummaryDataUrl: string =
        environment.apiBaseUrl + '/api/dynamicreports/getServiceSummaryData';
    public static readonly getServiceSummaryDetailUrl: string =
    environment.apiBaseUrl + '/api/dynamicreports/getServiceDetailData';
  public static readonly getPaystubUrl: string =
    environment.apiBaseUrl + '/api/dynamicreports/GetPaystubData';
  public static readonly getPayStubServiceDetailUrl: string =
    environment.apiBaseUrl + '/api/dynamicreports/GetPayStubServiceDetailData';
    public static readonly GetPacketStatus: string =
        environment.apiBaseUrl + '/api/UserDocuments/GetPacketStatus';

    // time-sheet
    public static readonly getShiftByIdUrl = environment.apiBaseUrl + '/api/timesheet/GetShiftById';
    public static readonly rejectMultipleShiftEntryUrl = environment.apiBaseUrl + '/api/timesheet/RejectMultipleShiftEntry';
    public static readonly getParticipantTimeShiftUrl = environment.apiBaseUrl + '/api/timesheet/GetParticipantTimeShift';
    public static readonly getAttestationTemplateUrl = environment.apiBaseUrl + '/api/UserManagement/getAttestationTemplate';
    public static readonly approveMultipleShiftEntryUrl = environment.apiBaseUrl + '/api/timesheet/ApproveMultipleShiftEntry';
    public static readonly getCaregivers = environment.apiBaseUrl + '/api/UserManagement/GetCaregivers';
    public static readonly getParticipantMileagesUrl = environment.apiBaseUrl + '/api/Mileage/GetParticipantMileages';
    public static readonly getAllTimeEntriesUrl = environment.apiBaseUrl + '/api/timesheet/GetCaregiverTimeShift';
    public static readonly getCaregiverParticipantLiveInDatesUrl = environment.apiBaseUrl
        + '/api/timesheet/GetCaregiverParticipantLiveInDates';
    public static readonly getCareGiverWWlHours = environment.apiBaseUrl + '/api/timesheet/GetCareGiverWWLHours';
    public static readonly getCareGiverMonthlyHours = environment.apiBaseUrl + '/api/timesheet/GetCareGiverMonthlyHours';
    public static readonly getCareGiverMonthlyAllocations = environment.apiBaseUrl + '/api/timesheet/GetCareGiverMonthlyAllocations';
    public static readonly getCaregiverWeeklyHours = environment.apiBaseUrl + '/api/timesheet/GetCaregiverWeeklyHours';
    public static readonly getParticipantTasks = environment.apiBaseUrl + '/api/timesheet/participanttasks';
    public static readonly submitShiftEntryUrl = environment.apiBaseUrl + '/api/timesheet/SubmitShiftEntry';
    public static readonly deleteShiftEntryUrl = environment.apiBaseUrl + '/api/timesheet/DeleteShiftEntry';
    public static readonly getCaregiversShiftServiceCodeUrl = environment.apiBaseUrl +
        '/api/timesheet/GetCaregiversShiftServiceCode';
    public static readonly getCaregiversMileageServiceCodeUrl = environment.apiBaseUrl + '/api/Mileage/GetCaregiversMileageServiceCode';
    public static readonly getMileageById = environment.apiBaseUrl + '/api/Mileage/GetMileageById';
    public static readonly deleteMultipleTimeShift = environment.apiBaseUrl + '/api/timesheet/DeleteMultipleShiftEntry';
    public static readonly copyForwardUrl = environment.apiBaseUrl + '/api/timesheet/CopyShiftsForward';
    public static readonly submitMileageEntryUrl = environment.apiBaseUrl + '/api/Mileage/SubmitMileageEntry';
    public static readonly saveShiftEntryUrl = environment.apiBaseUrl + '/api/timesheet/saveShiftEntry';
    public static readonly updateShiftEntryUrl = environment.apiBaseUrl + '/api/timesheet/UpdateShiftEntry';
    public static readonly getPayStubHistoryUrl = environment.apiBaseUrl + '/api/Timesheet/GetAllPayStubsBySolomonId';
    public static readonly getPayStubServiceDetailHistoryUrl = environment.apiBaseUrl +
        '/api/Timesheet/GetAllPayStubsServiceDetailBySolomonId';
    public static readonly getPayStubReportUrl = environment.apiBaseUrl + '/api/Reports/GetPaystubReportByCheckNumber';
    public static readonly getPayStubServiceDetailReportUrl = environment.apiBaseUrl +
        '/api/Reports/GetPaystubServiceDetailReportByCheckNumber';
    public static readonly getUserUrl = environment.apiBaseUrl + '/api/UserManagement/getUser';
    public static readonly getStatesUrl = environment.apiBaseUrl + '/api/UserManagement/getStates';
    public static readonly getCompaniesByStateUrl = environment.apiBaseUrl + '/api/UserManagement/getCompaniesByState';
    public static readonly getAllStatusUrl = environment.apiBaseUrl + '/api/UserManagement/getAllStatus';
    public static readonly getAllAttestationTemplateUrl = environment.apiBaseUrl + '/api/UserManagement/getAllAttestationTemplates';
    public static readonly getUsersByRoleUrl = environment.apiBaseUrl + '/api/UserManagement/getUsersByRole';
    public static readonly updateUserUrl = environment.apiBaseUrl + '/api/UserManagement/updateUser';
    public static readonly updateContactInfoUrl = environment.apiBaseUrl + '/api/UserManagement/UpdateContactInfo';
    public static readonly getProgramsByCompanyUrl = environment.apiBaseUrl + '/api/UserManagement/getProgramsByCompany';
    public static readonly getNameBySolomonId = environment.apiBaseUrl + '/api/UserManagement/getnamebyslid';
    public static readonly registerUserApiUrl = environment.apiBaseUrl + '/api/UserManagement/RegisterUser';
    public static readonly SetImpersonateSessionDetailsUrl = environment.apiBaseUrl + '/api/UserManagement/SetImpersonateSessionDetails';
    public static readonly GetRoles = environment.apiBaseUrl + '/api/RoleManagement/GetRoles';
    public static readonly DeleteRoles = environment.apiBaseUrl + '/api/RoleManagement/DeleteUserRole';
    public static readonly UpdateRolePermission = environment.apiBaseUrl + '/api/RoleManagement/UpdateRolePermission';
    public static readonly GetRolePermissionByRoleId = environment.apiBaseUrl + '/api/RoleManagement/GetRolePermissionByRoleId';
    public static readonly GetAllPermissions = environment.apiBaseUrl + '/api/RoleManagement/GetPermissions';
    public static readonly GetAllRoutes = environment.apiBaseUrl + '/api/RoleManagement/GetRoutes';

    public static readonly setPrimaryRole = environment.apiBaseUrl + '/api/UserManagement/SetPrimaryRole';
    public static readonly getAllCanBeImpersonatedUser = environment.apiBaseUrl + '/api/UserManagement/GetAllCanBeImpersonatedUser';
    public static readonly getUserDetailsByPersonId = environment.apiBaseUrl + '/api/UserManagement/GetUserByPersonId';

    public static readonly CreateSessionForImpersonatedUser = environment.apiBaseUrl +
        '/api/userManagement/CreateSessionForImpersonatedUser';
    public static readonly approveMultipleMileageEntryUrl: string = environment.apiBaseUrl + '/api/Mileage/ApproveMultipleMileageEntry';
    public static readonly rejectMultipleMileageEntryUrl: string = environment.apiBaseUrl + '/api/Mileage/RejectMultipleMileageEntry';
    public static readonly getAllAdjustmentReasonEntryUrl = environment.apiBaseUrl + '/api/timesheet/GetAllAdjusmentReasonCode';

    public static getParticipantsUrl: string = environment.apiBaseUrl + '/api/UserManagement/GetParticipants';

    public static readonly getCaregiverMileagesUrl: string = environment.apiBaseUrl + '/api/Mileage/GetCaregiverMileages';
    public static readonly saveMileageEntryUrl: string = environment.apiBaseUrl + '/api/Mileage/SaveMileageEntry';
    public static readonly updateMileageEntryUrl: string = environment.apiBaseUrl + '/api/Mileage/UpdateMileageEntry';
    public static readonly deleteMultipleMileageEntryUrl: string = environment.apiBaseUrl + '/api/Mileage/DeleteMultipleMileageEntry';
    public static readonly deleteMileageEntryUrl: string = environment.apiBaseUrl + '/api/Mileage/DeleteMileageEntry';

    // Notification
    public static readonly getAllNotificationsUrl: string = environment.apiBaseUrl + '/api/notificationv2/notifications';
    public static readonly toggleReadNotificationsUrl: string = environment.apiBaseUrl + '/api/notificationv2/notifications/toggle-read';
    public static readonly deleteNotificationsUrl: string = environment.apiBaseUrl + '/api/notificationv2/notifications/delete';
    public static readonly getNotificationUnReadCntUrl: string = environment.apiBaseUrl + '/api/notificationv2/notifications/unreadcnt';

    // AuthAllocation
    public static readonly getAllAuthAllocationUrl: string = environment.apiBaseUrl + '/api/AuthAllocation/fetchAll';
    public static readonly saveAuthAllocationUrl: string = environment.apiBaseUrl + '/api/AuthAllocation/SaveAuthAllocation';
    public static readonly getServiceCodeByIdUrl: string = environment.apiBaseUrl + '/api/AuthAllocation/GetServiceCodeById';

    // Configuration and Features
    public static readonly CompanyInfo: string = environment.apiBaseUrl + '/api/Company/info';
    public static readonly V4RoleScreensDetails: string = environment.apiBaseUrl + '/api/company/v4rolescreensdetails';

    // User Documents
    public static readonly GetParticipantDocumentSummaries: string = environment.apiBaseUrl + '/api/UserDocuments/GetParticipantDocuments';
    public static readonly GetParticipantDocument: string = environment.apiBaseUrl + '/api/UserDocuments/GetParticipantDocument';
}

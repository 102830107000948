import { AfterViewInit, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, take, takeUntil } from 'rxjs';

import { CurrentUser } from '../core/currentuser';
import { ValidationFunctions } from '../shared/utilities/validation-functions';
import { AppConstants } from '../core/appconstants';
import { ApiURLConstants } from '../core/apiurlconstants';
import { ValidationErrorExpressionConstants } from '../shared/constants/error-expression-constants';
import { PortalUserService } from '../core/services/portalUser.service';
import { MsalCustomService } from '../core/services/msal.service';
import { ResponseModel, SetLastV4LoginOnRequest, UserManagementServiceProxy } from '../external-user/services/v4ApiServices/v4ApiServices.services';
import { SuccessLandingPageService } from './success-popup/success-popup.service';
import { StorageConstants } from '../core/storageconstants';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit, OnDestroy, AfterViewInit {

  public companyConfig: any;
  public inputType: string = 'password';
  public validationForm: UntypedFormGroup;
  public checkResult: boolean;
  public isImpersonate = false;
  public currentUserData = JSON.parse(sessionStorage.getItem(StorageConstants.LoginUserDetails));

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private portalUserService: PortalUserService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private httpService: HttpClient,
    private toastr: ToastrService,
    public msalService: MsalCustomService,
    private successLandingPageService: SuccessLandingPageService,
    private userManagementServiceProxy: UserManagementServiceProxy) {
      this.companyConfig = JSON.parse(atob(sessionStorage.getItem(AppConstants.companyConfigStorageKey)));
  }

  ngOnInit() {
    this.getCurrentUser();
    let impersonateUserIdFromCurrentData = this.currentUserData?.impersonateUserId;
    let impersonateUserIdFromDetails = CurrentUser.details?.impersonateUserId;
    this.isImpersonate = (impersonateUserIdFromCurrentData && impersonateUserIdFromCurrentData > 0) ||
      (impersonateUserIdFromDetails && impersonateUserIdFromDetails > 0);
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  public async getCurrentUser() {
    if (CurrentUser.details === undefined) {
      AppConstants.showLoading = true;
      await this.portalUserService.InitiateUserSession(true);
      AppConstants.showLoading = false;
    }
  }

  public initializeForm() {
    this.validationForm = new UntypedFormGroup({
      ssn: new UntypedFormControl({value: '', disabled: this.isImpersonate}, [
        ValidationFunctions.required,
        Validators.pattern(/^\d+$/),
        Validators.maxLength(4),
        Validators.minLength(4),
      ]),
    }, { updateOn: 'change' });
  }

  public toggleVisibility() {
    this.inputType = (this.inputType === 'password') ? 'text' : 'password';
  }

  public showFirstNumber(object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return object[key]
      }
    }
    return null;
  }

  public onSubmit() {
    this.validate();
  }

  private async validate() {
    if (this.validationForm.valid && CurrentUser && CurrentUser.details) {
      const req = {
        PersonId: CurrentUser?.details?.solomonId,
        Ssn: this.validationForm.controls['ssn'].value,
        CompanyCode: CurrentUser?.details.companyCode,
      }
      AppConstants.showLoading = true;
      await this.postValidation(req);
      AppConstants.showLoading = false;

      if (this.checkResult === true) {
        this.successLandingPageService.setShow(true);
        if (CurrentUser.details.solomonId && CurrentUser.details.companyCode) {
          const req = new SetLastV4LoginOnRequest();
          req.personId = String(CurrentUser.details.solomonId);
          req.companyCode = CurrentUser.details.companyCode;
          this.userManagementServiceProxy.setLastV4LoginOn(req).subscribe();
        }
        this.goToDashboard();
      }
    }
  }

  private async postValidation(req) {
    return new Promise((resolve, reject) => {
      this.httpService.post(ApiURLConstants.validateSSN, req)
      .pipe(
        take(1),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (res: ResponseModel) => {
          if (res.isSuccess) {
            CurrentUser.details.isSsnValidated  = true;
            this.checkResult = true;
            resolve(true);
          } else {
            this.toastr.error(res.message);
            this.checkResult = false;
            resolve(true);
          }
        },
        error: error => {
          this.toastr.error(error?.error?.message ?? ValidationErrorExpressionConstants.serverErrorMessage);
          this.checkResult = false;
          resolve(true);
        },
        complete: () => {
        },
      });
    });
  }

  public async reload() {
    location.reload();
  }

  public goToDashboard() {
    this.router.navigateByUrl(CurrentUser.details.userAccessRight.routeUrl);
  }

}

import { AppConstants } from './../appconstants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import enLangJson from 'src/assets/i18n/resources-locale_en.json';
import esLangJson from 'src/assets/i18n/resources-locale_es.json';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageChangeService {
  private languageSource = new BehaviorSubject<string>(AppConstants.defaultLanguage);
  currentLanguage = this.languageSource.asObservable();

  public use(lang: string): Observable<{}> {
    this.languageSource.next(lang);
    AppConstants.preferredLanguageData = {};
    switch (lang) {
      case 'en':
        AppConstants.preferredLanguageData = enLangJson;
        break;
      case 'es':
        AppConstants.preferredLanguageData = esLangJson;
        break;

      default:
        break;
    }
    return of(AppConstants.preferredLanguageData);
  }
}

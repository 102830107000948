import { Component, OnInit } from '@angular/core';
import { CommonFunctionsService } from 'src/app/core/services/common-functions.service';
import { CurrentUser } from 'src/app/core/currentuser';
import { Router } from '@angular/router';
import { CurrentUserDetails } from 'src/app/core/models/currentuserdetails';
import { UserRole } from '../core/userrole';
import { AppConstants } from '../core/appconstants';
import { PortalUserService } from '../core/services/portalUser.service';

@Component({
  selector: 'app-registration-status',
  templateUrl: './registration-status.component.html',
  styleUrls: ['./registration-status.component.scss']
})
export class RegistrationStatusComponent implements OnInit {
  public userDetails: CurrentUserDetails;
  routeUrl = '';
  constructor(
    private router: Router,
    private commonFunctions: CommonFunctionsService,
    private portalUserService: PortalUserService) {
      this.userDetails = CurrentUser.details;
  }

  ngOnInit() {
    if (!this.commonFunctions.isValidSession()) {
      this.portalUserService.logout().subscribe();
    }
  }

  // Checks the provided status is the current status or not
  public isCurrentStatus(status: number): boolean {
      return (status === this.userDetails.enrollmentStatus);
  }

  // Checks the current user is internal or external user.
  public isImpersonation(): boolean {
      return CurrentUser.isInternalUser;
  }

  isOnboardingEligible() {
    const roleId = this.userDetails.roleId;
    if (Number(roleId) === UserRole.Participant ||
    Number(roleId) === UserRole.Caregiver ||
    Number(roleId) === UserRole.EmployerOfRecord) {
      return true;
     }
    return false;
  }

  routeToUserProfile() {
    AppConstants.showLoading = true;
    this.router.navigateByUrl('user/editdetails/' + this.userDetails.email);
  }
}

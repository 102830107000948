import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { MsalCustomService } from '../services/msal.service';

@Injectable()
export class MsalGuard {

    constructor(private router: Router, private msalcustSer: MsalCustomService) { }

    async canActivate(): Promise<boolean> {
        const userAuthenticated = await this.msalcustSer.authenticated;
        if (!userAuthenticated) {
            this.router.navigate(['login']);
        } 
        return userAuthenticated;
    }
}

export class ApplicationInsightsConstants {
    public static sourceName = 'WEBTS';
    public static source = 'V4-UI';

    public static getAllAuthorizationsEventName = 'Get all authorizations';
    public static getAuthorizationDetailsByAcnEventName = 'Get AuthorizationDetails by Acn';
    public static getAuthorizationServiceDetailsEventName = 'Get Authorization Service Detail';
    public static getAuthorizationAmountDetailsEventName = 'Get Authorization Amount Details';
    public static getAuthorizationServiceHistoryEventName = 'Get Authorization Service History';
    public static refreshAuthorizationAmountDetailsEventName = 'Refresh Authorization Amount Details';
    public static getAmountRangeEventName = 'Get Amount Range';
    public static getUnitRangeEventName = 'Get Unit Range';
    public static getAllShiftAndPayrollEventName = 'Get all Shift and Payroll Details for Participant';
    public static getEmployeeDetailsEventName = 'Get Employee Details.';



    public static getAllTimeEntriesEventName = 'Get all Time Entries';
    public static saveShiftEntryEventName = 'Save Shift Entry';
    public static updateCareGiverTimeEntryEventName = 'Update CareGiver Time Entry';
    public static getShiftByIdEventName = 'Get Shift By Id';
    public static getCaregiversShiftServiceCodeEventName = 'Get Caregivers Shift Service Code';
    public static deleteShiftEntryEventName = 'Delete Shift Entry';
    public static submitEventName = 'Submit Request';
    public static submitShiftEntryEventName = 'Submit Shift Entry';
    public static deleteMultipleTimeShiftEventName = 'Delete Multiple Time Shift';
    public static submitCopyForwardEventName = 'Submit Copy Forward';

    public static getOutstandingTimeEntriesForDREventName = 'Get Outstanding Time Entries for DR';
    public static getOutstandingMileageEntriesForDREventName = 'Get Outstanding Mileage Entries for DR';
    public static getAllParticipantsForDREventName = 'Get All Participants for DR';
    public static getAllServiceProvidersForDREventName = 'Get All Service Providers for DR';

    public static getMileageEntriesEventName = 'Get Mileage Entries';
    public static saveMileageEntryEventName = 'Save Mileage Entry';
    public static submitMileageEntryEventName = 'Submit Mileage Entry';
    public static submitMultipleMileageEntryEventName = 'Submit Multiple Mileage Entry';
    public static updateCareGiverMileageEntryEventName = 'Update CareGiver Mileage Entry';
    public static deleteMultipleMileageEntryEventName = 'Delete Multiple Mileage Entry';
    public static deleteMileageEntryEventName = 'Delete Mileage Entry';
    public static getMileageByIdEventName = 'Get Mileage by Id';
    public static getCaregiversMileageServiceCodeEventName = 'Get Caregivers Mileage Service Code';
    public static getParticipantMileagesEventName = 'Get Participant Mileages';
    public static approveMultipleMileageEntryEventName = 'Approve Multiple Mileage Entry';
    public static rejectMultipleMileageEntryEventName = 'Reject Multiple Mileage Entry';
    public static getOutstandingTimeEntriesForParticipantEventName = 'Get Outstanding Time Entries for Participant';
    public static getOutstandingMileageEntriesForParticipantEventName = 'Get Outstanding Mileage Entries for Participant';
    public static getAllContactsForParticipantEventName = 'Get all Contacts for Participant';

    public static getServiceCodesEventName = 'Get Service Codes';
    public static getServiceCodesTemplateEventName = 'Get Service Codes Template';
    public static getOutstandingTimeEntriesEventName = 'Get Outstanding Time Entries';
    public static getMalformedEntriesEventName = 'Get Malformed Entries';
    public static getOutstandingMileageEntriesEventName = 'Get Outstanding Mileage Entries';
    public static getParticipantsEventName = 'Get Participants';

    public static getAllTimeEntriesTimeSheetSvcEventName = 'Get all Time Entries';
    public static getShiftByIdTimeSheetSvcEventName = 'Get all Time Entries';
    public static rejectMultipleShiftEntryEventName = 'Reject Multiple Shift Entry';
    public static approveMultipleShiftEntryEventName = 'Approve Multiple Shift Entry';
    public static getPayStubHistoryEventName = 'Get Pay Stub History';
    public static getPayStubReportEventName = 'Get Pay Stub Report';

    public static getAllUserDocumentsEventName = 'Get all User Documents';
    public static getUserDocumentEventName = 'Get User Document';

    public static setUserInfo = 'Set user info session created';

    public static getUserDetailsEventName = 'Get User Details';
    public static registerUserEventName = 'Register User';
    public static updateUserEventName = 'Update User';
    public static searchUsersEventName = 'Search Users';
    public static getUserEventName = 'Get User';
    public static getCompaniesByStateEventName = 'Get Companies by State';
    public static getProgramsByCompanyEventName = 'Get Programs by Company';
    public static getUserStatusEventName = 'Get User Status';
    public static getAttestationTemplatesEventName = 'Get Attestation Templates';
    public static getUsersByRoleEventName = 'Get Users by Role';
    public static getStatesEventName = 'Get States';
    public static getUserRolesEventName = 'Get User Roles';
    public static getParticipantsUserManagementSvcEventName = 'Get Participants';
    public static getCaregiversEventName = 'Get Caregivers';
    public static getAttestationTemplateEventName = 'Get Attestation Template';
    public static updateContactInfoEventName = 'Update Contact Info';
    public static validateProgramCoordinatorEventName = 'Validate Program Coordinator';
    public static getExternalUserNamesByFilterEventName = 'Get External User Names by Filter';
    public static getPCParticipantNamesByFilterEventName = 'Get PC Participant Names by Filter';
    public static getCaseManagerParticipantNamesByFilterEventName = 'Get Case Manager Participant Names by Filter';
    public static getReportEventName = 'Get Report';
    public static getExternalUsersEventName = 'Get External Users';
    public static getParticipantsForProgramCoordinatorEventName = 'Get Participants for Program Coordinator';
    public static getParticipantsForExternalCaseManagerEventName = 'Get Participants for External Case Manager';
    public static getAnnouncementsEventName = 'Get Announcements';

    public static getAllCanBeImpersonatedUser = 'Get all can be Impersonated User list';
    public static getUserDetailsByPersonId = 'Get User Details By PersonId';

    public static impersonatedSelectedUser = 'Impersonated Seelcted User';
    public static fromImpersonatedSelectedUserToBackToMyProfile = 'From impersonated Seelcted User to back to My Profile';

    public static getAllParticipantsEventName = 'Get all participants';
    public static getParticipantByIdEventName = 'Get Participant by Id';
    public static getCustEmpRateEventName = 'Get customer Employee Rate';
    public static getEAByIdEventName = 'Get EA by Id';

    public static getserviceFacilitator = 'get Service facilitator';

    public static getAllAgencyEventName = 'Get all agencies';
    public static saveAgencyListEventName = 'Save Agency List';

    public static getCustEmpRateListEventName = 'Get Customer Employee Rate List';

    public static getAllNotifications = 'Get All Notifications';
    public static toggleReadNotifications = 'Update Toggle Read Notifications';
    public static deleteNotificationbyId = 'Delete Notification by Id';
    public static getNotificationUnReadCnt = 'Get Count of Unread Notifications';

    public static getAllAuthAllocation = 'Get All Auth Allocations';
    public static saveAuthAllocation = 'Save All Auth Allocations';
}

import { StorageConstants } from '../storageconstants';
import { environment } from 'src/environments/environment';
import { MsalGuardConfiguration } from '@azure/msal-angular';
import { PublicClientApplication, BrowserCacheLocation, InteractionType, IPublicClientApplication, Configuration, LogLevel } from '@azure/msal-browser';
import { AppInjector } from 'src/app/app.module';
import { AppInsightsService } from './app-insights.service';

    
export function getAuthorityUrlForExternalUser() {
    return 'https://' + environment.authority + '/' + environment.TenentIdB2C + '/' + environment.signInPolicy;
}

export function getAuthorityUrlForInternalUser() {
    return 'https://' + environment.authorityAD + '/' + environment.TenentIdAD;
}

export const msalLoggingConfig = {
    loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
            if (!environment.production && logLevel== LogLevel.Info) {  AppInjector.get(AppInsightsService).trackTrace(message); }
        },
        logLevel: environment.production ? LogLevel.Warning : LogLevel.Verbose,
        piiLoggingEnabled: false
    }
}

export const msalCachingConfig = {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: true,
}

export const aadMsalConfig: Configuration = {
    auth: {
        clientId: environment.ClientIdAD,
        authority: getAuthorityUrlForInternalUser(),
        knownAuthorities: [environment.authorityAD],
        navigateToLoginRequestUrl: environment.navigateToLoginRequestUrl,
        redirectUri: environment.redirectUrl
    },
    cache: msalCachingConfig,
    system: msalLoggingConfig
}


export const b2cMsalConfig: Configuration = {
    auth: {
        clientId: environment.clientIdB2C,
        authority: getAuthorityUrlForExternalUser(),
        knownAuthorities: [environment.authority],
        navigateToLoginRequestUrl: environment.navigateToLoginRequestUrl,
        redirectUri: environment.redirectUrl,
        postLogoutRedirectUri: environment.postLogoutRedirectUrl
    },
    cache: msalCachingConfig,
    system: msalLoggingConfig
}

export function MSALInstanceFactory(): IPublicClientApplication {

    const userType = sessionStorage.getItem(StorageConstants.userType)

    if (userType === StorageConstants.internalUserValue) {
        // Internal user flow.
        return new PublicClientApplication(aadMsalConfig);
    }
    else {

        // External user
        return new PublicClientApplication(b2cMsalConfig);
    }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...environment.graphScopes, environment.apiScope],
        },
        loginFailedRoute: 'login-failed'
    };
}



<kendo-dialog class="custom-dialog lg custom-dialog-darkOverlay" width="{{mainpopupWidth}}" height="auto">
    <section class="cdms-persona">
        <div class="attestation-header">
            <h1 data-i18n="label_user_profile">
                {{'label_clientrelationship_01'|langchange}}
            </h1>
                <button class="dialog-button-close" (click)="closeCgRelModal()" *ngIf="showCloseBtn">
                    <kendo-svg-icon [icon]="xIcon"></kendo-svg-icon></button>
                <a href="javascript:void(0)" id="language-selector" *ngIf="!showCloseBtn"
                    (click)="changeLanguage()">{{languageText}}</a>
        </div>
        <div class="h-line margin-b-30"></div>
    </section>
    <div class="modal-cont">
        <div class="row" *ngIf="isCaregiverRelEnabled">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="clearfix font-weight-bold fs-16">
                    <span class="float-left">{{'label_clientrelationship_02' | langchange}}</span>
                    <span class="float-left d-flex">
                        <label class="ml-3 margin-r-25per-rem d-flex">
                            <input class="margin-l-25per-rem margin-r-25per-rem" [disabled]="relRejected || isImpersonateUser" name="isEndLocnSameAsStartLocn1"
                                [(ngModel)]="isConfirm" [value]="true" (change)="cgPtRelChange(true)" type="radio">
                            <label>
                                {{'button_yes' | langchange}}
                            </label>
                        </label>
                        <label class="mr-3 d-flex">
                            <input class="margin-l-50per-rem margin-r-25per-rem" [disabled]="relRejected || isImpersonateUser" name="isEndLocnSameAsStartLocn1"
                                [(ngModel)]="isConfirm" [value]="false" (change)="cgPtRelChange(false)" type="radio">
                            <label>
                                {{'button_no' | langchange}}.
                            </label>
                        </label>
                    </span>
                </div>
                <div class="mb-5 font-weight-bold fs-16">
                    {{'label_clientrelationship_03' | langchange}} {{'label_clientrelationship_04' | langchange}}
                </div>



                <div class="margin-b-15">
                    <div class="row">
                        <div class="col-md-8 ml-5">
                            <div class="row margin-b-10">
                                <div class="col-md-6 col-6"><b>{{'label_item_name' | langchange}}</b></div>
                                <div class="col-md-6 col-6"><b>{{'label_item_value' | langchange}}</b></div>
                            </div>
                            <div class="row margin-b-10">
                                <div class="col-md-6 col-6">{{'label_name' | langchange}}</div>
                                <div class="col-md-6 col-6">{{CaregiverParticipantDetails.participantFirstName}} {{CaregiverParticipantDetails.participantLastName}}</div>
                            </div>
                            <div class="row margin-b-10">
                                <div class="col-md-6 col-6">{{'label_providerOneiD' | langchange}} ({{'label_if_known' |
                                    langchange}})</div>
                                <div class="col-md-6 col-6">{{CaregiverParticipantDetails.providerOneId}}</div>
                            </div>
                            <div class="row margin-b-10">
                                <div class="col-md-6 col-6">{{'label_client_age' | langchange}}</div>
                                <div class="col-md-6 col-6">{{CaregiverParticipantDetails.participantAge}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="showRelAuth">
                <div class="margin-b-25 font-weight-bold fs-16">
                    {{'label_caregiver_participant_text03' | langchange}}
                </div>

                <div class="margin-b-15">
                    <div class="row">
                        <div class="col-md-8 ml-5">
                            <div class="row margin-b-10">
                                <div class="col-md-6 col-6">{{'label_clientrelationship_05' | langchange}}</div>
                                <div class="col-md-4 col-4">
                                    <select required [class.has-error]="relTypeError" (change)="validateRelType()"
                                        name="relationTypes" id="relationTypes" [(ngModel)]="relationshipToClientId">
                                        <option *ngFor="let data of relationshipTypesData" [value]="data.id">
                                            {{data.description}}
                                        </option>
                                    </select>
                                    <span class="text-danger" *ngIf="relTypeError">
                                        {{'please_select' | langchange}} {{'label_relation_client' | langchange}}
                                    </span>
                                </div>
                            </div>
                            <div class="row margin-b-10">
                                <div class="col-md-6 col-6">{{'label_clientrelationship_06' | langchange}}</div>
                                <div class="col-md-4 col-4">
                                    <select required [class.has-error]="authRepError" (change)="validateAuthRep()"
                                        name="authRep" id="authRep" [(ngModel)]="isAuthRepresentative">
                                        <option value=1>{{'button_yes' | langchange}}</option>
                                        <option value=2>{{'button_no' | langchange}}</option>

                                    </select>
                                    <span class="text-danger" *ngIf="authRepError">
                                        {{'please_select' | langchange}} {{'label_authorized_rep' | langchange}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 ml-5">
                            <div class="row">
                                <div class="col-md-6 col-6"></div>
                                <div class="col-md-4 col-4">
                                    <button [disabled]="isImpersonateUser" type="button" class="btn btn-primary btn-lg" (click)="confirmContinue(true)"
                                        i18n="button_Ok">{{'button_Continue' | langchange}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</kendo-dialog>

<kendo-dialog class="custom-dialog" *ngIf="showConfirmation" width="{{popupWidth}}" height="auto">
    <div class="row">
        <div class="col-lg-12 col-12">
            <p class="model-text">
                {{'label_are_you_sure_reject' | langchange}}
            </p>
            <div class="modal-body h-auto"></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-lg" (click)="rejectService(false)"
                    i18n="button_Ok">{{'button_no' | langchange}}</button>
                <button type="button" class="btn btn-primary btn-lg" (click)="rejectService(true)"
                    i18n="button_Ok">{{'button_yes' | langchange}}</button>
            </div>
        </div>
    </div>
</kendo-dialog>

<app-confirm-modal *ngIf="showConfirmModal" (modalBtnClick)="closeModal($event)"></app-confirm-modal>
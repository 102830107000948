
import { Injectable } from '@angular/core';
import { AppConstants } from '../../core/appconstants';
import { FeatureConstants } from '../../core/featureConstants';

@Injectable({
    providedIn: 'root'
})
export class FeatureStateService {

    isCCHEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CCH);
    }

    isQualificationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.Qualifications);
    }

    isNotificationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.Notifications);
    }

    isCaregiverDemographicsValid(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverDemographics);
    }

    isCaregiverQualificationValid(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverQualification);
    }

    isCaregiverDocValid(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverDoc);
    }

    isCaregiverFicaValid(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverFica);
    }

    isCaregiverRelValid(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverRel);
    }

    isCaregiverTrainingValid(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverTraining);
    }

    isCaregiverBackgroundCheckValid(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverBackgroundCheck);
    }

    isWWLEnabled(): boolean {
        return this.isEnabled(FeatureConstants.WWL);
    }

    isTasksEnabled(): boolean {
        return this.isEnabled(FeatureConstants.Tasks);
    }

    isAuthAllocationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.AuthAllocation);
    }

    isGHSEnabled(): boolean {
        return this.isEnabled(FeatureConstants.GHS);
    }

    isAdminTimeAdjustmentEnabled(): boolean {
        return this.isEnabled(FeatureConstants.AdminTimeAdjustment);
    }

    isCopyForwardEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CopyForward);
    }

    isPTOEnabled(): boolean {
        return this.isEnabled(FeatureConstants.PTO);
    }

    isInternalAuthEnabled(): boolean {
        return this.isEnabled(FeatureConstants.InternalAuth);
    }

    isEmployerPayRatesEnabled(): boolean {
        return this.isEnabled(FeatureConstants.EmployerPayRates);
    }

    isShiftSubmitCountsAsApproved(): boolean {
        return this.isEnabled(FeatureConstants.ShiftSubmitCountsAsApproved);
    }

    areWaiverCodeSingleLineEdit(): boolean {
        return this.isEnabled(FeatureConstants.WaiverCodeSingleLineEdit);
    }

    isPSSTEnabled(): boolean {
        return this.isEnabled(FeatureConstants.PSST);
    }

    coEmploymentAutoEmployerRelationship(): boolean {
        return this.isEnabled(FeatureConstants.CoEmploymentAutoEmployerRelationship);
    }

    coEmploymentEmployerOfRecordDisabled(): boolean {
        return this.isEnabled(FeatureConstants.CoEmploymentEmployerOfRecordDisabled);
    }

    isCaregiverDemographicsConfirmationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverDemographicsConfirmation);
    }

    isCaregiverParticipantRelationshipConfirmationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CGParticipantRelationshipConfirmation);
    }

    isCaregiverDifficultOfCareConfirmationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverDifficultOfCareConfirmation);
    }

    isCaregiverFICAConfirmationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverFICAConfirmation);
    }

    isCaregiverQualificationsConfirmationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverQualificationsConfirmation);
    }

    isCaregiverBackgroundCheckConfirmationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverBackgroundCheckConfirmation);
    }

    isDisplayWorkdayButtonEnabled(): boolean {
        return this.isEnabled(FeatureConstants.DisplayWorkdayButton);
    }

    isPayStubHistoryGridEnabled(): boolean {
        return this.isEnabled(FeatureConstants.PayStubHistoryGrid);
    }

    isADPCompanyEnabled(): boolean {
        return this.isEnabled(FeatureConstants.ADPCompany);
    }

    isSickTimeEnabled(): boolean {
        return this.isEnabled(FeatureConstants.SickTime);
    }

    isViewDocumentationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.ViewDocumentation);
    }

    isCaregiverParticipantRequestRelationConfirmationEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CGPTReqRelConfirmation);
    }

    IsEditableIvrPinEnabled(): boolean {
        return this.isEnabled(FeatureConstants.EditableIvrPin);
    }

    isCaregiverParticipantDocumentEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverParticipantDocument);
    }

    CanRemoveReadyTimeEntry(): boolean {
        return this.isEnabled(FeatureConstants.RemoveReadyTimeEntry);
    }

    CanRemoveReadyMileageEntry(): boolean {
        return this.isEnabled(FeatureConstants.RemoveReadyMileageEntry);
    }

    isCaregiverParticipantRequestRelationAuthRepEnabled(): boolean {
        return this.isEnabled(FeatureConstants.CaregiverParticipantRequestRelationshipAuthorizedRep);
    }

    ShouldShowCaregiverParticipantAttestations(): boolean {
        return this.isEnabled(FeatureConstants.ShowCaregiverParticipantAttestations)
    }

    isSSNValidation(): boolean {
      return this.isEnabled(FeatureConstants.SSNValidation)
    }

    isTestShiftActive(): boolean {
      return this.isEnabled(FeatureConstants.TestShiftEntry)
    }

    isVASickTimeEnabled(): boolean {
        return this.isEnabled(FeatureConstants.VASickTime);
    }

    isV4CoreApiEnabled(): boolean {
        return this.isEnabled(FeatureConstants.V4CoreApi);
    }

    isShowQualificationRoleEnabled(): boolean {
      return this.isEnabled(FeatureConstants.ShowQualificationRole);
    }

    isHideSummaryAndDetailReportsEnabled(): boolean {
        return this.isEnabled(FeatureConstants.HideSummaryDetailReports);
    }

    isParticipantSpendingSummaryOnCGV4Enabled(): boolean {
        return this.isEnabled(FeatureConstants.ParticipantSpendingSummaryOnCGV4);
    }

    isV4NewShiftServiceEnabled(): boolean {
        return this.isEnabled(FeatureConstants.V4NewShiftService);
    }

    isShiftCommentsEnabled(): boolean {
        return this.isEnabled(FeatureConstants.EnableShiftComments);
    }

    isV4McoShowShiftSummaryTasksEnabled(): boolean {
        return this.isEnabled(FeatureConstants.V4McoShowShiftSummaryTasks);
    }
    
    isSubmitMultipleMileageShiftsEnabled(): boolean {
        return this.isEnabled(FeatureConstants.EnableSubmitMultipleMileageShifts);
    }

    isV4McoShowPayStubsEnabled(): boolean {
        return this.isEnabled(FeatureConstants.isV4McoShowPayStubsEnabled);
    }

    isEnabled(featureName: string): boolean {
        const companyFeatures = sessionStorage.getItem(AppConstants.companyFeaturesStorageKey);
        if (companyFeatures != null && companyFeatures !== '') {
            const companyFeaturesMap = new Map<string, boolean>(Object.entries(JSON.parse(atob(companyFeatures))));
            if (companyFeaturesMap && companyFeaturesMap.has(featureName)) {
                return companyFeaturesMap.get(featureName);
            }
        }
        return false;
    }
}

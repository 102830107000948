import { ApiURLConstants } from 'src/app/core/apiurlconstants';
import { Injectable } from '@angular/core';
import { CaseLoadDashBoardRequest } from '../caseload/caseload-dashboard/model/case-load-dash-board-request';

import { AppInsightsService } from 'src/app/core/services/app-insights.service';
import { HttpClient } from '@angular/common/http';
import {CommonFunctionsService} from 'src/app/core/services/common-functions.service';
@Injectable({
  providedIn: 'root'
})
export class UserDashBoardService {

    constructor(public httpService: HttpClient, public appInsights: AppInsightsService, public commonFunctionsService: CommonFunctionsService) { }

  public getAllCaseLoads(requestData: CaseLoadDashBoardRequest): any {
      return this.httpService.post(ApiURLConstants.getAllCaseLoads, requestData);
  }

  public getCaseLoadById(id: number): any {
      return this.httpService.get(ApiURLConstants.getCaseLoadById + '/' + id);
  }

  public deleteCaseLoads(requestData: any): any {
      return this.httpService.delete(ApiURLConstants.deleteCaseLoads + '?ids=' + requestData);
  }

  public createUpdateCaseLoad(requestData: any): any {
    return this.httpService.post(ApiURLConstants.createUpdateCaseLoad, requestData);
  }

  public getAllParticipants(requestData: any): any {
    return this.httpService.post(ApiURLConstants.getAllPartcipantCaseLoad, requestData);
  }
  public getAllSf(caseLoad, agencyId): any {
    return this.httpService.get(ApiURLConstants.getAllSfCaseLoad + '?caseLoadId=' + caseLoad + '&agencyId=' + agencyId);
  }

  public getAllParticipantsAgency(agencyId): any {
    return this.httpService.post(ApiURLConstants.getAllPartcipantAgency, agencyId);
  }

  public getAllParticipantsAgencyDashbaord(requestData: any) {
    return this.httpService.post(ApiURLConstants.getAllParticipants, requestData);
  }

  public getParticipantDetailsById(id: number): any {
   return this.httpService.get(ApiURLConstants.getParticipantById + '/' + id);
  }

  public getEmployeetDetailsById(id: number, participantId: string): any {
    return this.httpService.get(ApiURLConstants.getEmployeeById + '?employeeId=' + id + '&participantId=' + participantId);
   }

   public getEaDetailsById(id: number, participantid: number): any {
    return this.httpService.get(ApiURLConstants.getEaById + '/' + id + '/' + participantid);
   }

  public getAllSfDashbaord(requestData: any): any {
    return this.httpService.post(ApiURLConstants.getAllSf, requestData);
  }

  public getSfById(userId: any): any {
    return this.httpService.get(ApiURLConstants.getSfById  + '?Id=' + userId);
  }

  public removeSfsFromAgency(requestData: any): any {
    return this.httpService.post(ApiURLConstants.removeSfsFromAgency, requestData);
}

  public getAllAuthorizations(requestData: any): any {
    return this.httpService.post(ApiURLConstants.getAllAuthsForParticipants, requestData);
}

public getAllAuthorizationsByMco(requestData: any): any {
    return this.httpService.post(ApiURLConstants.getAllAuthsForMco, requestData);
}

public getAllAuthorizationsById(requestData: any): any {
    return this.httpService.post(ApiURLConstants.getAllAuthsListForParticipants, requestData);
}

public getAuthorizationDetailsByAcn(request: string): any {
    return this.httpService.get(ApiURLConstants.getAuthByAcn + '/' + request);
}
public getAuthorizationServiceDetails(requestData: any): any {
    return this.httpService.post(ApiURLConstants.getServiceDetails, requestData);
}
public getAuthorizationAmountDetails(requestData: any): any {
  return this.httpService.get(ApiURLConstants.getAuthAmountDetails + '/' + requestData);
}
public getAuthorizationServiceHistory(serveId: any, authId: any, participantId: string): any {
  return this.httpService.get(ApiURLConstants.getAuthCodeServiceHistory + '?serveId=' + serveId + '&authId=' + authId + '&participantId=' + participantId);
}
public getAmountRange(): any {
  return this.httpService.get(ApiURLConstants.getAmountRange);
}
public getUnitRange(): any {
  return this.httpService.get(ApiURLConstants.getUnitRange);
}
public getUserAgencys(userId): any {
  return this.httpService.get(ApiURLConstants.getUserAgencys + '?userId=' + userId);
}
public saveAgencyList(requestData: any): any {
  return this.httpService.post(ApiURLConstants.saveAgencyList, requestData);
}

public getCustEmpRateList(empId: number, custId: number): any {
  return this.httpService.get(ApiURLConstants.getcustEmpRateList + '/' + empId + '/' + custId);
}

public getAllShiftAndPayrollDetailsForParticipant(requestData: any): any {
  return this.httpService.post(ApiURLConstants.getAllShiftAndPayrollForParticipants, requestData);
}

public getSpendingDetailReport(solomonId: string): any {
  return this.httpService.get(ApiURLConstants.getSpendingDetailReportUrl + '?solomonId=' + solomonId,
  { headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'});
}

public getSpendingSummaryReport(solomonId: string): any {
   return this.httpService.get(ApiURLConstants.getSpendingSummaryReportUrl + '?solomonId=' + solomonId,
   { headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'});
}

  // Get User Info
  public getUser(requestData: string): any {
    const userEmailDTO ={
      email: requestData
    }
    // this.appInsights.trackTrace(ApplicationInsightsConstants.getUserEventName, { email: userEmail });
    return this.httpService.post(ApiURLConstants.getUserUrl, userEmailDTO);
  }

  // Get States
  public getStates(): any {
    // this.appInsights.trackTrace(ApplicationInsightsConstants.getStatesEventName);
    return this.httpService.get(ApiURLConstants.getStatesUrl);
  }

  public getCompaniesByState(stateId: string): any {
    // this.appInsights.trackTrace(ApplicationInsightsConstants.getCompaniesByStateEventName, { stateId: stateId });
    return this.httpService.get(ApiURLConstants.getCompaniesByStateUrl + '?stateId=' + stateId);
  }

  // Get User Status
  public getUserStatus(): any {
    // this.appInsights.trackTrace(ApplicationInsightsConstants.getUserStatusEventName);
    return this.httpService.get(ApiURLConstants.getAllStatusUrl);
  }

  public getAttestationTemplates(): any {
    // this.appInsights.trackTrace(ApplicationInsightsConstants.getAttestationTemplatesEventName);
    return this.httpService.get(ApiURLConstants.getAllAttestationTemplateUrl);
  }

  // get user By Role
  public getUsersByRole(roleId: any): any {
    // this.appInsights.trackTrace(ApplicationInsightsConstants.getUsersByRoleEventName, { roleId: roleId });
    return this.httpService.get(ApiURLConstants.getUsersByRoleUrl + '?roleId=' + roleId);
  }

  public updateUser(userData: any) {
    // this.appInsights.trackTrace(ApplicationInsightsConstants.updateUserEventName, userData);

    return this.httpService.put(ApiURLConstants.updateUserUrl , userData,
      { headers: { 'Content-Type': 'application/json' } });
  }

  public updateContactInfo(requestData: any): any {
    // this.appInsights.trackTrace(ApplicationInsightsConstants.updateContactInfoEventName, requestData);
    return this.httpService.post(ApiURLConstants.updateContactInfoUrl, requestData);
  }

  // Get Program
  public getProgramsByCompany(companyId: string): any {
    //  this.appInsights.trackTrace(ApplicationInsightsConstants.getProgramsByCompanyEventName, { companyId: companyId });
  return this.httpService.get(ApiURLConstants.getProgramsByCompanyUrl + '?companyId=' + companyId );
  }

  public getNameBySolomonId(id, companyId): any {
    return this.httpService.get(ApiURLConstants.getNameBySolomonId + '?id=' + id + '&companyId=' + companyId);
  }

  public getPatientPayData(custId: string, date: string): any {
    return this.httpService.get(ApiURLConstants.getPatientPayDataUrl + '?custid=' + custId + '&period=' + date);
  }

  public getServiceSummaryData(custId: string): any {
    return this.httpService.get(ApiURLConstants.getServiceSummaryDataUrl + '?custid=' + custId);
  }

  public getServiceDetailData(custId: string, startDate: string, endDate: string): any {
    return this.httpService.get(ApiURLConstants.getServiceSummaryDetailUrl + '?custid=' + custId + '&startdate='
      + startDate + '&enddate=' + endDate);
  }

  public getPaystubData(requestData: any): any {
    return this.httpService.post(ApiURLConstants.getPaystubUrl, requestData);
  }
}

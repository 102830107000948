import { MsalCustomService } from '../core/services/msal.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { StorageConstants } from '../core/storageconstants';
import { Router } from '@angular/router';
import { AppConstants } from '../core/appconstants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  public u2LoginRedirect: string = environment.U2BaseUrl;
  public v3LoginRedirect: string = environment.V3BaseUrl + 'Account/SignInAzureAD';
  showLoginForm = true;
  openInternalUserPopup = false;
  constructor(
    private msalService: MsalCustomService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    AppConstants.sessionExpires = false;
    AppConstants.headerLoading = false;
    if (sessionStorage.getItem(StorageConstants.userType) && (document.cookie.indexOf('msal.interaction.status') > -1)){
      this.showLoginForm = false;
      AppConstants.showLoading = true;
    } else {
      this.showLoginForm = true;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  signInExternalUser() {
    sessionStorage.setItem(StorageConstants.userType, StorageConstants.externalUserValue);
    this.msalService.signInExternalUser();
  }

  register() {
    sessionStorage.setItem(StorageConstants.userType, StorageConstants.externalUserValue);
    this.msalService.register();
  }

  signInInternalUser() {
    sessionStorage.setItem(StorageConstants.userType, StorageConstants.internalUserValue);
    this.msalService.signInInternalUser();
  }

  openInterUserLogin() {
    this.openInternalUserPopup = true;
  }
  closeInterUserPopup() {
    this.openInternalUserPopup = false;
  }
}

import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styles: [
  ]
})
export class ConfirmPopupComponent {

  popupWidth = 500;
  @Output() closeConfirmModal = new EventEmitter();
  constructor() { }

  confirmClose(option) {
    this.closeConfirmModal.emit(option);
  }

}

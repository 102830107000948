<kendo-dialog class="custom-dialog lg custom-dialog-darkOverlay" width="{{mainpopupWidth}}" height="auto">
    <div>
        <div class="attestation-header">
            <h1 class="mr-5">
                {{'label_doc_v2_title' | langchange}}
            </h1>
                <a href="javascript:void(0)" id="language-selector"
                    (click)="changeLanguage()">{{languageText}}</a>
        </div>
        <div class="h-line"></div>
    </div>
    <div class="modal-cont">
        <form name="docAttestationForm" (ngSubmit)="buildRequest()" [formGroup]="docForm">
            <div class="attestation-form" *ngIf="isCaregiverDocEnabled">                
                <div class="attestation-form-subtitle attestation-form-user-details margin-b-15">
                    <span>
                        <b>{{'label_client' | langchange}}: </b>
                    </span>
                    <span>
                        <b>{{CaregiverParticipantDetails.participantFirstName + ' ' + CaregiverParticipantDetails.participantLastName}}</b>
                    </span>
                </div>                
                <div>            
                    <div *ngFor='let detail of attestationDetails; let option = index'>
                        <div *ngIf="detail.componentType === 'Title' || detail.componentType === 'SubTitle'">
                            <div [class]="detail.componentType === 'Title' ? 'attestation-form-title':'attestation-form-subtitle'">
                                {{detail.componentText}}                               
                            </div>
                        </div> 
                        <div *ngIf="detail.componentType === 'Header'" class="margin-t-10">                           
                            <span>{{detail.componentText}}</span>                         
                        </div> 
                        <div *ngIf="detail.componentType === 'LabelWithLink'" class="margin-t-10 attestation-form-hyperlink-label">                           
                            <span>{{detail.componentText}}</span>                         
                        </div> 
                        <div *ngIf="detail.componentType === 'HyperLink'" class="margin-t-10">
                            <a href="{{detail.componentText}}" target="_blank" rel="noopener noreferrer">{{detail.componentText}}</a>
                        </div>  
                        <div *ngIf="detail.componentType === 'Question' && detail.optionType === 'Single'">
                            <div class="att-question" [attr.data-attr-qid]="detail.componentDetailId">
                                <div class="margin-t-10">
                                    {{detail.componentText}}
                                </div>
                                <div class="text-danger font-weight-bold" *ngIf="docFormsubmitted && docFormControls.controls[detail.componentId].errors?.required">
                                    {{'label_this_is_required' | langchange}}</div>
                                <div class="att-answer" [class.disabled-ctrl]="isImpersonateUser">
                                    <div class="margin-t-10">
                                        <div *ngFor="let answer of detail.questionnaireOptionDetails">
                                            <div class="att-answer-block clearfix">
                                                <input [attr.name]="detail.componentId" [attr.formControlName]="detail.componentId" class="att-answer-opt" value="true{{answer.optionDetailId}}" type="radio"  [disabled]="isImpersonateUser"
                                                [attr.data-attr-cid]="answer.componentId" [attr.data-attr-oid]="answer.optionId" [attr.data-attr-odid]="answer.optionDetailId" (change)="valueChange($event,detail.componentId)">
                                                <label class="att-answer-val">
                                                    {{answer.optionText}}
                                                </label>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>
                </div>                
                <div class="mt-5">
                    <button [disabled]="isImpersonateUser" type="submit" class="btn btn-primary btn-lg" i18n="button_Ok">{{'button_Continue' |
                        langchange}}</button>
                </div>


            </div>
        </form>
    </div>
</kendo-dialog>
<app-confirm-modal *ngIf="showConfirmModal" (modalBtnClick)="closeModal($event)"></app-confirm-modal>
export const environment = {
    envName: 'migration',
    production: true,
    clientIdB2C: 'a7aa4808-331d-4ec3-8c5b-7a0db6ad4c3f', // B2cV4PortalApplicationClientId
    TenentIdB2C: 'consumerdirectb2ctest.onmicrosoft.com', // B2cTenant
    graphScopes: ['openid'],
    apiScope: 'https://consumerdirectcare.onmicrosoft.com/49f953a3-f8aa-4192-930e-2e7985cbd71e/API.Access',
    popup: false,
    navigateToLoginRequestUrl: true,
    redirectUrl: window.location.origin,
    postLogoutRedirectUrl:  window.location.origin + '/#/signout',
    authority: 'consumerdirectb2ctest.b2clogin.com',
    authorityAD: 'login.microsoftonline.com',
    signInPolicy: 'B2C_1_R_Perf_Migration_Test_SignIn', // B2cSignInPolicyId
    signUpPolicy: 'B2C_1_R_Test_SignUp', // B2cSignUpPolicyId
    ClientIdAD: '4745b57f-e188-48ed-9234-ce6850b05872', // V4PortalApplicationId
    TenentIdAD: 'consumerdirectcare.onmicrosoft.com', // IdaTenant
    apiBaseUrl: 'https://apis-migration.cdcnapps.com/v4',
    appInsights: {
        instrumentationKey: '17f2c3a4-f18c-4213-bacf-f30d8c2b4db1' // V4InsightsInstrumentationKey
    },
    workdayURL: 'https://impl.workday.com/wday/authgwy/cdcn/login-saml2.htmld',
    twoWeeksTimeEntryStates: 'VA;WA;NV;MT',
    companyCodeOfVirginia: 'CDVA',
    AllowedStates: 'VA;FL;WA',
    U2BaseUrl: 'https://u2-portal-migration.cdcnapps.com/',
    V3BaseUrl: 'https://consumerdirectwebportal-migration.azurewebsites.net/',
    V3AzureAdB2cSigninAddress: '/Account/SignInB2C',
    V3AzureAdB2cSignupAddress: '/Account/Signup',
    DefaultCDCNPhoneNumber: '866-438-8591',
    logging: {
        enableTrace:false
    },
    loggingLevelTelemetry: 1
};



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants, AttestationCode } from 'src/app/core/appconstants';
import { CommonFunctionsService } from 'src/app/core/services/common-functions.service';
import { FeatureStateService } from 'src/app/core/services/featureStateService';
import { StorageConstants } from 'src/app/core/storageconstants';
import { CaregiverParticipantRelQuestionnaireDto, AttestationFormResponse, 
  AttestationServiceProxy, AttestationFormRequest } from 'src/app/external-user/services/v4ApiServices/v4ApiServices.services';


@Component({
  selector: 'app-doc-attestation',
  templateUrl: './doc-attestation.component.html',
  styles: [
  ]
})
export class DocAttestatitonComponent implements OnInit {

  mainpopupWidth = 500;
  @Input() participantData;
  @Input() solomonId;
  @Input() isImpersonateUser;
  @Output() emitFicaAttestation = new EventEmitter();
  @Input() languageText;
  @Output() langChange = new EventEmitter();
  docReqObj = new CaregiverParticipantRelQuestionnaireDto();
  attestationRes = [];
  participantId;
  CaregiverParticipantDetails;
  isCaregiverDocEnabled = false;
  caregiverParticipantData = JSON.parse(sessionStorage.getItem(StorageConstants.CaregiverParticipantRel));
  showConfirmModal = false;
  companyConfig;
  questionnaireLanguage;
  attestationDetails: AttestationFormResponse[] = [];
  docForm: UntypedFormGroup;
  docFormsubmitted = false;

  constructor(private AttestationServiceApi: AttestationServiceProxy, private toaster: ToastrService,
              public activatedRoute: ActivatedRoute, public formBuilder: UntypedFormBuilder,
              public commonFunction: CommonFunctionsService, private featureStateService: FeatureStateService) {
    this.isCaregiverDocEnabled = this.featureStateService.isCaregiverDocValid();
    const companyConfigValue = sessionStorage.getItem(AppConstants.companyConfigStorageKey);
    this.companyConfig = (JSON.parse(atob(companyConfigValue)));
  }

  ngOnInit(): void {
    this.participantId = this.participantData.participantId ? this.participantData.participantId : undefined;
    if (this.isCaregiverDocEnabled) {
      this.initializeDocForm();
      this.getCaregiverParticipantDetails();
      this.getSelectedLanguageCode();
      this.getDocQuestions();
    }
  }
  changeLanguage() {
    this.langChange.emit();
    this.getSelectedLanguageCode();
    this.getDocQuestions();
    this.initializeDocForm();
    this.docFormsubmitted = false;
  }

  getCaregiverParticipantDetails() {
    this.CaregiverParticipantDetails = this.participantData;
  }

  closeModal(option) {
      this.showConfirmModal = false;
  }

  buildRequest() {
    this.docFormsubmitted = true;
    const postObj = this.commonFunction.buildRequestForDocFica();
    this.docReqObj.attestationResponseId = 0;
    this.docReqObj.attestationVersion = this.companyConfig.docAttestationVersion;
    this.docReqObj.participantPersonId = this.participantId;
    this.docReqObj.caregiverPersonId = this.solomonId;
    this.docReqObj.confirmationDate = new Date();
    this.docReqObj.languageCode = this.questionnaireLanguage;
    this.docReqObj.attestationResponseDetails = postObj;
    this.docReqObj.participantFirstName = this.participantData.participantFirstName;
    this.docReqObj.participantLastName = this.participantData.participantLastName;
    if (this.docForm.valid){
      this.showConfirmModal = true;
    }
  }

  initializeDocForm(){
    this.docForm = this.formBuilder.group({
      48: new UntypedFormControl(''),
      50: new UntypedFormControl(''),
    });
  }

  getDocQuestions(){
    AppConstants.showLoading = true;
    const docFormReq = new AttestationFormRequest();
    docFormReq.attestationVersion = this.companyConfig.docAttestationVersion;
    docFormReq.languageCode = this.questionnaireLanguage;
    docFormReq.attestationCode = AttestationCode.DOC;
    this.AttestationServiceApi.getAttestationForm(docFormReq).subscribe(res => {
      if (res.attestationFormComponents && res.attestationFormComponents.length > 0){
        this.attestationDetails = res.attestationFormComponents;
        AppConstants.showLoading = false;
      }
    }, error => {
      this.toaster.error(error);
      AppConstants.showLoading = false;
    });
  }

  getSelectedLanguageCode(){
    const selectedLanguage = sessionStorage.getItem(StorageConstants.selectedlanguage);
    this.questionnaireLanguage = this.commonFunction.fetchU2LanguageCode(selectedLanguage);
  }

  get docFormControls() {
    return this.docForm;
  }

  valueChange(e, selectedControl){
    this.docForm.patchValue({[selectedControl]: e.target.value});
  }
}

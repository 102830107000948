import { LangchangePipe } from './pipe/langchange.pipe';
import { GridModule } from '@progress/kendo-angular-grid';
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationCountComponent } from './components/notification-count/notification-count.component';
import { AnnouncementGridComponent } from './components/announcement-grid/announcement-grid.component';
import { IconNumComponent } from './components/icon-num/icon-num.component';
import { AnnouncementGridListComponent } from './components/announcement-grid-list/announcement-grid-list.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { AuthorizationDetailsComponent } from './components/authorization-details/authorization-details.component';
import { CustEmpRateGridComponent } from './components/cust-emp-rate-grid/cust-emp-rate-grid.component';
import { AuthorizationServiceHistoryComponent } from './components/authorization-service-history/authorization-service-history.component';
import { AgencyListComponent } from './components/agency-list/agency-list.component';
import { OutstandingEntriesGridComponent } from './components/outstanding-entries-grid/outstanding-entries-grid.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { TimeShiftComponent } from './components/time-shift/time-shift.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './formatter/datepicker-formatter';
import { NoCommaPipe } from './pipe/nocomma.pipe';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CheckboxWithAttestationComponent } from './components/checkbox-with-attestation/checkbox-with-attestation.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ErrorHandlerService } from '../core/services/error-handler.service';
import { LangchangeDBPipe } from './pipe/langchangeDB.pipe';
import { WorkdayPopupComponent } from './components/workday-popup/workday-popup.component';
import { QualificationsComponent } from './components/qualifications/qualifications.component';
import { QualificationsGridComponent } from './components/qualifications/qualifications-grid/qualifications-grid.component';
import { CaregiverParticipantRelComponent } from './components/caregiver-participant-rel/caregiver-participant-rel.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { DocAttestatitonComponent } from './components/doc-attestation/doc-attestation.component';
import { FicaAttestationComponent } from './components/fica-attestation/fica-attestation.component';
import { LiveInExemptionAttestationComponent } from './components/live-in-exemption-attestation/live-in-exemption-attestation.component';
import { ClientRelLiveInAttestationComponent } from './components/live-in-exemption-attestation/client-rel-live-in/client-rel-live-in.component';
import { SummaryOfExemptionsComponent } from './components/live-in-exemption-attestation/summary-of-exemptions/summary-of-exemptions.component';
import { ExemptionsContinuedComponent } from './components/live-in-exemption-attestation/exemptions-continued/exemptions-continued.component';
import { ConfirmationDialogComponent } from '../shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { WarningConfirmationDialogComponent } from '../shared/dialog/confirmation-dialog/warning-confirmation-dialog/warning-confirmation-dialog.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { PhoneNumberFormatPipe } from './pipe/phone-number-format.pipe';
import { PhoneNumberMaskDirective } from './directives/phone-number-mask.directive';
import { IconsModule } from '@progress/kendo-angular-icons'


@NgModule({
  declarations: [
    NotificationCountComponent,
    AnnouncementGridComponent,
    IconNumComponent,
    ConfirmationComponent,
    AnnouncementGridListComponent,
    LangchangePipe,
    LangchangeDBPipe,
    NoCommaPipe,
    PhoneNumberFormatPipe,
    AuthorizationDetailsComponent,
    CustEmpRateGridComponent,
    AuthorizationServiceHistoryComponent,
    AgencyListComponent,
    OutstandingEntriesGridComponent,
    ModalDialogComponent,
    TimeShiftComponent,
    CheckboxWithAttestationComponent,
    WorkdayPopupComponent,
    QualificationsComponent,
    QualificationsGridComponent,
    CaregiverParticipantRelComponent,
    ConfirmModalComponent,
    DocAttestatitonComponent,
    FicaAttestationComponent,
    LiveInExemptionAttestationComponent,
    ClientRelLiveInAttestationComponent,
    SummaryOfExemptionsComponent,
    ExemptionsContinuedComponent,
    ConfirmationDialogComponent,
    WarningConfirmationDialogComponent,
    ConfirmPopupComponent,
    PhoneNumberMaskDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    DateInputsModule,
    DialogModule,
    TooltipModule,
    InputsModule,
    ReactiveFormsModule,
    IconsModule
  ],
  exports: [
    NotificationCountComponent,
    AnnouncementGridComponent,
    IconNumComponent,
    ConfirmationComponent,
    AnnouncementGridListComponent,
    LangchangePipe,
    LangchangeDBPipe,
    NoCommaPipe,
    PhoneNumberFormatPipe,
    AuthorizationDetailsComponent,
    CustEmpRateGridComponent,
    AgencyListComponent,
    AuthorizationServiceHistoryComponent,
    OutstandingEntriesGridComponent,
    ModalDialogComponent,
    TimeShiftComponent,
    CheckboxWithAttestationComponent,
    WorkdayPopupComponent,
    QualificationsComponent,
    QualificationsGridComponent,
    CaregiverParticipantRelComponent,
    ConfirmModalComponent,
    DocAttestatitonComponent,
    FicaAttestationComponent,
    LiveInExemptionAttestationComponent,
    ClientRelLiveInAttestationComponent,
    SummaryOfExemptionsComponent,
    ExemptionsContinuedComponent,
    ConfirmationDialogComponent,
    WarningConfirmationDialogComponent,
    ConfirmPopupComponent,
    PhoneNumberMaskDirective,
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { 
      provide: ErrorHandler, 
      useClass: ErrorHandlerService 
    },
  ]
})
export class AppSharedModule { }
